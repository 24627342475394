import React, { Component } from 'react';

import {
  UiText,
  UiRow,
  UiColumn,
  UiHeading,
  UiView,
  UiImage
} from '../../dependencies/zomato-ui';

import { CustomError, SentryErrorReporting, isSentryAllowed } from '../../util';

export default class ErrorPage extends Component {
  state = {
    status: null,
    errors: {
      500: {
        string:
          'Something went wrong. We are trying to fix the problem, it might take few seconds.',
        image:
          'https://b.zmtcdn.com/data/o2_assets/4f14248c65a9551e61ac0d919d5f51cb1543385584'
      },
      empty: {
        string:
          'Sorry, we couldn’t find any matching results. Please try searching for something else.',
        image:
          'https://b.zmtcdn.com/data/o2_assets/a686c6b7637a6ad1313020da0e0573481543385058'
      }
    }
  };

  componentDidMount() {
    this.setErrorStatus();
    if (isSentryAllowed() && this.props.error) {
      const error = new CustomError(this.props.error);
      SentryErrorReporting.captureMessage(error);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      this.setErrorStatus();
    }
  }

  setErrorStatus() {
    if (!this.props.error) {
      this.setState({
        status: null
      });
      return;
    }

    switch (this.props.error.status) {
      case 'empty':
        this.setState({
          status: this.props.error.status
        });
        break;
      default:
        this.setState({
          status: 500
        });
    }
  }

  containerStyle() {
    const { top, left, bottom, right } = this.props;
    return {
      top,
      left,
      bottom,
      right
    };
  }

  render() {
    const { errors, status } = this.state;
    const { error } = this.props;

    if (!error) {
      return null;
    }

    return (
      <UiView style={{ ...styles.snippetContainer, ...this.containerStyle() }}>
        <UiRow marginBottom>
          <UiColumn>
            <UiImage
              src={errors[status] && errors[status].image}
              height={180}
              width={180}
            />
          </UiColumn>
        </UiRow>
        <UiRow margin>
          <UiColumn doublePadded>
            <UiText align="center" color="secondary">
              {errors[status] && errors[status].string}
            </UiText>
          </UiColumn>
        </UiRow>
        {status !== 'empty' && (
          <UiRow margin>
            <UiColumn>
              <UiView onClick={this.props.tryAgain}>
                <UiHeading type="anchor" align="center">
                  Refresh
                </UiHeading>
              </UiView>
            </UiColumn>
          </UiRow>
        )}
      </UiView>
    );
  }
}

ErrorPage.defaultProps = {
  left: 0,
  bottom: 0,
  top: 0,
  right: 0
};

const styles = {
  snippetContainer: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column'
  }
};
