import React, { Component } from 'react';

import {
  UiRow,
  UiColumn,
  UiCell,
  UiImage,
  UiView,
  UiOverlay
} from './../../dependencies/zomato-ui';

import AppHeader from '../common/AppHeader';
import { getPageHeight } from '../../dependencies/modules/util';
import ErrorPage from '../common/ErrorPage';

import {
  RestaurantDetails,
  OrderStatusDetails,
  RiderDetails,
  SupportDetails
} from '../../containers/crystal';
import Partners from '../../dependencies/modules/partners';

export default class CrystalPage extends Component {
  state = {
    showLoader: true,
    error: null
  };

  componentDidMount() {
    const { tabId, getCartFromStorage, setCartEmpty } = this.props;
    const pollingTime = 10 * 1000; // 10 sec

    getCartFromStorage().then(cart => {
      if (!cart) {
        return;
      }

      if (cart.orderId === Number(tabId)) {
        setCartEmpty();
      }
    });

    this.loadData();
    this.refreshIntervalId = setInterval(this.loadData.bind(this), pollingTime);
  }

  componentWillUnmount() {
    clearInterval(this.refreshIntervalId);
  }

  tryAgain() {
    this.setState({
      showLoader: true,
      error: null
    });
    this.loadData();
  }

  loadData() {
    const data = {
      tabId: this.props.tabId
    };
    this.props
      .getOrderDetails(data)
      .then(response => {
        const partnerClient = Partners.getClient();

        if (
          (Partners.isRedirectAllowedPartner(partnerClient.CLIENT_NAME) &&
            !response.payment_status) ||
          [6].includes(response.status)
        ) {
          this.props.history.replace(`/order-summary/${this.props.tabId}`);
        }
        this.setState({
          showLoader: false
        });
      })
      .catch(error => {
        this.setState({
          showLoader: false,
          error
        });
      });
  }

  render() {
    const { statusImage, tabId } = this.props;
    const { showLoader, error } = this.state;
    return (
      <AppHeader
        iconColor={'#999999'}
        iconSize={18}
        showBackButton
        scrollerHeight={getPageHeight() - 45}
        divider={false}
        onBack={() => this.props.history.goBack()}
      >
        <RestaurantDetails tabId={tabId} />
        {statusImage && (
          <UiView style={{ textAlign: 'center' }}>
            <UiCell margin padded>
              <UiRow margin>
                <UiColumn>
                  <UiImage src={statusImage} height={250} width={250} />
                </UiColumn>
              </UiRow>
            </UiCell>
          </UiView>
        )}
        <OrderStatusDetails />
        <RiderDetails />
        <SupportDetails />
        <UiOverlay show={showLoader} />
        <ErrorPage error={error} tryAgain={() => this.tryAgain()} />
      </AppHeader>
    );
  }
}
