import React, { Component } from 'react';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../util/Theme';
import UiRow from '../Row';
import UiColumn from '../Column';
import UiText from '../Text';

class UiRadio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: ''
    };
  }

  componentDidMount() {
    this.setState({
      selectedValue: this.props.selectedTitle
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedTitle !== this.props.selectedTitle) {
      this.setState({
        selectedValue: this.props.selectedTitle
      });
    }
  }

  handleChange(index, item, event) {
    this.setState({
      selectedValue: event.target.value
    });
    this.props.onChange(index, item);
  }

  render() {
    const { classes, data } = this.props;

    return (
      <RadioGroup name={this.props.name} value={this.state.selectedValue}>
        {data &&
          data.map((item, index) => (
            <UiRow key={index}>
              <UiColumn flex={9}>
                <FormControlLabel
                  style={{
                    width: '100%'
                  }}
                  key={item.value}
                  value={item.title}
                  control={
                    <Radio
                      onChange={this.handleChange.bind(this, index, item)}
                      checked={this.state.selectedValue === item.title}
                      classes={{
                        root: classes.root,
                        checked: classes.checked
                      }}
                    />
                  }
                  label={item.title}
                />
              </UiColumn>
              <UiColumn justifyContent="center">
                {item.price ? (
                  <UiText align="right">₹{item.price}</UiText>
                ) : null}
              </UiColumn>
            </UiRow>
          ))}
      </RadioGroup>
    );
  }
}

const styles = {
  root: {
    color: colors['radio']['primary'],
    padding: 5,
    '&$checked': {
      color: colors['radio']['primary'],
      padding: 5
    }
  },
  checked: {}
};

export default withStyles(styles)(UiRadio);
