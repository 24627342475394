import Flipkart from './Flipkart';
import GPay from './GPay';

const partners = {
  ordering_sdk_fk_pwa_v1: {
    CLIENT_NAME: Flipkart.CLIENT_NAME,
    CLIENT_ID: Flipkart.CLIENT_ID,
    ZOMATO_ORDERING_API_KEY: Flipkart.ZOMATO_ORDERING_API_KEY,
    ZOMATO_ORDERING_SECRET_KEY: Flipkart.ZOMATO_ORDERING_SECRET_KEY
  },
  ordering_sdk_gpay_pwa_v1: {
    CLIENT_NAME: GPay.CLIENT_NAME,
    CLIENT_ID: GPay.CLIENT_ID,
    ZOMATO_ORDERING_API_KEY: GPay.ZOMATO_ORDERING_API_KEY,
    ZOMATO_ORDERING_SECRET_KEY: GPay.ZOMATO_ORDERING_SECRET_KEY
  }
};

export const getPartnerClient = clientId => {
  let partnerClient = {};

  partnerClient = partners[clientId];

  return partnerClient;
};
