import React, { Component } from 'react';
import { colors, fontSizes } from './util/Theme';

const styles = {
  UiText: {
    display: 'block',
    textAlign: 'left'
  }
};

export default class UiText extends Component<{}> {
  render() {
    const {
      color,
      align,
      decoration,
      size,
      type,
      weight,
      ellipsis
    } = this.props;

    let lineHeight = {};
    if (this.props.lineHeight) {
      lineHeight = {
        lineHeight: this.props.lineHeight
      };
    }

    let ellipsisStyle = {};
    if (ellipsis) {
      ellipsisStyle = {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      };
    }

    return (
      <span
        style={{
          ...styles.UiText,
          ...{
            fontSize: fontSizes[size],
            color: colors[type][color] ? colors[type][color] : color,
            textAlign: align,
            fontWeight: weight,
            fontFamily: 'arial',
            textDecoration: 'none',
            textDecorationLine: decoration,
            ...lineHeight,
            ...ellipsisStyle
          }
        }}
      >
        {this.props.children}
      </span>
    );
  }
}

UiText.defaultProps = {
  type: 'text',
  size: 'small',
  color: 'primary',
  align: 'left',
  decoration: 'none',
  weight: 'normal',
  ellipsis: 0
};
