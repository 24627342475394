import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import ChangeCustomisedItemQuantityPopup from '../../components/restaurant/ChangeCustomisedItemQuantityPopup';

import { toggleChangeCustomisedItemQuantityPopup } from '../../store/restaurant/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { cartItems, items } = state.cartState;
      const itemId = ownProps.match.params.itemId;
      const item = items[itemId];
      let cartItemQuantity = 0;

      if (item && item.length) {
        item.forEach(cartItemId => {
          cartItemQuantity += cartItems[cartItemId].quantity;
        });
      }

      return { itemId, cartItemQuantity };
    },
    { toggleChangeCustomisedItemQuantityPopup }
  )(ChangeCustomisedItemQuantityPopup)
);
