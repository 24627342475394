import React, { Component } from 'react';
import UiView from './View';

const BUTTON_STYLE_MAP = {
  tiny: {
    width: '30px',
    height: '38px'
  },
  small: {
    width: '45px',
    height: '40px'
  }
};

export default class UiLabel extends Component {
  state = {
    fluidWidth: BUTTON_STYLE_MAP[this.props.size].width
  };

  render() {
    const { title, background, style } = this.props;
    const { fluidWidth } = this.state;
    return (
      <UiView>
        <button
          style={{
            ...style,
            ...styles.customLabel,
            width: fluidWidth,
            backgroundColor: background
          }}
        >
          {title}
        </button>
      </UiView>
    );
  }
}

const styles = {
  customLabel: {
    display: 'inline-block',
    padding: 3,
    borderRadius: 3,
    border: 0,
    color: '#fff'
  }
};
