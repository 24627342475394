import React, { Component } from 'react';

import {
  UiRow,
  UiColumn,
  UiHeading,
  UiCell,
  UiLabel,
  UiText,
  UiView
} from '../../dependencies/zomato-ui';

import LocationSnippet from '../../containers/common/LocationSnippetContainer';
import MenuFilters from '../../containers/common/MenuFiltersContainer';

export default class RestaurantSnippet extends Component {
  aggregateRating() {
    const { restaurantInfo } = this.props;
    let rating = '-';
    if (restaurantInfo.user_rating.aggregate_rating !== '0') {
      rating = restaurantInfo.user_rating.aggregate_rating.toString();
    }
    return rating;
  }

  render() {
    const { restaurantInfo, resId } = this.props;
    return (
      <UiView>
        <UiRow>
          <UiColumn doublePadded>
            <UiCell margin padded borderBottom="default">
              <UiRow>
                <UiColumn flex={8} nonPadded>
                  <UiHeading size="massive">{this.props.name}</UiHeading>
                </UiColumn>
                {restaurantInfo.user_rating && (
                  <UiColumn nonPadded>
                    <UiLabel
                      title={this.aggregateRating()}
                      background={'#' + restaurantInfo.user_rating.rating_color}
                      size="small"
                    />
                  </UiColumn>
                )}
              </UiRow>
              {restaurantInfo.rating_streak &&
              restaurantInfo.rating_streak.length ? (
                <UiRow marginTop>
                  <UiColumn flex={5} nonPadded>
                    <UiRow marginTop>
                      <UiColumn flex={5} nonPadded>
                        <UiText color="secondary">
                          DELIVERY RATING FOR LAST{' '}
                          {restaurantInfo.rating_streak.length} ORDERS
                        </UiText>
                      </UiColumn>
                    </UiRow>
                    <UiRow marginTop>
                      <UiColumn>
                        {restaurantInfo.rating_streak.map(
                          (orderRating, key) => (
                            <UiView
                              style={styles.ratingStreakLabelContainer}
                              key={key}
                            >
                              <UiLabel
                                // class={[
                                //   key && key !== (restaurantInfo.rating_streak.length - 1) ? 'rating' : '',
                                //   !key ? 'first-rating' : '',
                                //   key === (restaurantInfo.rating_streak.length - 1) ? 'last-rating' : '',
                                // ]}
                                title={orderRating.rating.toString()}
                                background={orderRating.background}
                                color={orderRating.color}
                                size="tiny"
                              />
                            </UiView>
                          )
                        )}
                      </UiColumn>
                    </UiRow>
                  </UiColumn>
                </UiRow>
              ) : null}
            </UiCell>
          </UiColumn>
        </UiRow>
        <UiRow>
          <UiColumn>
            <LocationSnippet
              type="address"
              resId={resId}
              showIcon={resId}
              pageType="restaurant"
            />
          </UiColumn>
        </UiRow>
        {!restaurantInfo.is_delivering_now ? (
          <UiRow>
            <UiColumn doublePadded>
              <UiCell padded borderBottom="default">
                <UiRow>
                  <UiColumn nonPadded>
                    <UiHeading size="medium" color="secondary">
                      Currently not accepting orders
                    </UiHeading>
                  </UiColumn>
                </UiRow>
              </UiCell>
            </UiColumn>
          </UiRow>
        ) : null}
        <UiRow>
          <UiColumn>
            <MenuFilters />
          </UiColumn>
        </UiRow>
      </UiView>
    );
  }
}

const styles = {
  ratingStreakLabelContainer: {
    width: 70
  }
};
