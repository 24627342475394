import { Fetch } from './helpers';

export default {
  search(params) {
    if (!params || !params.query || !params.query.length) {
      return Promise.reject('Required field: query');
    }

    const { query, latitude, longitude } = params;

    const queryParams = {
      query,
      latitude: params.latitude ? latitude : 0,
      longitude: params.longitude ? longitude : 0
    };
    const path = 'order/search/location';

    return Fetch.get({ path, queryParams });
  },
  getNearestDeliverySubzone(params) {
    if (!params || !params.lat || !params.lng) {
      return Promise.reject('Required fields: latitude, longitude');
    }

    const { lat, lng } = params;

    const queryParams = {
      latitude: params.lat ? lat : 0,
      longitude: params.lng ? lng : 0
    };
    const path = 'order/location/auto-detect';

    return Fetch.get({ path, queryParams });
  }
};
