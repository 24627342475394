import React, { Component } from 'react';
import { UiView, UiText, UiRow, UiColumn } from '..';
import { UiCheckbox } from '.';

export default class UiCheckboxList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkedList: []
    };
  }

  componentDidMount() {
    this.checkIfListIsChecked();
  }

  wxcCheckBoxListChecked(event) {
    if (event.target.checked) {
      const checkedList = [...this.state.checkedList, event.target.value];
      this.setState(
        {
          checkedList: checkedList
        },
        () => this.props.onChange(this.state.checkedList)
      );
    } else {
      const index = this.state.checkedList.indexOf(event.target.value);
      this.setState(
        {
          checkedList: this.state.checkedList.splice(index, 1)
        },
        () => this.props.onChange(this.state.checkedList)
      );
    }
  }

  checkIfListIsChecked() {
    const { list } = this.props;

    if (list && list.length > 0) {
      const checkedList = [];
      list.forEach(item => {
        if (item.checked) {
          checkedList.push(item.value);
        }
      });

      this.setState({
        checkedList: checkedList
      });
    }
  }

  render() {
    const { list } = this.props;
    return (
      <UiView>
        {list.map((item, index) => (
          <UiRow key={index}>
            <UiColumn>
              <UiCheckbox
                value={item.title}
                onChange={this.wxcCheckBoxListChecked.bind(this)}
              />
            </UiColumn>
            <UiColumn flex={12}>
              <UiText>{item.title}</UiText>
            </UiColumn>
          </UiRow>
        ))}
      </UiView>
    );
  }
}
