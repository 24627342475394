import Partners from '../../dependencies/modules/partners';
import { Storage } from '../../util';
import { Fetch } from '../../api/helpers';
import { userModel } from '../../api/';
export const SET_USER_DATA = 'SET_USER_DATA';

export const PERMISSION_LOCATION = 'location';
export const PERMISSION_MOBILE = 'mobile';
export const PERMISSION_EMAIL = 'email';
export const PERMISSION_NAME = 'name';

export const setUserData = user => dispatch => {
  dispatch({
    type: SET_USER_DATA,
    user
  });
};

export const getUserDetails = () => (dispatch, getState) => {
  const state = getState().userState;
  const { thirdPartyUserId } = state;

  if (!thirdPartyUserId) {
    const skipPrompt = false;

    return dispatch(getGrantToken(skipPrompt)).then(user => {
      if (!user.third_party_user_id) {
        throw new Error('Unable to authenticate. Please try again.');
      }
    });
  }

  return Promise.resolve();
};

const makeLoginApi = response => dispatch => {
  const grantToken = response.grantToken ? response.grantToken : '';
  const scopes = response.scopes ? response.scopes : [];
  const params = {
    thirdPartyToken: grantToken,
    scopeNames: scopes
  };

  return userModel.login(params).then(response => {
    const { user } = response;
    Fetch.uuid = user.guest_id;
    if (!grantToken) {
      setUuidInStorage(user.guest_id);
    } else {
      removeUuidFromStorage();
    }

    dispatch(setUserData({ ...user }));
    return user;
  });
};

export const getUuidFromStorage = () => dispatch => {
  return Storage.getItem('guestId').then(value => {
    if (!value) {
      return;
    }

    const user = {
      guest_id: value
    };

    dispatch(setUserData({ ...user }));
  });
};

const setUuidInStorage = guestId => {
  Storage.setItem('guestId', guestId);
};

const removeUuidFromStorage = () => {
  Storage.removeItem('guestId');
};

// Rename to Auth
export const getGrantToken = skipPrompt => dispatch => {
  return getToken(skipPrompt)
    .then(response => {
      return dispatch(makeLoginApi(response));
    })
    .catch(error => {
      return dispatch(makeLoginApi({}));
    });
};

export const getUserPermission = () => dispatch => {
  const partnerClient = Partners.getClient();

  return partnerClient.requestUserDetails().then(response => {
    const data = {
      thirdPartyToken: response.grantToken,
      scopeNames: response.scopes
    };

    return userModel.login(data).then(res => {
      const { user } = res;
      dispatch(setUserData({ ...user }));
    });
  });
};

export const getToken = skipPrompt => {
  const partnerClient = Partners.getClient();
  return partnerClient.requestIdentity(skipPrompt);
};
