import { orderModel } from '../../api';

export const SET_RESPONSE = 'SET_RESPONSE';
export const SET_ORDER_HISTORY = 'SET_ORDER_HISTORY';
export const SET_ORDER_RESPONSE = 'SET_ORDER_RESPONSE';
export const SET_RESTAURANT_DETAILS = 'SET_RESTAURANT_DETAILS';
export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS';

export const getOrderSummary = data => dispatch => {
  return orderModel.getSummary(data).then(response => {
    dispatch(setResponse(response));
    dispatch(setOrderResponse(response.order_items));
    dispatch(setRestaurantDetails(response.restaurant));
  });
};

export const setResponse = response => dispatch => {
  dispatch({
    type: SET_RESPONSE,
    response
  });
};

export const setOrderResponse = orderItems => dispatch => {
  dispatch({
    type: SET_ORDER_RESPONSE,
    orderItems
  });
};

export const setRestaurantDetails = restaurant => dispatch => {
  dispatch({
    type: SET_RESTAURANT_DETAILS,
    restaurant
  });
};

export const getOrderHistory = data => dispatch => {
  return orderModel.getHistory(data).then(response => {
    dispatch(setOrderHistory(response));
  });
};

export const setOrderHistory = response => dispatch => {
  dispatch({
    type: SET_ORDER_HISTORY,
    response
  });
};

export const getOrderDetails = data => dispatch => {
  return orderModel.getDetails(data).then(response => {
    dispatch(setOrderDetails(response));
    return response;
  });
};

export const setOrderDetails = response => dispatch => {
  dispatch({
    type: SET_ORDER_DETAILS,
    response
  });
};

export const completePayment = data => dispatch => {
  return orderModel.completePayment(data).then(response => {
    return response;
  });
};

export const verifyPaymentStatus = data => dispatch => {
  return orderModel.verifyPaymentStatus(data).then(response => {
    return response;
  });
};
