import { StateManager } from '../../util';
import {
  SET_RESTAURANT_DATA,
  SET_MENU_FILTERS,
  SET_RESTAURANT_RESPONSE,
  SET_RESTAURANT_FILTERED_DATA,
  SET_CHOOSE_CUSTOMISATIONS_POPUP_VISIBLE_FLAG,
  SET_CHANGE_CUSTOMISED_ITEM_QUANTITY_POPUP_VISIBLE_FLAG
} from './actions';

export const initialState = {
  restaurantInfo: {},
  offer: null,
  menusList: [],
  menus: {},
  categories: {},
  items: {},
  groups: {},
  groupItems: {},
  vegOnly: false,
  containsEgg: false,
  response: {},
  customisationsPopup: {
    itemId: 0,
    isChooseCustomisationsPopupVisible: false,
    isChangeCustomisedItemQuantityPopupVisible: false
  }
};

const setRestaurantData = (state, action) => {
  const {
    restaurantInfo,
    offer,
    menusList,
    menus,
    categories,
    items,
    groups,
    groupItems
  } = action;
  return {
    ...state,
    restaurantInfo,
    offer,
    menusList,
    menus,
    categories,
    items,
    groups,
    groupItems
  };
};

const setRestaurantResponse = (state, action) => {
  const { response } = action;
  return {
    ...state,
    response: { ...response }
  };
};

const setMenuFilters = (state, action) => {
  const { vegOnly, containsEgg } = action;
  return {
    ...state,
    vegOnly,
    containsEgg
  };
};

const setRestaurantFilteredData = (state, action) => {
  const { dispatchData } = action;
  return {
    ...state,
    menusList: [...dispatchData.menusList],
    categories: { ...dispatchData.categories }
  };
};

const toggleChooseCustomisationsPopup = (state, action) => {
  const { isChooseCustomisationsPopupVisible, itemId } = action;
  return {
    ...state,
    customisationsPopup: {
      ...state.customisationsPopup,
      itemId: itemId,
      isChooseCustomisationsPopupVisible
    }
  };
};

const toggleChangeCustomisedItemQuantityPopup = (state, action) => {
  const { isChangeCustomisedItemQuantityPopupVisible, itemId } = action;
  return {
    ...state,
    customisationsPopup: {
      ...state.customisationsPopup,
      itemId: itemId,
      isChangeCustomisedItemQuantityPopupVisible
    }
  };
};

const actions = {
  [SET_RESTAURANT_DATA]: setRestaurantData,
  [SET_RESTAURANT_RESPONSE]: setRestaurantResponse,
  [SET_MENU_FILTERS]: setMenuFilters,
  [SET_RESTAURANT_FILTERED_DATA]: setRestaurantFilteredData,
  [SET_CHOOSE_CUSTOMISATIONS_POPUP_VISIBLE_FLAG]: toggleChooseCustomisationsPopup,
  [SET_CHANGE_CUSTOMISED_ITEM_QUANTITY_POPUP_VISIBLE_FLAG]: toggleChangeCustomisedItemQuantityPopup
};

const manager = new StateManager(initialState, actions);

export default manager.reducer;
