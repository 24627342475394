import React, { Component } from 'react';

import { UiRow, UiColumn } from './../../dependencies/zomato-ui';

import { UiRadio } from '../../dependencies/zomato-ui/input';

export default class CustomisationsRadio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      checkedIndex: -1,
      previousCheckedIndex: -1
    };
  }

  componentDidMount() {
    this.formatGroupItems();

    this.radioItemChecked(0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.checkedIndex !== this.state.checkedIndex) {
      this.onChange({ index: this.state.checkedIndex });
    }
  }

  onChange({ index }) {
    const { groupId } = this.props;
    const newItem = this.props.groupItems[index];
    const oldItem =
      this.state.previousCheckedIndex === -1
        ? null
        : this.props.groupItems[this.state.previousCheckedIndex];

    if (this.state.previousCheckedIndex === index) {
      return;
    }

    this.props.selectRadioItem({ groupId, newItem, oldItem });
  }

  radioItemChecked(index) {
    this.setState({
      previousCheckedIndex: this.state.checkedIndex,
      checkedIndex: index
    });
  }

  formatGroupItems() {
    const { groupItems } = this.props;

    const data = [];

    groupItems.map(groupItem =>
      data.push({
        title: groupItem.name,
        value: groupItem.item_id,
        price: groupItem.price
      })
    );

    this.setState({
      data: data
    });
  }

  render() {
    const { data } = this.state;

    return (
      <UiRow>
        <UiColumn doublePadded>
          <UiRadio
            selectedTitle={data[0] && data[0].title}
            data={data}
            name="customisation"
            onChange={this.radioItemChecked.bind(this)}
          />
        </UiColumn>
      </UiRow>
    );
  }
}
