import React, { Component } from 'react';

import {
  UiGrid,
  UiRow,
  UiColumn,
  UiView,
  UiHeading,
  UiText,
  UiButton,
  UiCell,
  UiSnackbar
} from './../../dependencies/zomato-ui';

import { MapWebView } from '../../containers/address';

export default class AddressMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isAddButtonEnabled: false,
      coordinates: {
        lat: 0,
        lon: 0,
        addr_lat: 0,
        addr_lon: 0
      },
      contentHeight: 200
    };
  }

  onMapCenterChange(e) {
    if (e.origin !== 'https://www.zomato.com') return;

    const coordinates = e.data;
    if (coordinates && coordinates.lat && coordinates.lng) {
      const state = { ...this.state };

      state.coordinates.lat = Number(coordinates.lat);
      state.coordinates.lon = Number(coordinates.lng);
      state.coordinates.addr_lat = Number(coordinates.lat);
      state.coordinates.addr_lon = Number(coordinates.lng);
      state.isAddButtonEnabled = true;
      this.setState(state);
    }
  }

  addAddress() {
    this.setState({
      isloading: true
    });

    const addressData = {
      ...this.props.newAddressData,
      ...this.state.coordinates
    };
    this.props
      .addUserAddress(addressData)
      .then(response => {
        if (response.status === 'failed') {
          this.setState({
            error: response.status,
            isloading: false
          });
          return;
        }

        this.setState({
          isloading: false
        });

        let addressPagesLength = 2;
        if (response.address_data.is_delivery_location_for_restaurant) {
          this.props.selectLocation(response.address_data);
          addressPagesLength++;
        }
        this.props.clearSelectedLocationForAddress();
        this.props.clearLocations();
        this.props.history.go(-addressPagesLength);
      })
      .catch(error => {
        this.setState({
          error: error,
          isloading: false
        });
      });
  }

  saveAddress() {
    if (!this.state.isAddButtonEnabled) {
      return;
    }

    this.props
      .getUserDetails()
      .then(() => {
        this.addAddress();
      })
      .catch(error => {
        this.setState({
          error: error
        });
      });
  }

  handleClose() {
    this.setState({
      error: null
    });
  }

  render() {
    const { newAddressData } = this.props;
    const { contentHeight, isloading, error, isAddButtonEnabled } = this.state;
    return (
      <UiGrid>
        <UiCell margin padded paddedBorder>
          <UiRow>
            <UiColumn nonPadded>
              <UiView>
                <UiHeading size="huge">Mark your address</UiHeading>
                <UiText color="highlighted">
                  Your accurate location will help us deliver your food faster.
                </UiText>
              </UiView>
            </UiColumn>
          </UiRow>
        </UiCell>
        {newAddressData && (
          <UiCell margin padded paddedBorder>
            <UiHeading size="big">{newAddressData.alias}</UiHeading>
            <UiText size="medium" color="secondary">
              {newAddressData.address}, {newAddressData.delivery_subzone_name}
            </UiText>
          </UiCell>
        )}
        <UiCell margin padded paddedBorder>
          <UiRow>
            <UiColumn nonPadded>
              <MapWebView
                onMapCenterChange={this.onMapCenterChange.bind(this)}
                contentHeight={contentHeight}
              />
            </UiColumn>
          </UiRow>
        </UiCell>
        <UiView style={styles.saveAddressBtn}>
          <UiButton
            gradient
            style={styles.save}
            title="Save"
            fluid="true"
            type="primary"
            variant="contained"
            loading={isloading}
            color={isAddButtonEnabled ? 'lightBlue' : 'paytmGrey'}
            onPress={this.saveAddress.bind(this)}
          />
        </UiView>
        <UiSnackbar
          open={error}
          handleClose={this.handleClose.bind(this)}
          message={'Something went wrong!'}
        />
      </UiGrid>
    );
  }
}

const styles = {
  saveAddressBtn: {
    position: 'fixed',
    flex: 1,
    flexDirection: 'row',
    left: 12,
    bottom: 12,
    right: 12,
    alignItems: 'center',
    zIndex: 100
  },
  save: {
    flex: 1,
    borderRadius: 10,
    alignItems: 'center'
  }
};
