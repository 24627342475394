import React, { Component } from 'react';
import { UiView } from '.';

export default class UiScroller extends Component {
  render() {
    const { height, onScroll, DOMref } = this.props;
    return (
      <UiView
        style={{
          overflowY: 'scroll',
          height: height
        }}
        DOMref={DOMref}
        onScroll={onScroll}
      >
        {this.props.children}
      </UiView>
    );
  }
}

UiScroller.defaultProps = {
  height: null,
  onScroll: () => {}
};
