import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { Addresses } from './../../components/address';
import { getUserAddresses } from '../../store/address/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { addressList } = state.addressState;
      return { addressList };
    },
    { getUserAddresses }
  )(Addresses)
);
