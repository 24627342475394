import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import {
  UiView,
  List,
  UiOverlay,
  UiScroller,
  UiDialog,
  UiIcon
} from '../../dependencies/zomato-ui';

import AppHeader from '../common/AppHeader';

import { HeaderImage } from '../restaurant';

import {
  RestaurantMenu,
  RestaurantSnippet,
  Fssai,
  MenuButton
} from './../../containers/restaurant/';

import CartButton from '../../containers/common/CartButtonContainer';
import { getPageHeight } from '../../dependencies/modules/util';

import ErrorPage from '../common/ErrorPage';

export default class Restaurant extends Component {
  constructor(props) {
    super(props);

    this.menuRefs = [];
    this.parentDiv = React.createRef();
    this.listContainerRef = React.createRef();
  }
  state = {
    isSearchPopupVisible: false,
    showHeaderTitle: false,
    showLoader: true,
    headerHeight: 200,
    outOfStockItemIds: [],
    isDialogVisible: false,
    dialogContent:
      'Would you like to remove those items and continue or would you like to empty the cart?'
  };

  componentDidMount() {
    const { resId, restaurantInfo, selectedLocation, menusList } = this.props;

    if (!selectedLocation) {
      this.props.selectLocation();
    }

    if (
      !restaurantInfo.R ||
      (restaurantInfo.R && Number(restaurantInfo.R.res_id) !== Number(resId)) ||
      !menusList ||
      !menusList.length
    ) {
      this.getRestaurantData();
    } else {
      this.setState({
        showLoader: false
      });
    }

    this.props.setCartFromStorage({ resId }).then(() => {
      const cartCalculations = this.props.getCartCalculations();
      this.props.setCartCalculation(cartCalculations);
    });
  }

  areAnyCartItemsOutOfStock() {
    const { addedItemsInCart, restaurantMenuItems } = this.props;
    const itemIdsAddedIntoCart = Object.keys(addedItemsInCart);
    if (!itemIdsAddedIntoCart.length) {
      return;
    }
    const outOfStockItemIds = [];
    itemIdsAddedIntoCart.forEach(itemId => {
      const isItemInStock = restaurantMenuItems[itemId];
      if (!isItemInStock) {
        outOfStockItemIds.push(itemId);
      }
    });
    if (outOfStockItemIds.length) {
      this.setState({
        isDialogVisible: true,
        outOfStockItemIds: outOfStockItemIds
      });
    }
  }

  emptyCart() {
    this.props.setCartEmpty();
    this.setState({
      isDialogVisible: false
    });
  }

  removeItems() {
    const { outOfStockItemIds } = this.state;
    this.props.removeOutOfStockItemsFromCart(outOfStockItemIds);
    this.setState({
      isDialogVisible: false
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedLocation !== this.props.selectedLocation) {
      this.getRestaurantData();
    }

    if (
      (!prevProps.menusList || !prevProps.menusList.length) &&
      prevProps.menusList !== this.props.menusList
    ) {
      this.areAnyCartItemsOutOfStock();
    }
  }

  onClose() {}

  onScroll = event => {
    if (event.target.scrollTop > 140) {
      this.setState({
        showHeaderTitle: true
      });
    } else {
      this.setState({
        showHeaderTitle: false
      });
    }
  };

  getRestaurantData() {
    const { selectedLocation, resId } = this.props;

    if (!selectedLocation) {
      this.setState({
        error: 'Location not selected',
        showLoader: false
      });
      return;
    }

    this.setState({
      error: null,
      showLoader: true
    });

    const { place } = selectedLocation;
    const data = {
      resId: resId,
      deliverySubzoneId:
        place.place_type === 'DSZ' ? place.place_id : place.delivery_subzone_id
    };

    this.props
      .getRestaurant(data)
      .then(() => {
        this.setState({
          showLoader: false
        });
      })
      .catch(err => {
        this.setState({
          error: err,
          showLoader: false
        });
      });
  }

  closePopup() {
    this.setState({
      isSearchPopupVisible: false
    });
  }

  onClick() {
    const { resId } = this.props;
    this.props.history.push(`/restaurant-search/${resId}`);
  }

  onBack() {
    this.props.history.goBack();
  }

  scrollOnSelectedMenu(selectedMenuId) {
    const menuDiv = ReactDOM.findDOMNode(this.menuRefs[selectedMenuId].current);
    const scroller = ReactDOM.findDOMNode(this.parentDiv.current);
    const listContainer = ReactDOM.findDOMNode(this.listContainerRef.current);

    let currentScrollTop = scroller.scrollTop;
    let finalScrollTop =
      currentScrollTop + menuDiv.getBoundingClientRect().top - 60;
    const totalTime = 50;

    const diff = Math.abs(currentScrollTop - finalScrollTop);
    const delta = diff / listContainer.getBoundingClientRect().height;
    let stepMultiplier = 2;

    if (delta <= 0.1) {
      stepMultiplier = 20;
    } else if (delta <= 0.2) {
      stepMultiplier = 10;
    } else if (delta <= 0.4) {
      stepMultiplier = 7;
    } else if (delta <= 0.6) {
      stepMultiplier = 5;
    }
    const step = (stepMultiplier * diff) / totalTime;
    const interval = setInterval(function() {
      if (currentScrollTop === finalScrollTop) {
        clearInterval(interval);
        return;
      }
      if (finalScrollTop < currentScrollTop) {
        currentScrollTop -= step;
        currentScrollTop = Math.max(finalScrollTop, currentScrollTop);
      } else {
        currentScrollTop += step;
        currentScrollTop = Math.min(finalScrollTop, currentScrollTop);
      }
      scroller.scrollTop = currentScrollTop;
    }, 0);
  }

  render() {
    const { restaurantInfo, menusList, resId, restaurantImage } = this.props;

    if (menusList && menusList.length && !this.menuRefs.length) {
      menusList.forEach(menuId => {
        if (this.menuRefs[menuId]) {
          return;
        }

        this.menuRefs[menuId] = React.createRef();
      });
    }
    const {
      showHeaderTitle,
      showLoader,
      headerHeight,
      error,
      isDialogVisible,
      dialogContent
    } = this.state;

    return (
      <UiScroller DOMref={this.parentDiv} onScroll={this.onScroll.bind(this)}>
        <AppHeader
          divider={false}
          backgroundColor={'transparent'}
          stickyHeader
          showBackButton
          showHeaderTitle
          showRightIcon
          rightIcon={() => (
            <UiIcon color="#fff" fontSize={20} icon={'fa fa-search'} feedback />
          )}
          iconSize={20}
          iconColor={'#fff'}
          titleColor={'#fff'}
          titlePosition="flex-start"
          title={showHeaderTitle ? restaurantInfo.name : ''}
          onClick={this.onClick.bind(this)}
          onBack={this.onBack.bind(this)}
        >
          <HeaderImage
            showHeaderTitle={showHeaderTitle}
            src={restaurantImage || restaurantInfo.o2_featured_image}
          />
        </AppHeader>
        <UiView style={{ height: getPageHeight() - headerHeight }}>
          <RestaurantSnippet name={restaurantInfo.name} resId={resId} />
          <List
            data={menusList}
            listContainerRef={this.listContainerRef}
            renderItem={menuId => (
              <UiView DOMref={this.menuRefs[menuId]}>
                <RestaurantMenu key={menuId} menuId={menuId} resId={resId} />
              </UiView>
            )}
          />
          <Fssai />
          {menusList && (
            <UiView>
              <MenuButton
                scrollOnSelectedMenu={this.scrollOnSelectedMenu.bind(this)}
              />
              <CartButton resId={resId} />
            </UiView>
          )}
          <UiOverlay show={showLoader} />
          {!showLoader && (error || (menusList && !menusList.length)) && (
            <ErrorPage
              error={{ status: 500 }}
              tryAgain={this.getRestaurantData.bind(this)}
            />
          )}
          <UiDialog
            isVisible={
              isDialogVisible && menusList && menusList.length && !showLoader
            }
            title="Some items in your cart have changed."
            content={dialogContent}
            confirmText="Remove items"
            cancelText="Empty Cart"
            onCancel={this.emptyCart.bind(this)}
            onConfirm={this.removeItems.bind(this)}
          />
        </UiView>
      </UiScroller>
    );
  }
}
