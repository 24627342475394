import React, { Component } from 'react';
import './SwipeRefreshLayout.css';
import { UiView } from '../../../dependencies/zomato-ui';
import { colors } from '../../../dependencies/zomato-ui/util/Theme';

const ReplayIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 29 29"
      width="25px"
    >
      <path
        d="M25.513 11.775c.26.971.392 1.971.392 2.976 0 6.309-5.191 11.5-11.5 11.5a11.504 11.504 0 0 1-8.132-3.368"
        fill="none"
        stroke={props.color}
        strokeWidth="3"
      />
      <path
        d="M3.296 17.728a11.503 11.503 0 0 1-.392-2.976c0-6.309 5.191-11.5 11.5-11.5 3.049 0 5.976 1.212 8.132 3.368"
        fill="none"
        stroke={props.color}
        strokeWidth="3"
      />
      <path d="M18.562 9.048l4.299-7.253 3.06 7.343z" fill={props.color} />
      <path d="M11.087 21.014l-6.243 6.252-1.071-7.303z" fill={props.color} />
    </svg>
  );
};

class Refresher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      touchMoveDelta: 0
    };

    this.startY = 0;
    this.endY = 120;
    this.loadingY = 70;
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
  }

  componentDidMount() {
    if (this.props.onRefresh) {
      const defaultChromeLoader = document.getElementsByTagName('body')[0];

      if (defaultChromeLoader) {
        defaultChromeLoader.style['overscroll-behavior-y'] = 'none';
      }

      window.addEventListener('touchstart', this.handleTouchStart);
      window.addEventListener('touchmove', this.handleTouchMove);
      window.addEventListener('touchend', this.handleTouchEnd);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.refreshing &&
      prevProps.refreshing !== this.props.refreshing
    ) {
      this.setState({ touchMoveDelta: 0 });
    }
  }

  componentWillUnmount() {
    if (this.props.onRefresh) {
      window.removeEventListener('touchstart', this.handleTouchStart);
      window.removeEventListener('touchmove', this.handleTouchMove);
      window.removeEventListener('touchend', this.handleTouchEnd);
    }
  }

  handleTouchStart(event) {
    this.startY = event.touches[0].pageY;
  }

  handleTouchEnd(event) {
    this.startY = 0;
    if (this.state.touchMoveDelta >= this.loadingY) {
      this.props.onRefresh();
      return;
    }

    if (this.state.touchMoveDelta < this.endY) {
      this.setState({ touchMoveDelta: 0 });
    }
  }

  handleTouchMove(event) {
    if (!event.target.closest('.ui-list-container')) {
      return;
    }

    const { refreshing, getScrollTop } = this.props;
    if (getScrollTop() > 0 || refreshing) {
      return;
    }

    const y = event.touches[0].pageY;
    if (y >= this.startY) {
      this.setState({ touchMoveDelta: y - this.startY });
    }
  }

  render() {
    let { touchMoveDelta } = { ...this.state };
    const { refreshing } = this.props;
    const classes = ['refresher', 'refreshing'];
    touchMoveDelta = Math.min(
      refreshing ? this.loadingY : touchMoveDelta,
      this.endY
    );

    return (
      <UiView
        id={'containerRefresher'}
        className={classes.join(' ')}
        style={{ ...styles.positionRefresher, top: `${touchMoveDelta - 50}px` }}
      >
        {refreshing ? (
          <UiView className="spinner" style={styles.positionRefresher}>
            <UiView style={styles.positionRefresher} />
            <UiView style={styles.positionRefresher} />
            <UiView style={styles.positionRefresher} />
            <UiView style={styles.positionRefresher} />
          </UiView>
        ) : (
          <UiView
            className="spinner"
            style={{ transform: `rotate(${(360 * touchMoveDelta) / 200}deg)` }}
          >
            <ReplayIcon
              color={
                touchMoveDelta >= this.loadingY
                  ? colors.button.primary
                  : colors.icon.blur
              }
            />
          </UiView>
        )}
      </UiView>
    );
  }
}

const styles = {
  positionRefresher: {
    position: 'absolute'
  }
};

export default Refresher;
