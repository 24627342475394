import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { SavedAddresses } from './../../components/address';

export default withRouter(
  connect((state, ownProps) => {
    const { addressList } = state.addressState;
    return { addressList };
  })(SavedAddresses)
);
