import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import CartItems from '../../components/cart/CartItems';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { resId, cartItems, items } = state.cartState;
      const itemId = ownProps.itemId;

      return { resId, cartItems, itemId, items };
    },
    {}
  )(CartItems)
);
