import { connect } from 'react-redux';
import { withRouter } from '../../../dependencies/modules/react-router-native';
import { SearchResultStatus } from './../../../components/home/restaurants';

import { resetFilters } from '../../../store/search/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const {
        selectedSorting,
        appliedFilters,
        appliedCuisine
      } = state.searchState;
      return { selectedSorting, appliedFilters, appliedCuisine };
    },
    { resetFilters }
  )(SearchResultStatus)
);
