import { connect } from 'react-redux';
import { withRouter } from '../../../dependencies/modules/react-router-native';
import { QuickFilter } from './../../../components/home/search';

import {
  getCategoriesList,
  setSelectedFilters
} from '../../../store/search/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { selectedFilters } = state.searchState;
      return { selectedFilters };
    },
    {
      getCategoriesList,
      setSelectedFilters
    }
  )(QuickFilter)
);
