import { connect } from 'react-redux';
import { withRouter } from '../../../dependencies/modules/react-router-native';
import { RestaurantCard } from './../../../components/home/restaurants';

export default withRouter(
  connect((state, ownProps) => {
    const { restaurants } = state.searchState;
    let restaurant = restaurants[ownProps.resId];

    if (ownProps.type === 'chainOutlet') {
      restaurant = ownProps.restaurant;
    }

    return { restaurant };
  })(RestaurantCard)
);
