import React, { Component } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { colors } from './util/Theme';

class UiSwitch extends Component {
  styles = {
    switchBase: {
      color: colors['switch'][this.props.type],
      '&$checked': {
        color: colors['switch'][this.props.type]
      },
      '&$checked + $bar': {
        backgroundColor: colors['switch'][this.props.type]
      }
    },
    checked: {},
    bar: {
      backgroundColor: colors['background']['gray']
    }
  };

  div = props => {
    return (
      <FormControlLabel
        control={
          <Switch
            classes={{
              switchBase: props.classes.switchBase,
              bar: props.classes.bar,
              checked: props.classes.checked
            }}
            onChange={this.handleChange.bind(this)}
            value={this.props.label}
          />
        }
        label={this.props.label}
      />
    );
  };

  Styled = withStyles(this.styles)(this.div);

  handleChange(event) {
    this.props.onChange(event);
  }

  render() {
    return <this.Styled>{this.props.children}</this.Styled>;
  }
}

UiSwitch.defaultProps = {
  color: 'primary',
  type: 'switch'
};

export default UiSwitch;
