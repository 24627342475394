import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { RestaurantMenu } from './../../components/restaurant';

export default withRouter(
  connect((state, ownProps) => {
    const { menus } = state.restaurantState;
    const menu = menus[ownProps.menuId];
    return { menu };
  })(RestaurantMenu)
);
