import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import PersonalDetails from '../../components/cart/PersonalDetails';

export default withRouter(
  connect((state, ownProps) => {
    const { mobileNumber, name } = state.userState;
    return { mobileNumber, name };
  })(PersonalDetails)
);
