import React, { Component } from 'react';

import {
  UiRow,
  UiColumn,
  UiView,
  UiTextInput,
  UiCell
} from './../../../dependencies/zomato-ui';

import { deepCompare, debounce } from '../../../util';

export default class KeywordSearchInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastSearch: null,
      inputValue: '',
      showLoader: true
    };

    this.searchDebounce = debounce(this.search, 300);
  }

  onChange(event) {
    this.setState({
      inputValue: event.target.value
    });
    this.searchDebounce(event.target.value);
  }

  search(query) {
    const { selectedLocation } = this.props;
    if (!selectedLocation) {
      return;
    }

    const { place } = selectedLocation;
    const data = {
      deliverySubzoneId:
        place.place_type === 'DSZ' ? place.place_id : place.delivery_subzone_id,
      latitude: place.latitude ? place.latitude : 0,
      longitude: place.longitude ? place.longitude : 0,
      query
    };

    if (
      //this.loading &&
      this.state.lastSearch &&
      deepCompare(data, this.state.lastSearch)
    ) {
      return;
    }

    this.setState({
      lastSearch: { ...data }
    });
    this.props.setLoading(true);
    this.props.setError(null);
    this.props
      .getkeywordsSearchResults(data)
      .then(() => {
        this.props.setLoading(false);
        this.props.setError(null);
        this.setState({
          showLoader: false
        });
      })
      .catch(error => {
        this.props.setLoading(false);
        this.props.setError(error);
      });
  }

  onBlur() {
    const { error, restaurantList } = this.props;
    if (
      (!restaurantList && !error) ||
      (restaurantList && !restaurantList.length)
    ) {
      this.props.setSearchResultsVisibility(false);
    }
  }

  onClear() {
    this.setState({
      inputValue: ''
    });
    this.props.setSearchResultsVisibility(false);
  }

  render() {
    const { showLoader } = this.state;
    return (
      <UiView style={styles.inputContainer}>
        <UiCell padded margin>
          <UiRow>
            <UiColumn doublePadded>
              <UiTextInput
                onClear={this.onClear.bind(this)}
                value={this.state.inputValue}
                style={styles.textInput}
                placeholder="Search by restaurant name or dish..."
                onChange={this.onChange.bind(this)}
                onFocus={this.props.setSearchResultsVisibility.bind(this, true)}
                onBlur={this.onBlur.bind(this)}
                icon={'fa fa-search'}
                fontSize={14}
                iconFontSize={14}
                showLoader={showLoader}
              />
            </UiColumn>
          </UiRow>
        </UiCell>
      </UiView>
    );
  }
}

const styles = {
  inputContainer: {
    backgroundColor: '#f3f7f8',
    borderRadius: 5,
    width: '100%',
    margin: '6px 0px'
  },
  textInput: {
    backgroundColor: '#f3f7f8'
  }
};
