import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';

import reducers, { initializeState } from './reducers';
import { routerMiddleware } from 'react-router-redux';

const getStore = history => {
  const middleware = routerMiddleware(history);
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    reducers,
    initializeState(),
    composeEnhancers(applyMiddleware(thunkMiddleware, middleware))
  );
  return store;
};
export default getStore;
