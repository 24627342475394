import React, { Component } from 'react';

import {
  UiRow,
  UiColumn,
  UiHeading,
  UiCell,
  UiView,
  UiIcon
} from './../../../dependencies/zomato-ui';
import { colors } from '../../../dependencies/zomato-ui/util/Theme';

export default class SearchResultStatus extends Component {
  reset() {
    this.props.resetFilters();
    this.toggleFiltersDrawer(false);
    this.toggleSortDrawer(false);
  }

  toggleSortDrawer() {
    this.props.history.push('/sort');
  }

  toggleFiltersDrawer() {
    this.props.history.push('/filters');
  }

  selectedSortOrder() {
    const { selectedSorting } = this.props;
    return selectedSorting && selectedSorting.title
      ? selectedSorting.title.toUpperCase()
      : 'RELEVANCE';
  }

  render() {
    const { appliedFilters, appliedCuisine } = this.props;
    return (
      <UiCell marginTop paddingTop paddedBorder>
        <UiRow>
          <UiColumn nonPadded>
            <UiView
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <UiView
                style={{ zIndex: 100, display: 'inline-block' }}
                onClick={this.toggleFiltersDrawer.bind(this, true)}
              >
                <UiRow margin>
                  {(appliedFilters.length ||
                    (appliedCuisine && appliedCuisine.title)) && (
                    <UiIcon
                      style={{ display: 'flex', marginTop: 1, marginRight: 3 }}
                      icon={'fa fa-check-circle'}
                      fontSize={12}
                      color={colors['anchor']['primary']}
                    />
                  )}
                  <UiHeading
                    type="anchor"
                    size="mini"
                    weight="600"
                    align="right"
                  >
                    FILTERS
                  </UiHeading>
                </UiRow>
              </UiView>
              <UiView
                style={{ zIndex: 100, display: 'inline-block', marginLeft: 12 }}
                onClick={this.toggleSortDrawer.bind(this, true)}
              >
                <UiRow margin>
                  <UiHeading
                    type="anchor"
                    size="mini"
                    weight="600"
                    align="right"
                  >
                    {this.selectedSortOrder()}
                  </UiHeading>
                  <UiIcon
                    style={{
                      display: 'flex',
                      marginTop: 1,
                      textAlign: 'right'
                    }}
                    icon={'fa fa-long-arrow-down'}
                    fontSize={11}
                    color={colors['anchor']['primary']}
                  />
                </UiRow>
              </UiView>
            </UiView>
          </UiColumn>
        </UiRow>
      </UiCell>
    );
  }
}
