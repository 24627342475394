import { connect } from 'react-redux';
import { withRouter } from '../../../dependencies/modules/react-router-native';
import { RestaurantList } from './../../../components/home/restaurants';
import { getRestaurants } from '../../../store/search/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const {
        restaurantList,
        appliedCuisine,
        appliedFilters,
        selectedSorting,
        hasMore,
        renderedRestaurants,
        location
      } = state.searchState;
      const { selectedLocation } = state.locationsState;
      const { uuid } = state.userState;
      const show = ownProps.show;
      return {
        restaurantList,
        searchedLocation: location,
        selectedLocation,
        uuid,
        show,
        appliedCuisine,
        appliedFilters,
        selectedSorting,
        hasMore,
        renderedRestaurants
      };
    },
    {
      getRestaurants
    }
  )(RestaurantList)
);
