import React, { Component } from 'react';

import Divider from '@material-ui/core/Divider';
import { UiView } from '.';
import { colors } from './util/Theme';

export default class UiDivider extends Component {
  render() {
    const { noMargin } = this.props;
    return (
      <UiView style={{ marginTop: noMargin ? 0 : 6 }}>
        <Divider style={{ backgroundColor: colors['border']['primary'] }} />
      </UiView>
    );
  }
}
