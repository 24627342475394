const superStore = '@OrderReactPWA:';

export default class Storage {
  static setItem = (key, value) =>
    new Promise(resolve => {
      if (window.localStorage) {
        resolve(
          window.localStorage.setItem(superStore + key, JSON.stringify(value))
        );
      } else {
        resolve(null);
      }
    });

  static getItem = key =>
    new Promise(resolve => {
      if (window.localStorage) {
        resolve(JSON.parse(window.localStorage.getItem(superStore + key)));
      } else {
        resolve(null);
      }
    });

  static removeItem = key =>
    new Promise(resolve => {
      if (window.localStorage) {
        resolve(window.localStorage.removeItem(superStore + key));
      } else {
        resolve(null);
      }
    });
}
