import { connect } from 'react-redux';
import { withRouter } from '../../../dependencies/modules/react-router-native';
import { Filters } from '../../../components/home/search';

import {
  applySelectedCuisine,
  applySelectedFilters
} from '../../../store/search/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { filterObject } = state.searchState;
      const hidePopup = ownProps.hidePopup;
      return { filterObject, hidePopup };
    },
    {
      applySelectedCuisine,
      applySelectedFilters
    }
  )(Filters)
);
