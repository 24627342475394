import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { AddAddress } from './../../components/address';

import { setNewAddressData } from '../../store/address/actions';
import {
  clearSelectedLocationForAddress,
  selectLocationForAddress,
  selectLocation
} from '../../store/locations/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const {
        selectedLocation,
        selectedLocationForAddress
      } = state.locationsState;
      return { selectedLocation, selectedLocationForAddress };
    },
    {
      selectLocation,
      setNewAddressData,
      selectLocationForAddress,
      clearSelectedLocationForAddress
    }
  )(AddAddress)
);
