import React, { Component } from 'react';

import {
  UiText,
  UiRow,
  UiCell,
  UiColumn,
  UiHeading,
  UiView,
  UiImage
} from '../../dependencies/zomato-ui';

import MenuItemCounter from '../../containers/restaurant/MenuItemCounterContainer';

export default class RestaurantMenuItem extends Component {
  hasGroups() {
    const { item } = this.props;
    return item.groups && item.groups.length > 0;
  }

  itemDisplayPrice() {
    const { restaurantInfo, item } = this.props;
    const { currency } = restaurantInfo;
    const checkOfferApplicability = false;
    const displayPrice = this.getDiscountedAmount(
      item.min_price,
      checkOfferApplicability
    );
    return this.getAmountStringWithCurrency(
      currency,
      displayPrice.toFixed(2),
      restaurantInfo.currency_affix
    );
  }

  itemOriginalPrice() {
    const { restaurantInfo, item } = this.props;
    const { currency } = restaurantInfo;
    return this.getAmountStringWithCurrency(
      currency,
      item.min_price.toFixed(2),
      restaurantInfo.currency_affix
    );
  }

  isOfferApplicable(amount) {
    const { offer } = this.props;

    if (
      offer &&
      offer.type === 'Discount' &&
      (!offer.min_order_amount || amount >= offer.min_order_amount)
    ) {
      return true;
    }
    return false;
  }

  getDiscountedAmount = (amount, checkApplicability = true) => {
    const { offer } = this.props;
    if (
      !offer ||
      !offer.discount_percentage ||
      (checkApplicability && !this.isOfferApplicable(amount))
    ) {
      return amount;
    }
    return amount * (1.0 - offer.discount_percentage);
  };

  getAmountStringWithCurrency = (currency, amount, currencyAffix) => {
    if (currencyAffix === 'prefix') {
      return currency + amount.toString();
    }
    return amount.toString() + currency;
  };

  render() {
    const { item, offer, resId, restaurantInfo } = this.props;
    const itemId = item.item_id;
    if (!itemId) {
      return null;
    }
    return (
      <UiRow margin>
        <UiCell margin>
          <UiRow margin>
            <UiImage src={item.item_tag_image} height={16} width={16} />
            <UiColumn nonPadded>
              <UiRow>
                <UiColumn flex={6}>
                  <UiRow>
                    <UiColumn nonPadded>
                      <UiHeading size="medium">{item.name}</UiHeading>
                    </UiColumn>
                  </UiRow>
                  {item.sub_desc && (
                    <UiRow marginTop>
                      <UiColumn nonPadded>
                        <UiHeading size="medium" color="secondary">
                          {item.sub_desc}
                        </UiHeading>
                      </UiColumn>
                    </UiRow>
                  )}
                  <UiRow marginTop>
                    {offer && offer.discount_percentage && (
                      <UiView style={styles.originalPrice}>
                        <UiText decoration="line-through">
                          {this.itemOriginalPrice()}
                        </UiText>
                      </UiView>
                    )}
                    <UiText>{this.itemDisplayPrice()}</UiText>
                  </UiRow>
                </UiColumn>
                <UiColumn nonPadded flex={2} style={styles.counterContainer}>
                  <UiView style={{ float: 'right' }}>
                    <MenuItemCounter
                      resId={resId}
                      itemId={itemId}
                      groups={item.groups}
                      isDeliveringNow={restaurantInfo.is_delivering_now}
                    />
                  </UiView>
                </UiColumn>
              </UiRow>
              <UiRow margin>
                <UiColumn>
                  <UiText color="secondary">{item.desc}</UiText>
                </UiColumn>
              </UiRow>
              {this.hasGroups() && (
                <UiRow>
                  <UiColumn>
                    <UiText type="anchor" color="secondary">
                      customizations available
                    </UiText>
                  </UiColumn>
                </UiRow>
              )}
            </UiColumn>
          </UiRow>
        </UiCell>
      </UiRow>
    );
  }
}

const styles = {
  originalPrice: {
    marginRight: 6
  },
  counterContainer: {
    alignSelf: 'flex-start',
    alignItems: 'flex-end'
  }
};
