import React, { Component } from 'react';
import { getGrantToken, getUuidFromStorage } from './store/user/actions';
import { Router, BackButton } from './dependencies/modules/react-router-native';
import { Provider } from 'react-redux';
import getStore from './store';
import ErrorPage from './components/common/ErrorPage';

import { UiView, UiOverlay } from './dependencies/zomato-ui';

import { getPageHeight, getUrlParams } from './dependencies/modules/util';

import Partners from './dependencies/modules/partners';

import Routes from './routes';
import history from './history';

import 'font-awesome/css/font-awesome.css';

import { SentryErrorReporting, isSentryAllowed } from './util';

const store = getStore(history);

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetectLocationIndicator: true,
      error: null
    };

    this.pageParams = getUrlParams();

    if (this.pageParams.partner_client_id) {
      Partners.setClient(this.pageParams.partner_client_id);
    }
  }

  componentDidMount() {
    window.windowHeight = window.innerHeight;
    const partnerClient = Partners.getClient();

    if (Partners.isWhitelistedPartner(partnerClient.CLIENT_NAME)) {
      store.dispatch(getUuidFromStorage()).then(() => {
        this.login();
      });
    }
  }

  login() {
    this.setState({
      showDetectLocationIndicator: true,
      error: null
    });

    const skipPrompt = true;

    store
      .dispatch(getGrantToken(skipPrompt))
      .then(user => {
        if (isSentryAllowed()) {
          SentryErrorReporting.setUser(user);
        }
        this.setState({
          showDetectLocationIndicator: false
        });
      })
      .catch(error => {
        this.setState({
          showDetectLocationIndicator: false,
          error
        });
      });
  }

  renderRoutes() {
    const { showDetectLocationIndicator, error } = this.state;

    if (showDetectLocationIndicator) {
      return <UiOverlay show={showDetectLocationIndicator} />;
    }

    if (error) {
      return <ErrorPage error={error} tryAgain={() => this.login()} />;
    }

    return <Routes />;
  }

  render() {
    const partnerClient = Partners.getClient();

    if (!Partners.isWhitelistedPartner(partnerClient.CLIENT_NAME)) {
      return null;
    }

    return (
      <Provider store={store}>
        <Router history={history}>
          <BackButton>
            <UiView style={{ height: getPageHeight() }}>
              {this.renderRoutes()}
            </UiView>
          </BackButton>
        </Router>
      </Provider>
    );
  }
}
