import React, { Component } from 'react';
import { colors } from './util/Theme';
import Chip from '@material-ui/core/Chip';
import { UiView } from '.';

export default class UiChip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedList: {}
    };
  }

  componentDidMount() {
    let selectedList = { ...this.state.selectedList };

    this.props.selectedList.map(
      item =>
        (selectedList = {
          ...selectedList,
          [item]: 1
        })
    );

    this.setState({
      selectedList: selectedList
    });
  }

  chipChecked(title) {
    let selectedList = { ...this.state.selectedList };
    if (selectedList[title]) {
      delete selectedList[title];
      this.setState({ selectedList });
    } else {
      selectedList = {
        ...selectedList,
        [title]: 1
      };
      this.setState({
        selectedList: selectedList
      });
    }

    this.props.onChange(selectedList);
  }

  chipCheckedSingle(title) {
    let selectedList = { ...this.state.selectedList };

    selectedList = {
      [title]: 1
    };
    this.setState({
      selectedList: selectedList
    });

    this.props.onChange(selectedList);
  }

  handleClick(title) {
    const { type } = this.props;

    if (type !== 'single') {
      this.chipChecked(title);
    } else {
      this.chipCheckedSingle(title);
    }
  }

  render() {
    const { list, valueKey } = this.props;
    return (
      <UiView>
        {list.map(item => {
          const chipStyle = this.state.selectedList[item[valueKey]]
            ? styles.selectedChip
            : styles.chip;

          return (
            <Chip
              key={item.value}
              onClick={this.handleClick.bind(this, item[valueKey])}
              label={item.title}
              style={{ ...chipStyle, marginRight: 10 }}
            />
          );
        })}
      </UiView>
    );
  }
}

const styles = {
  chip: {
    border: `1px solid ${colors['chip']['primary']}`,
    color: `${colors['chip']['primary']}`,
    backgroundColor: 'transparent'
  },
  selectedChip: {
    backgroundColor: `${colors['chip']['primary']}`,
    color: '#fff',
    border: 0
  }
};

UiChip.defaultProps = {
  valueKey: 'value'
};
