import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import {
  UiText,
  UiRow,
  UiColumn,
  UiHeading,
  UiLink,
  UiDivider,
  UiIcon,
  UiView
} from '../../dependencies/zomato-ui';
import { colors } from '../../dependencies/zomato-ui/util/Theme';

export default class LocationSnippet extends Component {
  state = {
    iconName: null,
    iconColor: 'primary',
    displayNameWidth: 0
  };

  componentDidMount() {
    const { showIcon } = this.props;
    const displayNameWidth = ReactDOM.findDOMNode(this.refs.displayName)
      .offsetWidth;
    const iconWidth = 21;

    this.setIconName();

    if (!showIcon) {
      this.setState({
        displayNameWidth
      });
    } else {
      this.setState({
        displayNameWidth: displayNameWidth - iconWidth
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.restaurantInfo !== this.props.restaurantInfo ||
      prevProps.selectedLocation !== this.props.selectedLocation
    ) {
      this.setIconName();
    }
  }

  setIconName() {
    const {
      pageType,
      selectedLocation,
      doesRestaurantDeliverToDSZ
    } = this.props;
    if (
      selectedLocation &&
      selectedLocation.place &&
      selectedLocation.place.place_id &&
      doesRestaurantDeliverToDSZ(selectedLocation.place.place_id)
    ) {
      if (pageType === 'cart' && !selectedLocation.id) {
        this.setState({
          iconName: 'fa fa-exclamation-circle',
          iconColor: 'tertiary'
        });
      } else {
        this.setState({
          iconName: 'fa fa-check-circle',
          iconColor: 'secondary'
        });
      }
    } else {
      this.setState({
        iconName: 'fa fa-times-circle',
        iconColor: 'tertiary'
      });
    }
  }

  displayName() {
    const {
      selectedLocation,
      selectedLocationForAddress,
      pageType
    } = this.props;
    let location = selectedLocation;

    if (pageType === 'addAddress') {
      location = selectedLocationForAddress;
    }

    if (location) {
      if (location.id) {
        return `${location.alias} (${location.delivery_subzone_name})`;
      } else if (location.place) {
        return location.place.place_name;
      }
    }

    return 'Select Location';
  }

  getUrl() {
    const { type, showAddressList, resId } = this.props;
    let url = '';
    if (type === 'location') {
      url = `/select-location/${showAddressList}`;
    } else if (type === 'address') {
      url = `/select-address/${resId}`;
    }
    return url;
  }

  checkIfAddressIsInvalid() {
    const {
      pageType,
      resId,
      selectedLocation,
      doesRestaurantDeliverToDSZ
    } = this.props;

    if (!(['cart', 'restaurant'].includes(pageType) && resId)) {
      return false;
    }

    return (
      (!selectedLocation &&
        !selectedLocation.place &&
        selectedLocation.place.place_id) ||
      (pageType === 'cart' && !selectedLocation.id) ||
      !doesRestaurantDeliverToDSZ(selectedLocation.place.place_id)
    );
  }

  onClick() {
    this.props.history.push(this.getUrl());
  }

  render() {
    const { showIcon } = this.props;
    const { iconName, iconColor, displayNameWidth } = this.state;

    return (
      <UiLink href={this.getUrl()}>
        <UiRow>
          <UiColumn>
            <UiText color="secondary">DELIVERING FOOD TO</UiText>
          </UiColumn>
        </UiRow>
        <UiRow>
          <UiColumn flex={12}>
            <UiRow margin>
              {showIcon && iconName && (
                <UiIcon
                  icon={iconName}
                  color={colors['icon'][iconColor]}
                  fontSize={18}
                />
              )}
              <UiColumn ref="displayName" nonPadded>
                <UiView style={{ width: displayNameWidth }}>
                  <UiHeading size="medium" ellipsis={1}>
                    {this.displayName()}
                  </UiHeading>
                </UiView>
              </UiColumn>
            </UiRow>
          </UiColumn>
          <UiColumn>
            <UiView style={{ marginTop: 3 }}>
              <UiHeading type="anchor" align="right">
                CHANGE
              </UiHeading>
            </UiView>
          </UiColumn>
        </UiRow>
        {this.props.pageType === 'cart' && this.checkIfAddressIsInvalid() && (
          <UiRow marginBottom>
            <UiColumn>
              <UiHeading type="anchor">Select an Address</UiHeading>
            </UiColumn>
          </UiRow>
        )}
        <UiRow>
          <UiColumn>
            <UiDivider />
          </UiColumn>
        </UiRow>
      </UiLink>
    );
  }
}
