export const debounce = (fn, time) => {
  let timeout;

  return function callback(...args) {
    const functionCall = () => fn.apply(this, args);
    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
};

export const encodeFormData = data => {
  return Object.keys(data)
    .map(key =>
      data[key]
        ? encodeURIComponent(key) + '=%20' + encodeURIComponent(data[key])
        : encodeURIComponent(key) +
          '=' +
          encodeURIComponent(data[key] ? data[key] : 0)
    )
    .join('&');
};

export const isSentryAllowed = () => {
  return process.env.REACT_APP_SENTRY_DSN;
};

export const deepCompare = (o, p) => {
  let i;
  const keysO = Object.keys(o).sort();
  const keysP = Object.keys(p).sort();
  if (keysO.length !== keysP.length) {
    return false;
    //  not the same nr of keys
  }
  if (keysO.join('') !== keysP.join('')) {
    return false;
    //  different keys
  }

  for (i = 0; i < keysO.length; i += 1) {
    if (o[keysO[i]] instanceof Array) {
      if (!(p[keysO[i]] instanceof Array)) {
        return false;
      }

      //  if (deepCompare(o[keysO[i]], p[keysO[i]] === false) return false
      //  would work, too, and perhaps is a better fit, still, this is easy, too
      if (p[keysO[i]].sort().join('') !== o[keysO[i]].sort().join('')) {
        return false;
      }
    } else if (o[keysO[i]] instanceof Date) {
      if (!(p[keysO[i]] instanceof Date)) {
        return false;
      }
      if (`${o[keysO[i]]}` !== `${p[keysO[i]]}`) {
        return false;
      }
    } else if (o[keysO[i]] instanceof Function) {
      if (!(p[keysO[i]] instanceof Function)) {
        return false;
        //  ignore functions, or check them regardless?
      }
    } else if (o[keysO[i]] instanceof Object) {
      if (!(p[keysO[i]] instanceof Object)) {
        return false;
      }

      if (o[keysO[i]] === o) {
        //  self reference?
        if (p[keysO[i]] !== p) {
          return false;
        }
      } else if (deepCompare(o[keysO[i]], p[keysO[i]]) === false) {
        return false;
        //  WARNING: does not deal with circular refs other than ^^
      }
    }
    if (o[keysO[i]] !== p[keysO[i]]) {
      //  change !== to != for loose comparison
      return false;
      //  not the same value
    }
  }
  return true;
};

export const arrayCompare = (a, b) => {
  if (a.length !== b.length) {
    return false;
  }

  const seen = {};
  a.forEach(v => {
    const key = typeof v + v;
    if (!seen[key]) {
      seen[key] = 0;
    }
    seen[key] += 1;
  });

  return b.every(v => {
    const key = typeof v + v;
    if (seen[key]) {
      seen[key] -= 1;
      return true;
    }
    return false;
  });
};

export const getAmountStringWithCurrency = (
  currency,
  amount,
  currencyAffix = 'prefix'
) => {
  if (currencyAffix === 'prefix') {
    return currency + amount.toString();
  }
  return amount.toString() + currency;
};

export const serialize = params => {
  const queryString = [];

  Object.keys(params).forEach(key => {
    const str = [encodeURIComponent(key), encodeURIComponent(params[key])];
    queryString.push(str.join('='));
  });

  return queryString.join('&');
};

export const getErrorTitleMessage = error => {
  let errorTitle = 'Error detecting location';
  let errorMessage =
    'There was some error while detecting your location. Please check if you have enabled device location service.';

  if (error && error.message) {
    const newError = error.message.split(' ');
    const errorCode = newError[newError.length - 1];

    if (errorCode === 'API_PERMISSION_DENIED') {
      errorTitle = 'Location Permission Denied';
      errorMessage =
        'Zomato uses this permission to detect your current location and show you great restaurants around you.';
    }
  }

  return {
    errorTitle,
    errorMessage
  };
};
