import React, { Component } from 'react';
import { List } from '../../dependencies/zomato-ui';

import { OrderItem } from '../summary';

export default class OrderItems extends Component {
  render() {
    const { orderItems } = this.props;
    return (
      <List
        data={orderItems}
        renderItem={orderItem => (
          <OrderItem key={orderItem.order_item_id} orderItem={orderItem} />
        )}
      />
    );
  }
}
