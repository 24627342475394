import React, { Component } from 'react';
import { UiView, UiHeading, UiAnchor } from '../../dependencies/zomato-ui';
import { colors } from '../../dependencies/zomato-ui/util/Theme';

export default class CallRestaurant extends Component {
  render() {
    const { mobilePhoneDisplay, restaurantName } = this.props;

    if (!mobilePhoneDisplay) {
      return null;
    }

    const mobileNumbers = mobilePhoneDisplay.split(',');

    return (
      <UiView>
        <UiHeading size="medium">
          Call {restaurantName + ' '} (
          {mobileNumbers.map((mobileNumber, index) => {
            return (
              <UiAnchor
                color={colors['text']['primary']}
                href={'tel:' + mobileNumber}
              >
                {mobileNumber}
                {index === mobileNumbers.length - 1 ? '' : ','}
              </UiAnchor>
            );
          })}
          )
        </UiHeading>
      </UiView>
    );
  }
}
