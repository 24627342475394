import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import RestaurantCategory from './../../components/restaurant/RestaurantCategory';

export default withRouter(
  connect((state, ownProps) => {
    const { categories } = state.restaurantState;
    const category = categories[ownProps.categoryId];
    return { category };
  })(RestaurantCategory)
);
