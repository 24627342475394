import React, { Component } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import { colors } from './util/Theme';

class UiLoader extends Component {
  styles = {
    colorPrimary: {
      color: this.props.color ? this.props.color : colors['switch']['primary']
    }
  };

  div = props => {
    return (
      <CircularProgress
        size={this.props.size}
        classes={{
          colorPrimary: props.classes.colorPrimary
        }}
      />
    );
  };

  Styled = withStyles(this.styles)(this.div);

  render() {
    return <this.Styled>{this.props.children}</this.Styled>;
  }
}

UiLoader.defaultProps = {
  size: 40
};

export default UiLoader;
