import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import CartItemCounter from '../../components/cart/CartItemCounter';

import {
  increaseCartItemQuantity,
  decreaseCartItemQuantity
} from '../../store/cart/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { cartItems } = state.cartState;
      const cartItemId = ownProps.cartItemId;
      const itemId = ownProps.itemId;
      const cartItemQuantity = cartItems[cartItemId].quantity;
      return { cartItemQuantity, cartItemId, itemId };
    },
    {
      increaseCartItemQuantity,
      decreaseCartItemQuantity
    }
  )(CartItemCounter)
);
