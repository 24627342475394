import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import LocationSnippet from '../../components/common/LocationSnippet';

import { doesRestaurantDeliverToDSZ } from '../../store/restaurant/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const {
        selectedLocation,
        selectedLocationForAddress
      } = state.locationsState;
      const { restaurantInfo } = state.restaurantState;
      const { type, showAddressList, resId } = ownProps;
      return {
        selectedLocation,
        type,
        showAddressList,
        resId,
        restaurantInfo,
        selectedLocationForAddress
      };
    },
    { doesRestaurantDeliverToDSZ }
  )(LocationSnippet)
);
