import React, { Component } from 'react';
import {
  UiRow,
  UiColumn,
  UiCell,
  UiHeading,
  UiLink
} from '../../dependencies/zomato-ui';

export default class RestaurantDetails extends Component {
  render() {
    const { title, buttonTitle, tabId } = this.props;
    return (
      <UiCell padded paddedBorder borderBottom="default">
        <UiRow margin>
          <UiColumn nonPadded>
            <UiHeading size="large">{title}</UiHeading>
            <UiLink href={`/order-summary/${tabId}`}>
              <UiRow marginTop>
                <UiColumn nonPadded>
                  <UiHeading size="small" type="anchor">
                    {buttonTitle}
                  </UiHeading>
                </UiColumn>
              </UiRow>
            </UiLink>
          </UiColumn>
        </UiRow>
      </UiCell>
    );
  }
}
