import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import MenuList from './../../components/restaurant/MenuList';

export default withRouter(
  connect((state, ownProps) => {
    const { menusList, menus } = state.restaurantState;
    return { menusList, menus };
  })(MenuList)
);
