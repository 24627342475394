import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { CartPage } from '../../components/pages';
import { getUserPermission } from '../../store/user/actions';
import { setRestaurant } from '../../store/restaurant/actions';
import { selectLocation } from '../../store/locations/actions';
import {
  setCartFromStorage,
  getCartCalculations,
  setCartCalculation,
  setCalculatingCart
} from '../../store/cart/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const {
        calculatingCart,
        error,
        calculatingUpdatedCart
      } = state.cartState;
      const cartResId = state.cartState.resId;
      const resId = ownProps.match.params.resId;
      return {
        cartResId,
        resId,
        calculatingCart,
        error,
        calculatingUpdatedCart
      };
    },
    {
      getUserPermission,
      selectLocation,
      setRestaurant,
      setCartFromStorage,
      getCartCalculations,
      setCartCalculation,
      setCalculatingCart
    }
  )(CartPage)
);
