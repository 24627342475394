import React, { Component } from 'react';

import Popover from '@material-ui/core/Popover';

export default class UiPopover extends Component {
  render() {
    const { children, id, open, top } = this.props;

    return (
      <Popover
        id={id}
        style={{ top: top }}
        open={open}
        onClose={this.props.onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        {children}
      </Popover>
    );
  }
}
