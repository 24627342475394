import React, { Component } from 'react';

import {
  UiView,
  UiText,
  UiRow,
  UiColumn,
  UiHeading,
  UiCell
} from './../../dependencies/zomato-ui';

export default class Address extends Component {
  selectAddress() {
    const { address } = this.props;
    this.props.selectLocation(address);
    this.props.history.goBack();
  }

  render() {
    const { address } = this.props;

    if (!address.id) {
      return null;
    }

    return (
      <UiView onClick={this.selectAddress.bind(this)}>
        <UiCell padded paddedBorder>
          <UiView style={styles.savedAddressContainer}>
            <UiRow>
              <UiColumn nonPadded>
                <UiHeading size="big">{address.alias}</UiHeading>
              </UiColumn>
            </UiRow>
            <UiRow>
              <UiColumn nonPadded>
                <UiText size="medium" color="secondary">
                  {address.address}, {address.delivery_subzone_name}
                </UiText>
              </UiColumn>
            </UiRow>
          </UiView>
        </UiCell>
      </UiView>
    );
  }
}

var styles = {
  savedAddressContainer: {
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 10,
    borderColor: '#dedede',
    borderWidth: 1,
    borderStyle: 'solid'
  }
};
