import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import CartItemCustomisations from '../../components/cart/CartItemCustomisations';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { groups, groupItems } = state.restaurantState;
      const cartItemId = ownProps.cartItemId;
      const { cartItems } = state.cartState;
      return { cartItems, groups, groupItems, cartItemId };
    },
    {}
  )(CartItemCustomisations)
);
