import React, { Component } from 'react';

import {
  UiView,
  UiScroller,
  UiRow,
  UiColumn,
  UiImage,
  UiHeading,
  UiPopover,
  UiBackdrop,
  UiFloatingActionButton
} from '../../dependencies/zomato-ui';

import { MenuList } from '../../containers/restaurant';

export default class MenuButton extends Component {
  static CART_BUTTON_OPENED = 'open';
  static CART_BUTTON_CLOSED = 'closed';
  static CART_BUTTON_OPEN_WITH_DISCOUNT = 'openWithDiscount';

  state = {
    isVisible: false
  };

  handleClick() {
    this.setState({
      isVisible: true
    });
  }

  handleClose() {
    this.setState({
      isVisible: false
    });
  }

  scrollOnSelectedMenu(selectedMenuId, isVisible) {
    this.setState({
      isVisible: isVisible
    });
    this.props.scrollOnSelectedMenu(selectedMenuId);
  }

  getCartButtonState() {
    const {
      cartCalculations,
      showDiscountSuggestion,
      minOrderAlert,
      resId,
      currentResId
    } = this.props;
    let menuButtonContainerClass = MenuButton.CART_BUTTON_CLOSED;

    if (resId != currentResId) {
      return menuButtonContainerClass;
    }

    if (
      cartCalculations.totalItems &&
      !showDiscountSuggestion &&
      !minOrderAlert
    ) {
      menuButtonContainerClass = MenuButton.CART_BUTTON_OPENED;
    } else if (
      cartCalculations.totalItems &&
      (showDiscountSuggestion || minOrderAlert)
    ) {
      menuButtonContainerClass = MenuButton.CART_BUTTON_OPEN_WITH_DISCOUNT;
    }
    return menuButtonContainerClass;
  }

  getMenuButtonStyle(cartButtonState) {
    switch (cartButtonState) {
      case MenuButton.CART_BUTTON_CLOSED:
        return {
          bottom: '25px'
        };
        break;
      case MenuButton.CART_BUTTON_OPENED:
        return {
          bottom: '90px'
        };
        break;
      case MenuButton.CART_BUTTON_OPEN_WITH_DISCOUNT:
        return {
          bottom: '90px'
        };
        break;
    }
  }

  getMenuPopoverTopOffset(cartButtonState) {
    switch (cartButtonState) {
      case MenuButton.CART_BUTTON_CLOSED:
        return '-60px';
        break;
      case MenuButton.CART_BUTTON_OPENED:
        return '-120px';
        break;
      case MenuButton.CART_BUTTON_OPEN_WITH_DISCOUNT:
        return '-120px';
        break;
    }
  }

  render() {
    const { isVisible } = this.state;
    const id = isVisible ? 'simple-popover' : undefined;
    const cartButtonState = this.getCartButtonState();

    return (
      <UiView
        style={{
          ...styles.menuButton,
          ...this.getMenuButtonStyle(cartButtonState)
        }}
      >
        <UiBackdrop show={isVisible} />
        <UiPopover
          id={id}
          top={this.getMenuPopoverTopOffset(cartButtonState)}
          open={isVisible}
          onClose={this.handleClose.bind(this)}
        >
          <UiScroller>
            <UiRow>
              <UiColumn doublePadded>
                <UiView ref="menu">
                  <MenuList
                    scrollOnSelectedMenu={this.scrollOnSelectedMenu.bind(this)}
                  />
                </UiView>
              </UiColumn>
            </UiRow>
          </UiScroller>
        </UiPopover>
        <UiFloatingActionButton
          variant="extended"
          style={{ backgroundColor: '#4a90e2', height: 35 }}
          onClick={this.handleClick.bind(this)}
        >
          <UiHeading color="#fff">Menu</UiHeading>
          <UiImage
            height={20}
            width={20}
            src={
              'https://b.zmtcdn.com/data/o2_assets/95c02e69c4da5d59b028b955706ce7141539757719'
            }
          />
        </UiFloatingActionButton>
      </UiView>
    );
  }
}

const styles = {
  menuButton: {
    position: 'fixed',
    bottom: 10,
    right: 10,
    zIndex: 11
  },
  menuBtnContainer: {
    bottom: 25
  },
  menuBtnWithCartBtn: {
    bottom: 90
  },
  menuBtnWithDiscount: {
    bottom: 90
  }
};
