import React, { Component } from 'react';

import Partners from '../../dependencies/modules/partners';

import {
  UiText,
  UiRow,
  UiColumn,
  UiHeading,
  UiView,
  UiIcon,
  UiSnackbar,
  UiCell,
  UiLoader
} from '../../dependencies/zomato-ui';

import { colors } from '../../dependencies/zomato-ui/util/Theme';

export default class CheckoutButton extends Component {
  state = {
    openCartSnackbar: false,
    error: null,
    showLoader: false
  };

  redirectOnStatus(status, tabId) {
    switch (status) {
      case 'success':
        this.props.history.push(`/order-details/${tabId}`);
        break;

      case 'failed':
        this.props.history.push(`/order-summary/${tabId}`);
        break;

      case 'pending':
        this.props.history.push(`/verify-payment-status/${tabId}`);
        break;

      default:
        break;
    }
  }

  onClick() {
    const {
      resId,
      isLoading,
      showCartError,
      selectedLocation,
      promocode,
      mobileNumber,
      name,
      email,
      uuid
    } = this.props;
    if (isLoading || showCartError) {
      return;
    }

    this.setState({
      showLoader: true
    });

    if (!name || !mobileNumber) {
      this.props
        .getUserPermission()
        .then(response => {})
        .catch(error => {
          const errorMsg = 'Phone number is required.';

          this.setState({
            openCartSnackbar: true,
            error: errorMsg
          });

          throw new Error(errorMsg);
        });

      this.setState({
        showLoader: false
      });

      return;
    }

    if (this.isDeliveringAddressSelected()) {
      const partnerClient = Partners.getClient();
      const dishes = this.props.buildCartObject();
      const cartObject = {};
      this.loading = true;
      this.showCheckoutError = false;
      cartObject.resId = resId;
      cartObject.addressId = selectedLocation.id ? selectedLocation.id : null;
      cartObject.paymentMethodType = partnerClient.getPaymentMethodType();
      cartObject.paymentMethodId = 0;
      cartObject.phone = mobileNumber;
      cartObject.email = email;
      cartObject.name = name;
      cartObject.dishes = dishes;
      if (
        promocode &&
        promocode.valid &&
        promocode.code &&
        promocode.code.length
      ) {
        cartObject.promocode = promocode.code;
      }

      this.props
        .checkout(cartObject)
        .then(checkoutResponse => {
          if (checkoutResponse.status === 'success') {
            const partners = Partners.getClient();
            checkoutResponse.uuid = uuid;

            partners.requestPayment(checkoutResponse).then(gpayResponse => {
              const partnerClient = Partners.getClient();

              if (
                Partners.isRedirectAllowedPartner(partnerClient.CLIENT_NAME)
              ) {
                return;
              }

              const params = {
                order_id: checkoutResponse.data.order_id,
                data: gpayResponse.data,
                flow_type: checkoutResponse.response.flow_type
              };

              this.props
                .completePayment(params)
                .then(response => {
                  this.redirectOnStatus(
                    response.status,
                    checkoutResponse.data.order_id
                  );
                })
                .catch(error => {
                  this.props.history.push(
                    `/verify-payment-status/${checkoutResponse.data.order_id}`
                  );
                });
            });
          } else {
            this.setState({
              openCartSnackbar: true,
              error: checkoutResponse.message
            });
          }

          this.setState({
            showLoader: false
          });
        })
        .catch(error => {
          this.setState({
            openCartSnackbar: true,
            error: 'Something went wrong! Please try again.',
            showLoader: false
          });
        });
    } else {
      this.props.history.push(`/select-address/${resId}`);
    }
  }

  title() {
    if (this.isPlacingOrder()) {
      return `Place Order (${this.cartTotal().display_cost.text})`;
    }
    return 'Next';
  }

  cartTotal() {
    let total = {};
    if (!this.props.cartCalculations.items) {
      return total;
    }

    this.props.cartCalculations.items.some(item => {
      if (item.type === 'total_user') {
        total = item;
        return true;
      }
      return false;
    });

    return total;
  }

  isPlacingOrder() {
    return (
      this.isDeliveringAddressSelected() &&
      this.cartTotal() &&
      this.cartTotal().display_cost
    );
  }

  isDeliveringAddressSelected() {
    const { selectedLocation } = this.props;
    return (
      selectedLocation &&
      selectedLocation.id &&
      (selectedLocation.is_delivery_location_for_restaurant ||
        this.doesRestaurantDeliverToDSZ(selectedLocation.place.place_id))
    );
  }

  doesRestaurantDeliverToDSZ(selectedLocationPlaceId) {
    const deliverySubzones = this.props.restaurantInfo.delivery_subzones;
    let doesRestaurantDeliver = false;

    if (!deliverySubzones) {
      return false;
    }

    Object.values(deliverySubzones).some(deliverySubzone => {
      if (
        deliverySubzone.delivery_subzone_id === Number(selectedLocationPlaceId)
      ) {
        doesRestaurantDeliver = true;
        return true;
      }
      return false;
    });

    return doesRestaurantDeliver;
  }

  subtitle() {
    if (this.isDeliveringAddressSelected()) {
      return 'PAY ON NEXT STEP';
    }
    return 'SELECT AN ADDRESS';
  }

  handleClose() {
    this.setState({
      openCartSnackbar: false
    });
  }

  render() {
    const { openCartSnackbar, error, showLoader } = this.state;
    const { calculatingUpdatedCart } = this.props;

    return (
      <UiView
        style={{
          ...styles.checkoutButton,
          backgroundColor: colors['button']['primary']
        }}
        onClick={this.onClick.bind(this)}
      >
        <UiCell margin padded>
          <UiRow>
            <UiColumn doublePadded>
              {this.props.cartError && (
                <UiRow>
                  <UiColumn>
                    <UiText size="small" align="center" color="inverted">
                      Cart error
                    </UiText>
                  </UiColumn>
                </UiRow>
              )}
              {this.props.showCheckoutError && (
                <UiRow>
                  <UiColumn>
                    <UiText size="small" align="center" color="inverted">
                      Checkout error
                    </UiText>
                  </UiColumn>
                </UiRow>
              )}
              <UiRow style={styles.checkoutBlue}>
                <UiColumn nonPadded>
                  <UiHeading size="medium" color="inverted">
                    {this.title()}
                  </UiHeading>
                  <UiText size="mini" color="inverted">
                    {this.subtitle()}
                  </UiText>
                </UiColumn>
                <UiView
                  style={{
                    justifyContent: 'flex-end',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {showLoader || calculatingUpdatedCart ? (
                    <UiLoader show={showLoader} color={'#fff'} size={20} />
                  ) : (
                    <UiIcon
                      color={'#fff'}
                      fontSize={16}
                      icon={'fa fa-arrow-circle-right'}
                    />
                  )}
                </UiView>
              </UiRow>
            </UiColumn>
          </UiRow>
        </UiCell>
        <UiSnackbar
          open={openCartSnackbar}
          message={error && error}
          handleClose={this.handleClose.bind(this)}
        />
      </UiView>
    );
  }
}

var styles = {
  checkoutButton: {
    position: 'fixed',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    left: 0,
    bottom: 0,
    right: 0
  },
  checkoutBlue: {
    height: 120,
    alignItems: 'center'
  }
};
