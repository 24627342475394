import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { RestaurantSnippet } from '../../components/summary';

export default withRouter(
  connect((state, ownProps) => {
    const { summary } = state.orderState;
    const { restaurant, resId } = summary;
    const name = restaurant.name;
    const location = restaurant.location;
    return { name, location, resId };
  })(RestaurantSnippet)
);
