import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import MenuFilters from '../../components/common/MenuFilters';

import { setRestaurantFilteredData } from '../../store/restaurant/actions';

export default withRouter(
  connect(
    state => {
      const { restaurantInfo, vegOnly, containsEgg } = state.restaurantState;
      return { restaurantInfo, vegOnly, containsEgg };
    },
    { setRestaurantFilteredData }
  )(MenuFilters)
);
