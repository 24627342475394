import { restaurant } from '../../api';
import { Storage } from '../../util';

export const SET_RESTAURANT_DATA = 'SET_RESTAURANT_DATA';
export const SET_RESTAURANT_RESPONSE = 'SET_RESTAURANT_RESPONSE';
export const SET_MENU_FILTERS = 'SET_MENU_FILTERS';
export const SET_RESTAURANT_FILTERED_DATA = 'SET_RESTAURANT_FILTERED_DATA';
export const SET_CHOOSE_CUSTOMISATIONS_POPUP_VISIBLE_FLAG =
  'SET_CHOOSE_CUSTOMISATIONS_POPUP_VISIBLE_FLAG';
export const SET_CHANGE_CUSTOMISED_ITEM_QUANTITY_POPUP_VISIBLE_FLAG =
  'SET_CHANGE_CUSTOMISED_ITEM_QUANTITY_POPUP_VISIBLE_FLAG';

const setRestaurantData = ({
  restaurantInfo,
  offer,
  menusList,
  menus,
  categories,
  items,
  groups,
  groupItems
}) => ({
  type: SET_RESTAURANT_DATA,
  restaurantInfo,
  offer,
  menusList,
  menus,
  categories,
  items,
  groups,
  groupItems
});

const setRestaurantResponse = response => dispatch => {
  dispatch({
    type: SET_RESTAURANT_RESPONSE,
    response
  });
};

export const setMenuFilters = params => dispatch => {
  dispatch({
    type: SET_MENU_FILTERS,
    vegOnly: params.vegOnly,
    containsEgg: params.containsEgg
  });
};

const setGroupsData = (dispatchData, item, groups, parentItem) => {
  const dispatch = dispatchData;
  groups.forEach(groupObj => {
    const group = { ...groupObj };
    item.allGroups.push(group.group_id);
    const groupItems = [...group.items];
    group.items = [];
    if (parentItem) {
      parentItem.groups.push(group.group_id);
      group.parentItemId = parentItem.item_id;
    } else {
      item.groups.push(group.group_id);
    }
    dispatch.groups[group.group_id] = group;

    if (!groupItems || !groupItems.length) {
      return;
    }

    groupItems.forEach(groupItemObj => {
      const groupItem = { ...groupItemObj };
      group.items.push(groupItem);
      if (!groupItem || !groupItem.item_id) {
        return;
      }

      const groupItemGroups = groupItem.groups ? [...groupItem.groups] : [];
      groupItem.groups = [];
      dispatch.groupItems[groupItem.item_id] = groupItem;

      if (!groupItemGroups || !groupItemGroups.length) {
        return;
      }

      setGroupsData(dispatchData, item, groupItemGroups, groupItem);
    });
  });
};

const checkItemValidity = ({ item }) => item.min_price;

const shouldItemBeIncluded = ({ params, item }) =>
  checkItemValidity({ item }) && checkVegOrEggItem({ params, item });

const checkVegOrEggItem = ({ params, item }) => {
  let addItem = true;
  if (params && params.vegOnly && item.tag_ids) {
    addItem = false;
    const tagIds = item.tag_ids.split(',');

    if (
      (params.vegOnly && tagIds.indexOf('1') > -1) ||
      (params.containsEgg && tagIds.indexOf('24') > -1)
    ) {
      addItem = true;
    }
  }

  return addItem;
};

export const getRestaurant = params => dispatch => {
  const dispatchData = {
    restaurantInfo: {},
    offer: null,
    menusList: [],
    menus: {},
    categories: {},
    items: {},
    groups: {},
    groupItems: {}
  };

  dispatch(setRestaurantData(dispatchData));

  return restaurant
    .getData(params)
    .then(response => {
      const menus = response.menus;
      dispatchData.restaurantInfo = response.restaurant_info;

      const { offers } = response.restaurant_info;
      if (offers && offers.length) {
        const { offer } = offers[0];
        if (offer) {
          delete dispatchData.restaurantInfo.offers;
          dispatchData.offer = offer;
        }
      }

      menus.forEach(menuObj => {
        const menu = { ...menuObj };
        const { categories } = { ...menu };
        menu.categories = [];
        menu.ItemCount = 0;
        dispatchData.menus[menu.menu_id] = menu;

        if (!categories || !categories.length) {
          return;
        }

        categories.forEach(categoryObj => {
          const category = { ...categoryObj };
          const { items } = { ...category };
          category.items = [];

          if (!items || !items.length) {
            return;
          }

          dispatchData.categories[category.category_id] = category;
          items.forEach(itemObj => {
            const item = { ...itemObj };

            if (!shouldItemBeIncluded({ params, item })) {
              return;
            }

            category.items.push(item);
            const { groups } = { ...item };
            item.groups = [];
            item.allGroups = [];
            dispatchData.items[item.item_id] = item;

            if (!groups || !groups.length) {
              return;
            }

            setGroupsData(dispatchData, item, groups);
          });

          if (category.items.length) {
            menu.ItemCount += category.items.length;
            menu.categories.push(category.category_id);
          }
        });

        if (menu.categories.length) {
          dispatchData.menusList.push(menu.menu_id);
        }
      });

      dispatch(setRestaurantResponse(response));
      dispatch(setRestaurantData(dispatchData));
    })
    .catch(error => console.log(error));
};

export const setRestaurantFilteredData = params => (dispatch, getState) => {
  const state = getState().restaurantState;
  const response = state.response;
  const menus = response.menus;
  const dispatchData = {
    menusList: [],
    categories: {}
  };

  menus.forEach(menuObj => {
    const menu = { ...menuObj };
    const { categories } = { ...menu };
    menu.categories = [];

    if (!categories || !categories.length) {
      return;
    }

    categories.forEach(categoryObj => {
      const category = { ...categoryObj };
      const { items } = { ...category };
      category.items = [];

      if (!items || !items.length) {
        return;
      }

      dispatchData.categories[category.category_id] = category;
      items.forEach(itemObj => {
        const item = { ...itemObj };
        let addItem = true;

        if (params && params.vegOnly && item.tag_ids) {
          addItem = false;
          const tagIds = item.tag_ids.split(',');

          if (
            (params.vegOnly && tagIds.indexOf('1') > -1) ||
            (params.containsEgg && tagIds.indexOf('24') > -1)
          ) {
            addItem = true;
          }
        }

        if (!addItem) {
          return;
        }

        category.items.push(item);
      });

      if (category.items.length) {
        menu.categories.push(category.category_id);
      }
    });

    if (menu.categories.length) {
      dispatchData.menusList.push(menu.menu_id);
    }
  });

  dispatch(setMenuFilters(params));

  dispatch({
    type: SET_RESTAURANT_FILTERED_DATA,
    dispatchData
  });
};

export const toggleChooseCustomisationsPopup = (
  isChooseCustomisationsPopupVisible,
  itemId = 0
) => dispatch => {
  dispatch({
    type: SET_CHOOSE_CUSTOMISATIONS_POPUP_VISIBLE_FLAG,
    isChooseCustomisationsPopupVisible,
    itemId
  });
};

export const toggleChangeCustomisedItemQuantityPopup = (
  isChangeCustomisedItemQuantityPopupVisible,
  itemId
) => dispatch => {
  dispatch({
    type: SET_CHANGE_CUSTOMISED_ITEM_QUANTITY_POPUP_VISIBLE_FLAG,
    isChangeCustomisedItemQuantityPopupVisible,
    itemId
  });
};

export const doesRestaurantDeliverToDSZ = selectedLocationPlaceId => (
  dispatch,
  getState
) => {
  const state = getState().restaurantState;
  const deliverySubzones = state.restaurantInfo.delivery_subzones;
  let doesRestaurantDeliver = false;

  if (!deliverySubzones) {
    return false;
  }

  Object.values(deliverySubzones).some(deliverySubzone => {
    if (
      deliverySubzone.delivery_subzone_id === Number(selectedLocationPlaceId)
    ) {
      doesRestaurantDeliver = true;
      return true;
    }
    return false;
  });

  return doesRestaurantDeliver;
};

export const generateItemObject = params => (dispatch, getState) => {
  const state = getState().restaurantState;
  const dispatchData = params.dispatchData;
  const itemId = params.itemId;
  const item = state.items[itemId];
  dispatchData.items[itemId] = item;

  if (!item.groups || !item.groups.length) {
    return;
  }

  item.groups.forEach(groupId => {
    const group = state.groups[groupId];
    dispatchData.groups[groupId] = group;
    dispatch(generateGroupItemsObject({ dispatchData, group }));
  });
};

export const generateGroupItemsObject = params => (dispatch, getState) => {
  const state = getState().restaurantState;
  const dispatchData = params.dispatchData;
  const group = params.group;
  if (!group || !group.items || !group.items.length) {
    return;
  }
  group.items.forEach(groupItem => {
    dispatchData.groupItems[groupItem.item_id] = groupItem;
    if (!groupItem.groups || !groupItem.groups.length) {
      return;
    }

    groupItem.groups.forEach(groupId => {
      const nestedGroup = state.groups[groupId];
      dispatchData.groups[groupId] = nestedGroup;
      dispatch(generateGroupItemsObject({ dispatchData, group: nestedGroup }));
    });
  });
};

export const setRestaurant = () => (dispatch, getState) => {
  const { restaurantInfo } = getState().restaurantState;
  return Storage.getItem('restaurant').then(value => {
    if (!value || restaurantInfo.id === value.restaurantInfo.id) {
      return;
    }

    dispatch(setRestaurantData(value));
  });
};
