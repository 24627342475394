import { StateManager } from '../../util';
import {
  SET_LOCATIONS_LIST,
  SELECT_LOCATION,
  CLEAR_LOCATIONS,
  SELECT_LOCATION_FOR_ADDRESS,
  CLEAR_SELECTED_LOCATION_FOR_ADDRESS,
  SELECT_CURRENT_LAT_LNG
} from './actions';

export const initialState = {
  selectedLocation: null,
  selectedLocationForAddress: null,
  locations: null,
  currentLatLong: {}
};

const setLocationsList = (state, action) => {
  const { locations } = action;
  return {
    ...state,
    locations
  };
};

const selectLocation = (state, action) => {
  const { location } = action;
  return {
    ...state,
    selectedLocation: location
  };
};

const selectLocationForAddress = (state, action) => {
  const { selectedLocationForAddress } = action;
  return {
    ...state,
    selectedLocationForAddress
  };
};

const clearSelectedLocationForAddress = (state, action) => {
  return {
    ...state,
    selectedLocationForAddress: null
  };
};

const setCurrentLatLng = (state, action) => {
  const { currentLatLong } = action;
  return {
    ...state,
    currentLatLong
  };
};

const clearLocations = (state, action) => {
  return {
    ...state,
    locations: null
  };
};

const actions = {
  [SET_LOCATIONS_LIST]: setLocationsList,
  [SELECT_LOCATION]: selectLocation,
  [CLEAR_LOCATIONS]: clearLocations,
  [SELECT_LOCATION_FOR_ADDRESS]: selectLocationForAddress,
  [CLEAR_SELECTED_LOCATION_FOR_ADDRESS]: clearSelectedLocationForAddress,
  [SELECT_CURRENT_LAT_LNG]: setCurrentLatLng
};

const manager = new StateManager(initialState, actions);

export default manager.reducer;
