import React, { Component } from 'react';

import {
  UiRow,
  UiColumn,
  UiView,
  UiHeading,
  UiCell
} from './../../dependencies/zomato-ui';

import AddAddress from '../../containers/address/AddAddressContainer';
import LocationSnippet from '../../containers/common/LocationSnippetContainer';
import AppHeader from '../common/AppHeader';

export default class AddressPage extends Component {
  render() {
    const { resId } = this.props;
    const showAddressList = false;

    return (
      <UiView>
        <AppHeader
          iconColor={'#999999'}
          iconSize={18}
          showHeaderTitle
          showBackButton
          title={'Delivery Address'}
          onBack={() => this.props.history.goBack()}
        >
          <UiRow>
            <UiColumn>
              <UiRow>
                <UiColumn>
                  <UiCell margin padded>
                    <UiHeading size="huge">Add Address</UiHeading>
                  </UiCell>
                </UiColumn>
              </UiRow>
              <LocationSnippet
                type="location"
                pageType="addAddress"
                showIcon={resId}
                showAddressList={showAddressList}
              />
              <UiRow>
                <UiColumn>
                  <AddAddress />
                </UiColumn>
              </UiRow>
            </UiColumn>
          </UiRow>
        </AppHeader>
      </UiView>
    );
  }
}
