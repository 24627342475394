import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { RiderDetails } from '../../components/crystal';

export default withRouter(
  connect(state => {
    const { crystal } = state.orderState;
    const { riderDetails } = crystal;
    return { riderDetails };
  })(RiderDetails)
);
