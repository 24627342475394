import { addressModel } from '../../api';

export const SET_ADDRESS_DATA = 'SET_ADDRESS_DATA';
export const SET_SELECTED_ADDRESS = 'SET_SELECTED_ADDRESS';
export const SET_NEW_ADDRESS_DATA = 'SET_NEW_ADDRESS_DATA';
export const SET_NEW_ADDRESS = 'SET_NEW_ADDRESS';

const setAddressData = ({ addressList, addresses }) => ({
  type: SET_ADDRESS_DATA,
  addressList,
  addresses
});

export const getUserAddresses = params => dispatch => {
  return addressModel.getUserAddresses(params).then(addressResponse => {
    const { response } = addressResponse;
    const dispatchData = {
      addressList: [],
      addresses: {}
    };
    if (!response) {
      return;
    }

    const { user } = response;
    if (!user) {
      return;
    }

    let { addresses } = user;
    if (!addresses) {
      addresses = [];
    }

    const addressList = [];
    const allAddresses = {};
    addresses.forEach(({ address }) => {
      addressList.push(address.id);
      allAddresses[address.id] = address;
    });

    dispatchData.addressList = addressList;
    dispatchData.addresses = allAddresses;

    dispatch(setAddressData(dispatchData));
  });
};

export const setNewAddressData = address => dispatch => {
  dispatch({
    type: SET_NEW_ADDRESS_DATA,
    address
  });
};

export const addUserAddress = data => dispatch => {
  return addressModel.addUserAddress(data).then(addressResponse => {
    const { response } = addressResponse;
    if (response.status === 'success') {
      if (!response) {
        return null;
      }

      const addressData = response.address_data;

      if (!addressData) {
        return null;
      }

      dispatch(setNewAddress(addressData));
    }
    return response;
  });
};

export const setNewAddress = address => (dispatch, getState) => {
  const state = getState().addressState;
  const addresses = { ...state.addresses };
  let addressList = [...state.addressList];

  addresses[address.id] = address;
  addressList = [address.id, ...addressList];

  dispatch({
    type: SET_NEW_ADDRESS,
    addressList,
    addresses
  });
};
