import React, { Component } from 'react';
import UiText from './Text';

export default class UiHeading extends Component<{}> {
  render() {
    return <UiText {...this.props} />;
  }
}

UiHeading.defaultProps = {
  type: 'heading',
  size: 'small',
  color: 'primary',
  align: 'left',
  decoration: 'none',
  weight: '600'
};
