import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import MenuButton from './../../components/restaurant/MenuButton';

import {
  showDiscountSuggestion,
  minOrderAlert
} from '../../store/cart/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { cartCalculations, resId } = state.cartState;
      const currentResId = ownProps.match.params.resId;
      return { cartCalculations, resId, currentResId };
    },
    {
      showDiscountSuggestion,
      minOrderAlert
    }
  )(MenuButton)
);
