import { Fetch } from './helpers';
import { encodeFormData } from './../util/helpers';

export default {
  getUserAddresses(params) {
    const { latitude, longitude, resId } = params;

    const queryParams = {
      latitude: params.latitude ? latitude : 0,
      longitude: params.longitude ? longitude : 0,
      ...(resId && { res_id: resId })
    };

    const path = 'v2/order/address/get_user_addresses_with_subzones.json';

    return Fetch.get({ path, queryParams });
  },
  addUserAddress(data) {
    if (!data) {
      return Promise.reject('Required field');
    }

    const body = encodeFormData(data);
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    const path = 'v2/order/address/upload_user_address.json';

    return Fetch.post({ path }, { headers, body });
  }
};
