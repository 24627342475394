import { StateManager } from '../../util';
import {
  SET_SELECTED_CUSTOMISED_ITEMS,
  SET_SELECTED_DEPENDENCIES,
  CLEAR_CUSTOMISATIONS
} from './actions';

export const initialState = {
  selectedCustomisedItems: {},
  selectedDependencies: []
};

const setSelectedCustomisedItems = (state, action) => {
  const { selectedCustomisedItems } = action;
  return {
    ...state,
    selectedCustomisedItems: { ...selectedCustomisedItems }
  };
};

const setSelectedDependencies = (state, action) => {
  const { selectedDependencies } = action;
  return {
    ...state,
    selectedDependencies: [...selectedDependencies]
  };
};

const clearCustomisations = (state, action) => {
  return {
    ...state,
    selectedCustomisedItems: {},
    selectedDependencies: []
  };
};

const actions = {
  [SET_SELECTED_CUSTOMISED_ITEMS]: setSelectedCustomisedItems,
  [SET_SELECTED_DEPENDENCIES]: setSelectedDependencies,
  [CLEAR_CUSTOMISATIONS]: clearCustomisations
};

const manager = new StateManager(initialState, actions);

export default manager.reducer;
