import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';
import UiView from './View';
import FeedbackOverlay from './feedbackOverlay';

export default class UiIcon extends Component {
  render() {
    const {
      color,
      icon,
      fontSize,
      onClick,
      children,
      style,
      feedback
    } = this.props;
    const updatedStyle = {};
    if (feedback) {
      updatedStyle.padding = '6px';
    }

    return (
      <UiView onClick={onClick} style={{ ...style, ...updatedStyle }}>
        <Icon className={icon} style={{ fontSize: fontSize, color: color }}>
          {children}
        </Icon>
        {feedback && <FeedbackOverlay onClick={onClick} />}
      </UiView>
    );
  }
}

UiIcon.defaultProps = {
  onClick: () => {}
};
