import React, { Component } from 'react';

import { UiView, UiRow, UiColumn } from './../../dependencies/zomato-ui';

import { UiCheckbox } from '../../dependencies/zomato-ui/input';

export default class CustomisationsItem extends Component {
  componentDidMount() {
    const { groupId, item, checked } = this.props;

    if (checked) {
      this.props.onModifierItemChange({
        checked: checked,
        groupId,
        itemId: item.item_id
      });
    }
  }

  onChange(event) {
    const { groupId, item } = this.props;
    this.props.onModifierItemChange({
      checked: event.target.checked,
      groupId,
      itemId: item.item_id
    });
  }

  isDisabled() {
    const { selectedCustomisedItems, groupMax, groupId, item } = this.props;
    const selectedGroupItems = selectedCustomisedItems[groupId];

    return (
      selectedGroupItems &&
      selectedGroupItems.length === groupMax &&
      selectedGroupItems.indexOf(item.item_id) === -1
    );
  }

  render() {
    const { groupItem, checked } = this.props;
    return (
      <UiView>
        <UiRow>
          <UiColumn doublePadded>
            <UiCheckbox
              checked={checked}
              isDisabled={this.isDisabled()}
              type="checkbox"
              onChange={this.onChange.bind(this)}
              title={groupItem.name}
              label={groupItem.price ? `₹${groupItem.price}` : null}
            />
          </UiColumn>
        </UiRow>
      </UiView>
    );
  }
}
