import FKPlatform from 'fk-platform-sdk/web';

import { generateApiUrl } from '../../../api/helpers';
import {
  PERMISSION_LOCATION,
  PERMISSION_MOBILE,
  PERMISSION_NAME
} from '../../../store/user/actions';

const permissions = {
  name: { scope: 'user.name', isMandatory: true, shouldVerify: false },
  location: { scope: 'user.location', isMandatory: false, shouldVerify: false },
  mobile: { scope: 'user.mobile', isMandatory: true, shouldVerify: true },
  email: { scope: 'user.email', isMandatory: true, shouldVerify: false }
};

window.FlipkartPlatform = null;
if (FKPlatform.isPlatformAvailable()) {
  window.FlipkartPlatform = new FKPlatform('zomato');
}

export default class Flipkart {
  static CLIENT_NAME = 'FLIPKART';
  static CLIENT_ID = 'ordering_sdk_fk_pwa_v1';
  static PAYMENT_METHOD_TYPE = '';
  static ZOMATO_ORDERING_API_KEY = 'f55a0ef8a9b721bf726285f81090e900';
  static ZOMATO_ORDERING_SECRET_KEY = '1e857e2cce68503fafd0643487b01d0a';

  constructor() {
    this.CLIENT_NAME = Flipkart.CLIENT_NAME;
  }

  isPlatformAvailable() {
    return window.FlipkartPlatform;
  }

  requestIdentity() {
    const scopes = [];

    return this.getFKGrantToken(scopes);
  }

  requestCurrentLocation() {
    const scopes = [PERMISSION_LOCATION];

    return this.getFKGrantToken(scopes).then(response => {
      if (!response) {
        throw new Error('Invalid App');
      }

      if (!response.grantToken || !response.grantToken.length) {
        throw new Error('Invalid token');
      }

      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(position => {
          resolve(position.coords);
        });
      });
    });
  }

  getPaymentMethodType() {
    return Flipkart.PAYMENT_METHOD_TYPE;
  }

  requestPayment(response) {
    const url = {
      path: 'o2-third-party-order-payment.php',
      queryParams: {
        uuid: response.uuid,
        order_id_hash: response.response.hash_key
      }
    };
    window.location = `https://zomato.com/${generateApiUrl(url)}`;

    return Promise.resolve({});
  }

  requestUserDetails() {
    const scopes = [PERMISSION_MOBILE, PERMISSION_NAME];

    return this.getFKGrantToken(scopes);
  }

  getFKGrantToken(scopes) {
    if (!window.FlipkartPlatform) {
      return Promise.resolve();
    }

    return window.FlipkartPlatform.getModuleHelper()
      .getPermissionsModule()
      .getToken(scopes.map(scope => permissions[scope]))
      .then(response => {
        return {
          grantToken: response.grantToken,
          scopes: scopes.map(scope => permissions[scope].scope)
        };
      });
  }
}
