import React, { Component } from 'react';
import { colors } from './util/Theme';

import { UiText, UiRow, UiColumn, UiView } from './index';

const styles = {
  stepper: {
    borderWidth: 1,
    borderRadius: 4,
    borderStyle: 'solid',
    width: 75,
    lineHeight: '25px'
  },
  disabledStepper: {
    borderColor: '#999999',
    backgroundColor: '#999999'
  },
  enabledStepper: {
    borderColor: colors['counter']['primary'],
    backgroundColor: colors['counter']['primary']
  },
  addMenuButton: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  addButton: {
    backgroundColor: '#fff',
    borderRadius: 4
  },
  stepperAdd: {
    backgroundColor: '#fff',
    textAlign: 'center'
  },
  stepperIconSection: {
    padding: 0,
    height: 25,
    alignItems: 'center',
    justifyContent: 'center'
  },
  stepperIcon: {
    backgroundColor: colors['counter']['primary']
  },
  stepperAddIcon: {
    backgroundColor: colors['counter']['secondary']
  },

  stepperDisabledAddIcon: {
    backgroundColor: '#ccc'
  },
  stepperAddRadius: {
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4
  },
  stepperMinus: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4
  },
  stepperInput: {
    borderWidth: 0,
    textAlign: 'center',
    alignItems: 'center',
    color: colors['counter']['primary'],
    fontSize: 25,
    lineHeight: 26
  }
};

export default class UiCounter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
  }

  onAddClicked() {
    this.props.onAdd();
    this.plusClicked();
  }

  onMinusClicked() {
    this.props.onMinus();
    this.minusClicked();
  }

  minusClicked() {
    const { isControlled, step, min } = this.props;
    const { value } = this.state;
    let resultValue = value;
    if (isControlled) {
      return;
    }

    const nowNum = value - parseInt(step, 10);
    if (value > min) {
      resultValue = nowNum;
    }

    if (nowNum < min) {
      resultValue = parseInt(min, 10);
    }
    this.setState({ value: resultValue });
  }

  plusClicked() {
    const { isControlled, step, max } = this.props;
    const { value } = this.state;
    let resultValue = value;
    if (isControlled) {
      return;
    }

    const nowNum = value + parseInt(step, 10);
    if (value < max) {
      resultValue = nowNum;
    }

    if (nowNum > max) {
      resultValue = parseInt(max, 10);
    }
    this.setState({ value: resultValue });
  }

  render() {
    const { isDisabled, value } = this.props;
    const stepperStyle = isDisabled
      ? styles.disabledStepper
      : styles.enabledStepper;
    const stepperIconStyle = isDisabled
      ? styles.stepperDisabledAddIcon
      : styles.stepperAddIcon;

    return (
      <UiView style={{ ...styles.stepper, ...stepperStyle }}>
        {value > 0 ? (
          <UiView style={styles.addMenuButton}>
            <UiRow>
              <UiColumn nonPadded>
                <UiView
                  style={styles.stepperMinus}
                  onClick={this.onMinusClicked.bind(this)}
                >
                  <UiText color="#fff" size="big" align="center">
                    -
                  </UiText>
                </UiView>
              </UiColumn>
              <UiColumn nonPadded>
                <UiView
                  style={{
                    ...styles.stepperAdd,
                    height: 25,
                    lineHeight: '23px'
                  }}
                  onClick={this.onAddClicked.bind(this)}
                >
                  <UiText
                    style={{ ...styles.stepperInput }}
                    color={colors['counter']['primary']}
                    align="center"
                  >
                    {value}
                  </UiText>
                </UiView>
              </UiColumn>
              <UiColumn nonPadded>
                <UiView
                  style={{
                    ...styles.stepperIconSection,
                    ...styles.stepperIcon
                  }}
                  onClick={this.onAddClicked.bind(this)}
                >
                  <UiText color="#fff" size="big" align="center">
                    +
                  </UiText>
                </UiView>
              </UiColumn>
            </UiRow>
          </UiView>
        ) : (
          <UiView
            style={{ ...styles.addMenuButton, ...styles.addButton }}
            onClick={this.onAddClicked.bind(this)}
          >
            <UiRow>
              <UiColumn>
                <UiView style={styles.stepperAdd}>
                  <UiText
                    color={
                      isDisabled ? '#999999' : colors['counter']['primary']
                    }
                    align="center"
                  >
                    ADD
                  </UiText>
                </UiView>
              </UiColumn>
              <UiColumn nonPadded>
                <UiView
                  style={{
                    ...styles.stepperIconSection,
                    ...styles.stepperAddRadius,
                    ...stepperIconStyle
                  }}
                >
                  <UiText
                    color={
                      isDisabled ? '#999999' : colors['counter']['primary']
                    }
                    size="big"
                    align="center"
                  >
                    +
                  </UiText>
                </UiView>
              </UiColumn>
            </UiRow>
          </UiView>
        )}
      </UiView>
    );
  }
}
