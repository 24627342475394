const themes = {
  flipkart: {
    colors: {
      background: {
        primary: '#00B9F5',
        secondary: '#002E6E',
        tertiary: 'rgb(33,193,122)',
        inverted: '#fff',
        gray: '#bcbcbc',
        grayishOrange: '#f3eee0'
      },
      counter: {
        primary: '#099e44',
        secondary: '#ecf6ef'
      },
      boxShadow: {
        primary: '0 1px 1px 0 rgba(0,0,0,.12)'
      },
      button: {
        primary: '#099e44',
        secodary: '#000',
        tertiary: '#000',
        disabled: 'rgb(219,219,219,1)',
        inverted: '#ffffff'
      },
      overlayBackground: {
        primary: 'rgba(100,100,100,0.5)',
        inverted: 'rgb(249, 249, 249)'
      },
      text: {
        primary: '#000000',
        secondary: '#999999',
        tertiary: 'rgb(33,193,122)',
        inverted: '#FFF',
        highlighted: '#00B9F5',
        error: '#E57373'
      },
      heading: {
        primary: '#000000',
        secondary: '#999999',
        tertiary: 'rgb(33,193,122)',
        inverted: '#FFF',
        highlighted: '#00B9F5',
        error: '#E57373'
      },
      anchor: {
        primary: '#e23644',
        secondary: '#002E6E',
        tertiary: '#E57373',
        inverted: '#FFF',
        success: 'rgb(33,193,122)'
      },
      icon: {
        primary: '#cb1f2c',
        secondary: '#099e44',
        tertiary: '#E57373',
        quaternary: 'rgb(33,193,122)',
        inverted: '#FFF',
        blur: '#ccc'
      },
      radio: {
        primary: '#099e44'
      },
      checkbox: {
        primary: '#099e44'
      },
      switch: {
        primary: '#009537',
        secondary: '#fbbe08'
      },
      section: {
        primary: 'rgba(0,46,110,0.02)',
        secondary: '#F5F7F9'
      },
      highlight: {
        primary: 'rgb(235,250,255)',
        secondary: 'rgb(253,251,211)'
      },
      border: {
        primary: '#EBEBEB',
        secondary: '#f5f5f5',
        error: '#E57373'
      },
      loader: {
        primary: '#00B9F5',
        secondary: 'rgb(160,160,160)',
        inverted: '#fff'
      },
      response: {
        success: 'rgb(33,193,122)',
        failure: 'rgb(253,92,92)',
        Pending: 'rgb(255,164,0)'
      },
      chip: {
        primary: '#099e44'
      }
    },
    fontSizes: {
      tiny: 9,
      mini: 11,
      small: 13,
      medium: 15,
      big: 17,
      large: 19,
      huge: 20,
      massive: 22,
      tinyCart: 7,
      miniCart: 9,
      smallCart: 11,
      mediumCart: 13,
      bigCart: 15,
      largeCart: 17,
      hugeCart: 18,
      massiveCart: 20
    },
    units: {
      quarter: 3,
      half: 6,
      full: 12
    }
  },
  gPay: {
    colors: {
      background: {
        primary: '#00B9F5',
        secondary: '#002E6E',
        tertiary: 'rgb(33,193,122)',
        inverted: '#fff',
        gray: '#bcbcbc',
        grayishOrange: '#f3eee0'
      },
      counter: {
        primary: '#099e44',
        secondary: '#ecf6ef'
      },
      boxShadow: {
        primary: '0 1px 1px 0 rgba(0,0,0,.12)'
      },
      button: {
        primary: '#099e44',
        secodary: '#000',
        tertiary: '#000',
        disabled: 'rgb(219,219,219,1)',
        inverted: '#ffffff'
      },
      overlayBackground: {
        primary: 'rgba(100,100,100,0.5)',
        inverted: 'rgb(249, 249, 249)'
      },
      text: {
        primary: '#000000',
        secondary: '#999999',
        tertiary: 'rgb(33,193,122)',
        inverted: '#FFF',
        highlighted: '#00B9F5',
        error: '#E57373'
      },
      heading: {
        primary: '#000000',
        secondary: '#999999',
        tertiary: 'rgb(33,193,122)',
        inverted: '#FFF',
        highlighted: '#00B9F5',
        error: '#E57373'
      },
      anchor: {
        primary: '#e23644',
        secondary: '#002E6E',
        tertiary: '#E57373',
        inverted: '#FFF',
        success: 'rgb(33,193,122)'
      },
      icon: {
        primary: '#cb1f2c',
        secondary: '#099e44',
        tertiary: '#E57373',
        quaternary: 'rgb(33,193,122)',
        inverted: '#FFF',
        blur: '#ccc'
      },
      radio: {
        primary: '#099e44'
      },
      checkbox: {
        primary: '#099e44'
      },
      switch: {
        primary: '#009537',
        secondary: '#fbbe08'
      },
      section: {
        primary: 'rgba(0,46,110,0.02)',
        secondary: '#F5F7F9'
      },
      highlight: {
        primary: 'rgb(235,250,255)',
        secondary: 'rgb(253,251,211)'
      },
      border: {
        primary: '#EBEBEB',
        secondary: '#f5f5f5',
        error: '#E57373'
      },
      loader: {
        primary: '#00B9F5',
        secondary: 'rgb(160,160,160)',
        inverted: '#fff'
      },
      response: {
        success: 'rgb(33,193,122)',
        failure: 'rgb(253,92,92)',
        Pending: 'rgb(255,164,0)'
      },
      chip: {
        primary: '#099e44'
      }
    },
    fontSizes: {
      tiny: 9,
      mini: 11,
      small: 13,
      medium: 15,
      big: 17,
      large: 19,
      huge: 20,
      massive: 22,
      tinyCart: 7,
      miniCart: 9,
      smallCart: 11,
      mediumCart: 13,
      bigCart: 15,
      largeCart: 17,
      hugeCart: 18,
      massiveCart: 20
    },
    units: {
      quarter: 3,
      half: 6,
      full: 12
    }
  }
};

const { colors, fontSizes, units } = themes['gPay'];

export { colors, fontSizes, units };
