import { StateManager } from '../../util';
import {
  SET_RESPONSE,
  SET_ORDER_HISTORY,
  SET_ORDER_RESPONSE,
  SET_RESTAURANT_DETAILS,
  SET_ORDER_DETAILS
} from './actions';

export const initialState = {
  summary: {
    resId: 0,
    orderId: 0,
    userPhone: '',
    address: '',
    deliverySubzoneName: '',
    orderSummaryString: '',
    paymentText: '',
    addedOn: '',
    orderItems: [],
    restaurant: {}
  },
  history: [],
  crystal: {
    statusImage: '',
    orderStatus: 0,
    orderStatusString: '',
    orderDetails: {},
    orderStatusDetails: {},
    riderDetails: {},
    supportDetails: {}
  }
};

const setResponse = (state, action) => {
  const { response } = action;
  return {
    ...state,
    summary: {
      ...state.summary,
      resId: response.res_id,
      orderId: response.tab_id,
      userPhone: response.phone,
      address: response.address,
      deliverySubzoneName: response.delivery_subzone_name,
      paymentText: response.payment_text,
      addedOn: response.created_at_str,
      orderSummaryString: response.order_summary_string
    }
  };
};

const setOrderResponse = (state, action) => {
  const { orderItems } = action;
  return {
    ...state,
    summary: {
      ...state.summary,
      orderItems: [...orderItems]
    }
  };
};

const setRestaurantDetails = (state, action) => {
  const { restaurant } = action;
  return {
    ...state,
    summary: {
      ...state.summary,
      restaurant: { ...restaurant }
    }
  };
};

const setOrderHistory = (state, action) => {
  const { response } = action;
  return {
    ...state,
    history: [...response]
  };
};

const setOrderDetails = (state, action) => {
  const { response } = action;
  return {
    ...state,
    crystal: {
      ...state.crystal,
      orderStatus: response.status,
      orderStatusString: response.order_status,
      statusImage: response.status_image,
      orderDetails: { ...response.order_details },
      orderStatusDetails: { ...response.order_status_details },
      riderDetails: { ...response.delivery_details },
      supportDetails: { ...response.chat_support_details }
    }
  };
};

const actions = {
  [SET_RESPONSE]: setResponse,
  [SET_ORDER_HISTORY]: setOrderHistory,
  [SET_ORDER_RESPONSE]: setOrderResponse,
  [SET_RESTAURANT_DETAILS]: setRestaurantDetails,
  [SET_ORDER_DETAILS]: setOrderDetails
};

const manager = new StateManager(initialState, actions);

export default manager.reducer;
