import React, { Component } from 'react';

import { UiView, UiPopup } from '../zomato-ui';

import { Filters, SortList } from '../../containers/home/search';

import {
  MenuItemSearch,
  ChooseCustomisationsPopup,
  ChangeCustomisedItemQuantityPopup
} from '../../containers/restaurant';

import { RestaurantChainOutlets } from '../../containers/home/restaurants';

export default function WithPopup(WrappedComponent) {
  class WithPopup extends Component {
    state = {
      isPopupVisible: false,
      showRightIcon: true,
      showBackButton: true,
      showPopupHeader: true,
      showHeaderTitle: true,
      anchor: 'bottom'
    };

    onBackClick() {
      this.props.history.goBack();
    }

    reset() {
      const path = this.props.match.path;

      switch (path) {
        case '/filters':
          this.props.resetFilters();
          this.onBackClick();
          break;
        case '/sort':
          this.props.resetSorting();
          this.onBackClick();
          break;
        default:
          return;
      }
    }

    renderPopupItem() {
      const path = this.props.match.path;

      switch (path) {
        case '/filters':
          return <Filters hidePopup={this.onBackClick.bind(this)} />;
        case '/sort':
          return <SortList hidePopup={this.onBackClick.bind(this)} />;
        case '/chooose-customisations/:resId/:itemId':
          return (
            <ChooseCustomisationsPopup resId={this.props.match.params.resId} />
          );
        case '/restaurant-chain-outlets/:resId':
          return (
            <RestaurantChainOutlets resId={this.props.match.params.resId} />
          );
        case '/change-customised-item-quantity/:resId/:itemId':
          return (
            <ChangeCustomisedItemQuantityPopup
              resId={this.props.match.params.resId}
            />
          );
        case '/restaurant-search/:resId':
          return <MenuItemSearch resId={this.props.match.params.resId} />;
        default:
          return;
      }
    }

    renderPopupTitle() {
      const path = this.props.match.path;

      switch (path) {
        case '/filters':
          return 'Filters';
        case '/sort':
          return 'Sort by';
        case '/restaurant-search/:resId':
          return 'Search';
        default:
          return '';
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.match.path !== prevProps.match.path) {
        const path = this.props.match.path;
        switch (path) {
          case '/filters':
            this.setState({
              isPopupVisible: true
            });
            break;

          case '/sort':
            this.setState({
              isPopupVisible: true
            });
            break;

          case '/chooose-customisations/:resId/:itemId':
            this.setState({
              isPopupVisible: true,
              showPopupHeader: false
            });
            break;
          case '/restaurant-chain-outlets/:resId':
            this.setState({
              isPopupVisible: true,
              showPopupHeader: false
            });
            break;
          case '/change-customised-item-quantity/:resId/:itemId':
            this.setState({
              isPopupVisible: true,
              showPopupHeader: true,
              showHeaderTitle: false,
              showRightIcon: false,
              anchor: 'bottom'
            });
            break;
          case '/restaurant-search/:resId':
            this.setState({
              isPopupVisible: true,
              showRightIcon: false,
              anchor: 'top'
            });
            break;
          default:
            this.setState({
              isPopupVisible: false,
              showPopupHeader: true
            });
            return;
        }
      }
    }

    render() {
      const {
        showRightIcon,
        showBackButton,
        showPopupHeader,
        showHeaderTitle,
        anchor
      } = this.state;
      return (
        <UiView>
          <WrappedComponent />
          <UiPopup
            reset={this.reset.bind(this)}
            onBack={this.onBackClick.bind(this)}
            showRightIcon={showRightIcon}
            showBackButton={showBackButton}
            showPopupHeader={showPopupHeader}
            showHeaderTitle={showHeaderTitle}
            title={this.renderPopupTitle()}
            anchor={anchor}
            open={this.state.isPopupVisible}
            onClose={this.onBackClick.bind(this)}
            renderItem={this.renderPopupItem()}
          />
        </UiView>
      );
    }
  }
  return WithPopup;
}
