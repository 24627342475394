import React, { Component } from 'react';
import {
  UiRow,
  UiColumn,
  UiHeading,
  UiText,
  UiCell,
  UiImage,
  UiView
} from '../../dependencies/zomato-ui';

export default class OrderItem extends Component {
  render() {
    const { orderItem } = this.props;
    return (
      <UiCell
        marginTop={!['dish'].includes(orderItem.type)}
        padded={['total_user', 'dish'].includes(orderItem.type)}
        paddedBorder
        borderTop={['total_user'].includes(orderItem.type) ? 'default' : 'none'}
        borderBottom={
          ['total_user', 'dish'].includes(orderItem.type) ? 'default' : 'none'
        }
      >
        <UiRow>
          {orderItem.item_tag_image ? (
            <UiView style={{ marginRight: 6 }}>
              <UiImage src={orderItem.item_tag_image} width={14} height={14} />
            </UiView>
          ) : null}

          <UiColumn nonPadded>
            <UiRow>
              <UiColumn flex={5} nonPadded>
                <UiHeading>{orderItem.item_name}</UiHeading>
              </UiColumn>
              {orderItem.type !== 'dish' && (
                <UiColumn nonPadded>
                  <UiText align="right">{orderItem.display_cost}</UiText>
                </UiColumn>
              )}
            </UiRow>
            {orderItem.type === 'dish' && (
              <UiRow marginTop>
                <UiColumn nonPadded flex={4}>
                  <UiText>
                    {orderItem.quantity + ' x ' + orderItem.unit_cost}
                  </UiText>
                </UiColumn>
                <UiColumn nonPadded>
                  <UiRow>
                    <UiColumn nonPadded>
                      <UiText align="right">{orderItem.display_cost}</UiText>
                    </UiColumn>
                  </UiRow>
                </UiColumn>
              </UiRow>
            )}
            {orderItem.choose_text && (
              <UiRow>
                <UiColumn nonPadded>
                  <UiText size="small" color="secondary">
                    {orderItem.choose_text}
                  </UiText>
                </UiColumn>
              </UiRow>
            )}
          </UiColumn>
        </UiRow>
      </UiCell>
    );
  }
}
