import { Fetch } from './helpers';

export default {
  getData(data) {
    if (!data.dishes || !data.dishes.length) {
      return Promise.reject('Required field');
    }
    const path = 'order/cart';
    const body = {
      order: JSON.stringify({
        dishes: data.dishes
      }),
      res_id: data.resId,
      address_id: data.addressId,
      phone: data.phone,
      name: data.name,
      email: data.email,
      voucher_code: data.promocode
    };

    return Fetch.post({ path }, { body: JSON.stringify(body) });
  },
  checkout(data) {
    if (!data.dishes || !data.dishes.length) {
      return Promise.reject('Required field');
    }
    const path = 'order/checkout';
    const {
      resId,
      addressId,
      paymentMethodType,
      paymentMethodId,
      phone,
      name,
      email,
      promocode
    } = data;

    const body = {
      order: JSON.stringify({
        dishes: data.dishes
      }),
      res_id: resId,
      address_id: addressId,
      payment_method_type: paymentMethodType,
      payment_method_id: paymentMethodId,
      phone,
      name,
      email,
      voucher_code: promocode
    };
    return Fetch.post({ path }, { body: JSON.stringify(body) });
  }
};
