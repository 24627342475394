import React, { Component } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';

class UiSnackbar extends Component {
  state = {
    vertical: 'bottom',
    horizontal: 'center'
  };

  render() {
    const { message, open, classes } = this.props;
    const { vertical, horizontal } = this.state;
    return (
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
        open={open}
        onClose={this.props.handleClose}
        autoHideDuration={1500}
      >
        <SnackbarContent
          classes={{
            root: classes.root
          }}
          message={message}
        />
      </Snackbar>
    );
  }
}

const styles = {
  root: {
    height: 45
  }
};

export default withStyles(styles)(UiSnackbar);
