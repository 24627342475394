import React, { Component } from 'react';
import {
  UiView,
  UiRow,
  UiColumn,
  UiHeading,
  UiSwitch,
  UiCell
} from '../../dependencies/zomato-ui';

export default class MenuFilters extends Component {
  state = {
    showContainsEgg: false
  };

  onChange(event) {
    let { vegOnly, containsEgg } = this.props;
    if (event.target.value === 'Veg Only') {
      vegOnly = event.target.checked;
    } else if (event.target.value === 'Contains Egg') {
      containsEgg = event.target.checked;
    }
    const params = {
      vegOnly,
      containsEgg
    };
    this.props.setRestaurantFilteredData(params);
  }

  render() {
    const { restaurantInfo, vegOnly, containsEgg } = this.props;
    const { showContainsEgg } = this.state;

    return (
      <UiView>
        {restaurantInfo.is_pure_veg === 1 ? (
          <UiRow>
            <UiColumn>
              <UiCell margin padded>
                <UiHeading color="tertiary">PURE VEG.</UiHeading>
              </UiCell>
            </UiColumn>
          </UiRow>
        ) : (
          <UiRow>
            <UiColumn>
              <UiSwitch
                label="Veg Only"
                value="Veg Only"
                type="primary"
                isChecked={vegOnly}
                onChange={this.onChange.bind(this)}
              />
              {(showContainsEgg || vegOnly) && (
                <UiSwitch
                  class="contains_egg"
                  style={{ width: vegOnly ? '260px' : '1px' }}
                  isChecked={containsEgg}
                  label="Contains Egg"
                  value="Contains Egg"
                  type="secondary"
                  onChange={this.onChange.bind(this)}
                />
              )}
            </UiColumn>
          </UiRow>
        )}
      </UiView>
    );
  }
}
