import React, { Component } from 'react';

import { UiView, UiImage } from './../../dependencies/zomato-ui';

export default class HeaderImage extends Component {
  render() {
    const { showHeaderTitle } = this.props;
    return (
      <UiView>
        <UiView style={styles.banner}>
          <UiImage src={this.props.src} width={'100%'} height={200} />
          <UiView style={styles.bannerOverlay} />
        </UiView>
        {showHeaderTitle && (
          <UiView style={styles.headerImageSticky}>
            <UiImage
              style={{ opacity: showHeaderTitle ? 1 : 0 }}
              src={this.props.src}
              width={'100%'}
              height={200}
            />
            <UiView style={styles.bannerOverlay} />
          </UiView>
        )}
      </UiView>
    );
  }
}

const styles = {
  banner: {
    position: 'relative',
    height: 200,
    flexDirection: 'row'
  },
  bannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 200,
    flexDirection: 'row',
    backgroundImage:
      'linear-gradient(-270deg,rgba(24,24,24,0.2) 0%,rgba(60,60,60,0.2) 100%)'
  },
  headerImageSticky: {
    height: 200,
    position: 'fixed',
    top: -140,
    left: 0,
    zIndex: 1,
    width: '100%'
  }
};
