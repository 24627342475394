import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import CartDetail from '../../components/cart/CartDetail';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { resId, cartResponse, items, cartItems } = state.cartState;
      let cartItemQuantity = 0;

      Object.values(items).forEach(item => {
        item.forEach(cartItemId => {
          cartItemQuantity += cartItems[cartItemId].quantity;
        });
      });

      return { resId, cartResponse, items, cartItemQuantity };
    },
    {}
  )(CartDetail)
);
