import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { RestaurantDetails } from '../../components/crystal';

export default withRouter(
  connect(state => {
    const { crystal } = state.orderState;
    const { orderDetails } = crystal;
    const title = orderDetails.title;
    const buttonTitle = orderDetails.button && orderDetails.button.title;
    return { title, buttonTitle };
  })(RestaurantDetails)
);
