import { Fetch } from './helpers';

export default {
  getSummary(params) {
    const queryParams = {
      tab_id: params.tabId
    };

    const path = 'order/order-summary';

    return Fetch.get({ path, queryParams });
  },

  getHistory() {
    const queryParams = {
      type: 'ONLINE_ORDER',
      filter: 'all'
    };

    const path = 'order/order-history';
    return Fetch.get({ path, queryParams });
  },

  getDetails(params) {
    const queryParams = {
      tab_id: params.tabId
    };

    const path = 'order/crystal';

    return Fetch.get({ path, queryParams });
  },
  completePayment(params) {
    if (!params.order_id || !params.flow_type) {
      return Promise.reject('Required field');
    }

    const path = 'order/complete-payment';
    const { order_id, data, flow_type } = params;

    const body = {
      order_id: order_id,
      data: data,
      flow_type: flow_type
    };
    return Fetch.post({ path }, { body: JSON.stringify(body) });
  },
  verifyPaymentStatus(params) {
    if (!params.order_id || !params.retry_count) {
      return Promise.reject('Required field');
    }

    const path = 'order/verify-payment-status';
    const { order_id, retry_count } = params;

    const body = {
      order_id: order_id,
      retry_count: retry_count
    };
    return Fetch.post({ path }, { body: JSON.stringify(body) });
  }
};
