import React, { Component } from 'react';

import { UiView, UiText } from '../../dependencies/zomato-ui';

export default class CartItemCustomisations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGroups: []
    };
  }

  componentDidMount() {
    this.getCustomisedItem();
  }

  getCustomisedItem() {
    const { cartItems, groups, groupItems, cartItemId } = this.props;
    const cartItem = cartItems[cartItemId];
    const customisedGroupIds = Object.keys(cartItem.customisations);

    const selectedGroups = [];
    customisedGroupIds.forEach(customisedGroupId => {
      const selectedGroup = groups[customisedGroupId];
      let groupString = `${selectedGroup.name}: `;
      const selectedGroupItemIds = cartItem.customisations[customisedGroupId];
      const selectedGroupItems = [];

      selectedGroupItemIds.forEach(selectedGroupItemId => {
        const groupItem = groupItems[selectedGroupItemId];
        selectedGroupItems.push(groupItem.name);
      });

      groupString += selectedGroupItems.join(', ');
      selectedGroups.push({
        groupId: selectedGroup.group_id,
        groupString
      });
    });

    this.setState({
      selectedGroups: selectedGroups
    });
  }

  render() {
    if (!this.state.selectedGroups) {
      return null;
    }

    return (
      <UiView>
        {this.state.selectedGroups.map(selectedGroup => (
          <UiView key={selectedGroup.groupId}>
            <UiText size="small" color="secondary">
              {selectedGroup.groupString}
            </UiText>
          </UiView>
        ))}
      </UiView>
    );
  }
}
