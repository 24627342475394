import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import CustomisationsItem from '../../components/restaurant/CustomisationsItem';

import { onModifierItemChange } from '../../store/customisations/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { selectedCustomisedItems } = state.customisationsState;
      const item = ownProps.groupItem;
      const groupId = ownProps.groupId;
      return { item, groupId, selectedCustomisedItems };
    },
    { onModifierItemChange }
  )(CustomisationsItem)
);
