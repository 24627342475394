import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { SupportDetails } from '../../components/crystal';

export default withRouter(
  connect(state => {
    const { crystal } = state.orderState;
    const { supportDetails } = crystal;
    const { title, subtitle, image } = supportDetails;
    return { title, subtitle, image };
  })(SupportDetails)
);
