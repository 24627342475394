import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { colors } from './util/Theme';
import { styled } from '@material-ui/styles';
import { UiView, UiLoader } from '.';

export default class UiButton extends Component {
  render(props) {
    const {
      title,
      fluid,
      onPress,
      gradient,
      variant,
      type,
      height,
      loading,
      nonPadded
    } = this.props;
    const CustomButton = styled(Button)({
      borderColor: `${colors['button'][type]} !important`,
      color: `${
        variant === 'contained'
          ? colors['button']['inverted']
          : colors['button'][type]
      } !important`,
      backgroundColor: `${
        variant === 'contained'
          ? colors['button'][type]
          : colors['button']['inverted']
      } !important`,
      width: ` ${fluid === 'true' ? '100%' : 'auto'} !important`,
      height: height,
      padding: nonPadded && 0
    });
    return (
      <UiView style={styles.container} onClick={onPress}>
        <CustomButton variant={variant}>{!loading && title}</CustomButton>
        {gradient && <UiView style={styles.gradientOverlay} />}
        {loading && (
          <UiView style={styles.overlay}>
            <UiLoader size={24} color="#fff" />
          </UiView>
        )}
      </UiView>
    );
  }
}

UiButton.defaultProps = {
  onPress: () => {},
  title: 'Title',
  disabled: false,
  fluid: 'false',
  color: 'primary',
  size: 'full',
  height: 45,
  type: 'hollow'
};

const styles = {
  container: {
    position: 'relative',
    width: '100%'
  },
  gradientOverlay: {
    position: 'absolute',
    justifyContent: 'center',
    flex: 1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundImage:
      'linear-gradient(to right,rgba(255, 255, 255, 0.5),rgba(255, 255, 255,0))'
  },
  overlay: {
    position: 'absolute',
    flex: 1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};
