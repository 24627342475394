import React, { Component } from 'react';

import { UiView, UiLoader } from '.';

export default class UiActivityIndicator extends Component {
  render() {
    const { show } = this.props;
    if (!show) {
      return null;
    }
    return (
      <UiView
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '60px'
        }}
      >
        <UiLoader size="huge" />
      </UiView>
    );
  }
}
