import React, { Component } from 'react';

import {
  UiText,
  UiRow,
  UiColumn,
  UiHeading,
  UiImage,
  UiCell,
  UiView
} from './../../dependencies/zomato-ui';

import CartItemCounter from '../../containers/cart/CartItemCounterContainer';
import CartItemCustomisations from '../../containers/cart/CartItemCustomisationsContainer';

export default class CartItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: {},
      cartItem: {},
      totalPrice: 0,
      shouldWatchCart: true
    };
  }

  itemOriginalUnitPrice() {
    const { restaurantInfo, cartItem } = this.props;
    const { currency } = restaurantInfo;
    const { totalItemPrice } = cartItem;
    const unitDisplayPrice = this.props.getDiscountedAmount(totalItemPrice);
    if (
      !this.props.isOfferApplicable() ||
      totalItemPrice === unitDisplayPrice
    ) {
      return '';
    }
    return this.props.getAmountStringWithCurrency(
      currency,
      totalItemPrice,
      restaurantInfo.currency_affix
    );
  }

  itemDisplayUnitPrice() {
    const { restaurantInfo, cartItem } = this.props;
    const { currency } = restaurantInfo;

    const unitDisplayPrice = this.props.getDiscountedAmount(
      cartItem.totalItemPrice
    );
    return this.props.getAmountStringWithCurrency(
      currency,
      unitDisplayPrice.toFixed(2),
      restaurantInfo.currency_affix
    );
  }

  itemDisplayTotalPrice() {
    const { restaurantInfo, cartItem } = this.props;
    const totalPrice = cartItem.quantity * cartItem.totalItemPrice;
    const { currency } = restaurantInfo;
    const totalDisplayPrice = this.props.getDiscountedAmount(totalPrice);
    return this.props.getAmountStringWithCurrency(
      currency,
      totalDisplayPrice.toFixed(2),
      restaurantInfo.currency_affix
    );
  }

  render() {
    const {
      restaurantMenuItem,
      cartItem,
      resId,
      itemId,
      cartItemId
    } = this.props;

    if (!restaurantMenuItem.item_id || !cartItem || !cartItem.quantity) {
      return null;
    }

    return (
      <UiCell margin>
        <UiRow margin>
          <UiImage
            src={restaurantMenuItem.item_tag_image}
            width={17}
            height={17}
          />
          <UiColumn nonPadded>
            <UiRow>
              <UiColumn flex={6}>
                <UiRow>
                  <UiColumn nonPadded>
                    <UiHeading>{restaurantMenuItem.name}</UiHeading>
                  </UiColumn>
                </UiRow>
                <UiRow marginTop>
                  {this.props.isOfferApplicable() &&
                    this.itemOriginalUnitPrice() && (
                      <UiText>{this.itemDisplayUnitPrice()}</UiText>
                    )}
                </UiRow>
              </UiColumn>
              <UiColumn flex={2} nonPadded>
                <UiView style={{ float: 'right' }}>
                  <CartItemCounter
                    resId={resId}
                    itemId={itemId}
                    cartItemId={cartItemId}
                    groups={restaurantMenuItem.groups}
                    cartItemQuantity={cartItem.quantity}
                    onItemRemove={this.props.onItemRemove}
                  />
                </UiView>
              </UiColumn>
            </UiRow>
            <UiRow marginTop>
              <UiColumn flex={5}>
                <CartItemCustomisations cartItemId={cartItemId} />
              </UiColumn>
              <UiColumn nonPadded>
                <UiText align="right">{this.itemDisplayTotalPrice()}</UiText>
              </UiColumn>
            </UiRow>
          </UiColumn>
        </UiRow>
      </UiCell>
    );
  }
}
