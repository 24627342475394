import React, { Component } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';

import { units } from './util/Theme';

import { UiButton, UiColumn } from './';
import UiRow from './Row';
class UiDialog extends Component {
  render() {
    const {
      isVisible,
      confirmText,
      cancelText,
      onConfirm,
      onCancel,
      title,
      content,
      classes
    } = this.props;

    return (
      <Dialog open={isVisible} aria-labelledby="responsive-dialog-title">
        <DialogTitle className={classes.title} id="responsive-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions
          classes={{
            root: classes.root
          }}
        >
          <UiRow>
            <UiColumn>
              {cancelText && (
                <UiButton
                  title={cancelText}
                  type="primary"
                  fluid="true"
                  variant="contained"
                  nonPadded
                  onPress={onCancel}
                />
              )}
            </UiColumn>
            <UiColumn>
              <UiButton
                title={confirmText}
                type="primary"
                fluid="true"
                variant="contained"
                nonPadded
                onPress={onConfirm}
              />
            </UiColumn>
          </UiRow>
        </DialogActions>
      </Dialog>
    );
  }
}

UiDialog.defaultProps = {
  onConfirm: () => {},
  onCancel: () => {}
};

const styles = {
  root: {
    padding: units.half,
    display: 'block'
  },
  title: {
    padding: units.full
  },
  content: {
    padding: units.full,
    paddingTop: 0
  }
};

export default withStyles(styles)(UiDialog);
