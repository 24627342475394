import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { MapWebView } from './../../components/address';

export default withRouter(
  connect((state, ownProps) => {
    const { selectedLocationForAddress } = state.locationsState;
    return { selectedLocationForAddress };
  })(MapWebView)
);
