import React, { Component } from 'react';
import {
  UiView,
  UiRow,
  UiColumn,
  UiCell,
  UiHeading,
  UiText,
  UiDivider
} from '../../dependencies/zomato-ui';

export default class OrderRating extends Component {
  render() {
    return (
      <UiView style={{ marginTop: 30 }}>
        <UiRow>
          <UiColumn doublePadded>
            <UiHeading size="medium">Order Rating</UiHeading>
          </UiColumn>
        </UiRow>
        <UiRow>
          <UiColumn doublePadded>
            <UiDivider />
          </UiColumn>
        </UiRow>
        <UiRow>
          <UiColumn doublePadded>
            <UiCell padded>
              <UiText size="small">2 *</UiText>
            </UiCell>
          </UiColumn>
        </UiRow>
      </UiView>
    );
  }
}
