import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import OrderHistory from '../../components/pages/OrderHistory';

import { getOrderHistory } from '../../store/order/actions';

export default withRouter(
  connect(
    state => {
      const orderItems = state.orderState.history;
      return { orderItems };
    },
    { getOrderHistory }
  )(OrderHistory)
);
