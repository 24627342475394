import React, { Component } from 'react';

export default class UiImage extends Component {
  render() {
    const { src, width, height, radius, alt } = this.props;

    if (!src) {
      return null;
    }

    return (
      <img
        src={src}
        alt={alt}
        style={{
          ...this.props.style,
          width,
          height,
          borderRadius: radius ? radius : 0,
          objectFit: 'cover'
        }}
      />
    );
  }
}

UiImage.defaultProps = {
  width: 30,
  height: 30,
  src: null,
  radius: 0,
  alt: 'Image'
};
