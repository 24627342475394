import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import CartButton from '../../components/common/CartButton';

import {
  minOrderAlert,
  showDiscountSuggestion,
  offerSuggestion,
  isOfferApplicable,
  originalTotalAmount,
  finalTotalAmount,
  setCartCalculation,
  getCartCalculations
} from '../../store/cart/actions';

import { generateItemObject } from '../../store/restaurant/actions';

import { getUserDetails } from '../../store/user/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { restaurantInfo, offer } = state.restaurantState;
      const { items, cartItems, cartCalculations } = state.cartState;
      const cartResId = state.cartState.resId;
      return {
        items,
        cartItems,
        restaurantInfo,
        offer,
        cartCalculations,
        cartResId
      };
    },
    {
      getUserDetails,
      minOrderAlert,
      showDiscountSuggestion,
      offerSuggestion,
      isOfferApplicable,
      originalTotalAmount,
      finalTotalAmount,
      generateItemObject,
      setCartCalculation,
      getCartCalculations
    }
  )(CartButton)
);
