import React, { Component } from 'react';

import {
  UiView,
  UiText,
  UiGrid,
  UiRow,
  UiColumn,
  UiHeading,
  UiImage,
  UiLabel
} from './../../../dependencies/zomato-ui';
import { colors, units } from '../../../dependencies/zomato-ui/util/Theme';

export default class RestaurantCard extends Component {
  openRestaurant() {
    const { restaurant, type } = this.props;
    if (restaurant.chain_outlets && restaurant.chain_outlets.length) {
      this.props.history.push(
        `/restaurant-chain-outlets/${restaurant.R.res_id}`
      );
    } else if (type === 'chainOutlet') {
      this.props.history.replace(`/restaurant/${restaurant.R.res_id}`);
    } else {
      this.props.history.push(`/restaurant/${restaurant.R.res_id}`);
    }
  }

  aggregateRating() {
    const { restaurant } = this.props;
    let rating = '-';

    if (restaurant.user_rating.aggregate_rating !== '0') {
      rating = restaurant.user_rating.aggregate_rating.toString();
    }

    return rating;
  }

  hasOffer() {
    const offers = this.props.restaurant.offers;

    if (
      this.props.restaurant &&
      offers &&
      offers.length &&
      offers[0].offer.sub_text
    ) {
      return true;
    }
    return false;
  }

  offerSubText() {
    const { restaurant } = this.props;
    if (this.hasOffer()) {
      return restaurant.offers[0].offer.sub_text;
    }
    return '';
  }

  render() {
    const { restaurant, type } = this.props;
    const outletStyle = type === 'outlet' ? styles.outlet : styles.chainOutlet;

    return (
      <UiView style={{ ...styles.restaurantCardContainer, ...outletStyle }}>
        {restaurant && !restaurant.is_delivering_now && (
          <UiView style={{ paddingBottom: 15 }}>
            <UiGrid>
              <UiRow>
                <UiColumn>
                  <UiText color="error">Currently not accepting orders</UiText>
                </UiColumn>
              </UiRow>
            </UiGrid>
          </UiView>
        )}
        <UiView onClick={this.openRestaurant.bind(this)}>
          {this.props.type === 'chainOutlet' &&
            this.hasOffer() &&
            this.offerSubText() && (
              <UiRow>
                <UiColumn>
                  <UiView
                    style={{
                      ...styles.chainDiscountLabel,
                      backgroundColor: colors['button']['primary']
                    }}
                  >
                    <UiHeading size="mini" color="inverted" align="center">
                      {this.offerSubText()}
                    </UiHeading>
                  </UiView>
                </UiColumn>
              </UiRow>
            )}
          <UiRow>
            {this.props.type === 'outlet' && (
              <UiView style={styles.restaurantCardImageContainer}>
                {this.hasOffer() && this.offerSubText() && (
                  <UiView
                    style={{
                      ...styles.discountLabelHinge,
                      backgroundColor: colors['button']['primary']
                    }}
                  />
                )}
                <UiImage
                  style={styles.restaurantCardImage}
                  src={restaurant.o2_featured_image}
                  width={80}
                  height={80}
                  radius={6}
                />
                {this.hasOffer() && this.offerSubText() && (
                  <UiView
                    style={{
                      ...styles.discountLabel,
                      backgroundColor: colors['button']['primary']
                    }}
                  >
                    <UiHeading size="mini" color="inverted">
                      {this.offerSubText()}
                    </UiHeading>
                  </UiView>
                )}
              </UiView>
            )}
            <UiView
              style={
                this.props.type === 'chainOutlet'
                  ? styles.chainResCardContentContainer
                  : styles.restaurantCardContentContainer
              }
            >
              <UiRow>
                <UiView
                  style={
                    this.props.type === 'chainOutlet'
                      ? styles.chainResCardTitle
                      : styles.restaurantCardTitle
                  }
                >
                  <UiHeading size="big" ellipsis={1}>
                    {this.props.type === 'chainOutlet'
                      ? restaurant && restaurant.location.locality
                      : restaurant.name}
                  </UiHeading>
                </UiView>
                <UiLabel
                  title={restaurant && this.aggregateRating()}
                  background={
                    restaurant && '#' + restaurant.user_rating.rating_color
                  }
                  size="tiny"
                />
              </UiRow>
              {this.props.type === 'outlet' && (
                <UiRow marginTop>
                  <UiView
                    style={
                      this.props.type === 'chainOutlet'
                        ? styles.chainResCardCuisines
                        : styles.restaurantCardCuisines
                    }
                  >
                    <UiText color="secondary" ellipsis={1}>
                      {restaurant.cuisines}
                    </UiText>
                  </UiView>
                </UiRow>
              )}
              <UiRow marginTop>
                <UiColumn nonPadded>
                  <UiRow marginTop>
                    <UiColumn nonPadded={this.props.type === 'chainOutlet'}>
                      <UiText color="secondary">
                        {restaurant && restaurant.cft_display}
                      </UiText>
                    </UiColumn>
                  </UiRow>
                </UiColumn>
              </UiRow>
              <UiRow marginTop>
                <UiColumn nonPadded>
                  <UiRow marginTop>
                    <UiColumn nonPadded={this.props.type === 'chainOutlet'}>
                      <UiText>
                        {restaurant && restaurant.average_delivery_time_display}
                      </UiText>
                    </UiColumn>
                    <UiColumn flex={2}>
                      <UiText>
                        {restaurant && restaurant.min_order_display}
                      </UiText>
                    </UiColumn>
                  </UiRow>
                </UiColumn>
              </UiRow>
            </UiView>
          </UiRow>
        </UiView>
        {restaurant && !restaurant.is_delivering_now && (
          <UiView style={styles.offlineOverlay} />
        )}
      </UiView>
    );
  }
}

const styles = {
  restaurantCardContainer: {
    position: 'relative',
    backgroundColor: '#ffffff',
    marginTop: units.quarter * 3,
    paddingTop: units.quarter * 3,
    paddingBottom: units.quarter * 3,
    paddingLeft: units.quarter * 3,
    paddingRight: units.quarter * 3,
    width: `calc(100% - ${units.full * 2})`,
    marginLeft: units.full,
    marginRight: units.full,
    boxSizing: 'border-box'
  },
  restaurantCardImage: {
    borderRadius: units.half,
    borderColor: '#dedede',
    borderWidth: 1,
    borderStyle: 'solid',
    position: 'relative'
  },
  chainDiscountLabel: {
    position: 'relative',
    width: 60,
    padding: 3
  },
  restaurantCardImageContainer: {
    width: '82px',
    boxSizing: 'border-box',
    display: 'flex'
  },
  restaurantCardContentContainer: {
    width: 'calc(100% - 80px)'
  },
  chainResCardContentContainer: {
    width: '100%'
  },
  restaurantCardTitle: {
    width: 'calc(100% - 30px)',
    paddingLeft: units.half,
    paddingRight: units.half,
    boxSizing: 'border-box'
  },
  chainResCardTitle: {
    width: 'calc(100% - 30px)'
  },
  chainResCardCuisines: {
    width: '100%'
  },
  restaurantCardCuisines: {
    width: '100%',
    paddingLeft: units.half,
    paddingRight: units.half,
    boxSizing: 'border-box'
  },
  discountLabelHinge: {
    position: 'absolute',
    left: -3,
    top: 22,
    width: 15,
    height: 15,
    transform: 'rotate(40deg)',
    overflow: 'hidden'
  },
  discountLabel: {
    position: 'absolute',
    left: -6,
    top: 10,
    width: 'initial',
    padding: 4,
    paddingLeft: 6,
    paddingRight: 6
  },
  outlet: {
    borderRadius: 5,
    borderColor: '#dedede',
    borderWidth: 1,
    borderStyle: 'solid'
  },
  offlineOverlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.6)'
  }
};
