import React, { Component } from 'react';

import { UiView, UiOverlay } from '../../dependencies/zomato-ui';

import CartObject from '../../containers/common/CartObjectContainer';
import CartDetail from '../../containers/cart/CartDetailContainer';
import CheckoutButton from '../../containers/cart/CheckoutButtonContainer';
import AppHeader from '../common/AppHeader';
import { getPageHeight } from '../../dependencies/modules/util';

export default class CartPage extends Component {
  componentDidMount() {
    const { resId } = this.props;

    this.props.setCalculatingCart(true);
    this.props
      .selectLocation()
      .then(() => {
        return this.props.setRestaurant();
      })
      .then(() => {
        this.props.setCartFromStorage({ resId });
      });
  }

  componentDidUpdate(prevProps) {
    const cartCalculations = this.props.getCartCalculations();
    if (
      prevProps.calculatingUpdatedCart !== this.props.calculatingUpdatedCart
    ) {
      this.props.setCartCalculation(cartCalculations);
    }
  }

  render() {
    const { calculatingCart, error } = this.props;
    return (
      <UiView style={{ flex: 1 }}>
        <CartObject resId={this.props.resId} />
        <AppHeader
          iconColor={'#999999'}
          iconSize={18}
          scrollerHeight={getPageHeight() - 110}
          showHeaderTitle
          showBackButton
          onBack={() => this.props.history.goBack()}
          title={'View Cart'}
        >
          <UiOverlay
            height={getPageHeight() - 110}
            show={calculatingCart && !error}
          />
          {!calculatingCart && !error && <CartDetail />}
          <CheckoutButton />
        </AppHeader>
      </UiView>
    );
  }
}
