export const SET_SELECTED_CUSTOMISED_ITEMS = 'SET_SELECTED_CUSTOMISED_ITEMS';
export const SET_SELECTED_DEPENDENCIES = 'SET_SELECTED_DEPENDENCIES';
export const CLEAR_CUSTOMISATIONS = 'CLEAR_CUSTOMISATIONS';

export const selectRadioItem = ({ groupId, newItem, oldItem }) => (
  dispatch,
  getState
) => {
  const state = getState().customisationsState;
  const selectedCustomisedItems = { ...state.selectedCustomisedItems };
  selectedCustomisedItems[groupId] = [newItem.item_id];

  dispatch(removePreviouslySelectedItem({ oldItem, selectedCustomisedItems }));
  const updatedDependenciesList = Object.values(selectedCustomisedItems).reduce(
    (result, array) => [...result, ...array]
  );

  dispatch(setSelectedCustomisedItems(selectedCustomisedItems));
  dispatch(setSelectedDependencies(updatedDependenciesList));
};

export const removePreviouslySelectedItem = params => (dispatch, getState) => {
  const state = getState().restaurantState;
  const item = params.oldItem;
  const selectedCustomisedItems = params.selectedCustomisedItems;

  if (item && item.groups && item.groups.length) {
    item.groups.forEach(oldItemGroupId => {
      delete selectedCustomisedItems[oldItemGroupId];
      const group = state.groups[oldItemGroupId];
      if (group && group.items && group.items.length) {
        group.items.forEach(groupItem => {
          dispatch(
            removePreviouslySelectedItem({
              oldItem: groupItem,
              selectedCustomisedItems
            })
          );
        });
      }
    });
  }
};

export const onModifierItemChange = ({ checked, groupId, itemId }) => (
  dispatch,
  getState
) => {
  const state = getState().customisationsState;
  const selectedItems = { ...state.selectedCustomisedItems };
  const selectedGroupItems = selectedItems[groupId]
    ? selectedItems[groupId]
    : [];
  if (checked) {
    selectedGroupItems.push(itemId);
  } else {
    const index = selectedGroupItems.indexOf(itemId);
    selectedGroupItems.splice(index, 1);
  }
  if (selectedGroupItems.length) {
    selectedItems[groupId] = selectedGroupItems;
  } else {
    delete selectedItems[groupId];
  }

  dispatch(setSelectedCustomisedItems(selectedItems));
};

export const setSelectedCustomisedItems = selectedCustomisedItems => dispatch => {
  dispatch({
    type: SET_SELECTED_CUSTOMISED_ITEMS,
    selectedCustomisedItems
  });
};

export const setSelectedDependencies = updatedDependenciesList => dispatch => {
  dispatch({
    type: SET_SELECTED_DEPENDENCIES,
    selectedDependencies: updatedDependenciesList
  });
};

export const clearCustomisations = () => dispatch => {
  dispatch({
    type: CLEAR_CUSTOMISATIONS
  });
};
