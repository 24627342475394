import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { OrderItems } from '../../components/summary';

export default withRouter(
  connect(state => {
    const { summary } = state.orderState;
    const orderItems = summary.orderItems;
    return { orderItems };
  })(OrderItems)
);
