import { connect } from 'react-redux';
import { withRouter } from '../../../dependencies/modules/react-router-native';
import { SelectCuisineFilter } from './../../../components/home/search';

import {
  getCategoriesList,
  setSelectedCuisine
} from '../../../store/search/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { selectedCuisine } = state.searchState;
      return { selectedCuisine };
    },
    {
      getCategoriesList,
      setSelectedCuisine
    }
  )(SelectCuisineFilter)
);
