import React, { Component } from 'react';

import {
  UiView,
  UiRow,
  UiColumn,
  UiRadioList
} from './../../../dependencies/zomato-ui';

export default class SelectCuisineFilter extends Component {
  onChange(item) {
    const cuisine = {
      title: item.title,
      value: item.value
    };

    if (
      !this.props.selectedCuisine ||
      this.props.selectedCuisine.value !== item.value
    ) {
      this.props.setSelectedCuisine(cuisine);
    }
  }

  render() {
    const { getCategoriesList, selectedCuisine } = this.props;
    return (
      <UiView>
        <UiRow>
          <UiColumn>
            <UiRow>
              <UiColumn>
                <UiRadioList
                  selectedTitle={selectedCuisine.title}
                  data={getCategoriesList('cuisines')}
                  name="cuisine"
                  onChange={this.onChange.bind(this)}
                />
              </UiColumn>
            </UiRow>
          </UiColumn>
        </UiRow>
      </UiView>
    );
  }
}
