import { Fetch } from './helpers';

export default {
  getData({ resId, deliverySubzoneId }) {
    if (!resId || !deliverySubzoneId) {
      return Promise.reject('Required field: resId, deliverySubzoneId');
    }

    const queryParams = {
      delivery_subzone_id: deliverySubzoneId
    };
    const path = `order/restaurant/${resId}/menu-with-details`;
    return Fetch.get({ path, queryParams });
  }
};
