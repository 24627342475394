import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import MenuItemCounter from '../../components/restaurant/MenuItemCounter';
import {
  setItem,
  setCartItem,
  increaseItemQuantity,
  decreaseCartItemQuantity
} from '../../store/cart/actions';

import {
  toggleChooseCustomisationsPopup,
  toggleChangeCustomisedItemQuantityPopup
} from '../../store/restaurant/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { restaurantState, cartState } = state;
      const itemId = ownProps.itemId;
      const restaurantMenuItems = restaurantState.items;
      const { items, cartItems } = cartState;
      const item = items[itemId];
      let quantity = 0;
      if (item && item.length) {
        item.forEach(cartItemId => {
          quantity += cartItems[cartItemId].quantity;
        });
      }
      const restaurantMenuItem = restaurantMenuItems[ownProps.itemId];
      const resId = ownProps.resId;
      return { item, itemId, resId, restaurantMenuItem, cartItems, quantity };
    },
    {
      setItem,
      setCartItem,
      increaseItemQuantity,
      decreaseCartItemQuantity,
      toggleChooseCustomisationsPopup,
      toggleChangeCustomisedItemQuantityPopup
    }
  )(MenuItemCounter)
);
