import React, { Component } from 'react';

import { UiText, UiView, UiCell } from './../../../dependencies/zomato-ui';

import Restaurants from './Restaurants';
import { getPageHeight } from '../../../dependencies/modules/util';
import ErrorPage from '../../common/ErrorPage';
import { debounce } from '../../../util';

export default class SearchResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      emptyError: null
    };

    this.setError = debounce(this.onError, 200);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.searchResults !== this.props.searchResults ||
      prevProps.searchResultRestaurants !== this.props.searchResultRestaurants
    ) {
      this.setError();
    }
  }

  onError() {
    const { searchResults, searchResultRestaurants } = this.props;

    if (
      !searchResults.loading &&
      searchResultRestaurants &&
      !searchResultRestaurants.length
    ) {
      this.setState({
        emptyError: { status: 'empty' }
      });
      return;
    }
    this.setState({
      emptyError: null
    });
  }

  render() {
    const { emptyError } = this.state;
    const { show, searchResults, searchResultRestaurants } = this.props;

    if (!show) {
      return null;
    }

    if (searchResultRestaurants && searchResultRestaurants.length) {
      return <Restaurants restaurantList={searchResultRestaurants} />;
    }

    return (
      <UiView
        style={{
          height: getPageHeight() - 90,
          backgroundColor: 'rgb(249, 249, 249)'
        }}
      >
        {!searchResults.loading &&
        !searchResults.error &&
        !emptyError &&
        !searchResultRestaurants ? (
          <UiCell padded paddedBorder>
            <UiText color="secondary">Start typing...</UiText>
          </UiCell>
        ) : (
          <ErrorPage error={searchResults.error || emptyError} />
        )}
      </UiView>
    );
  }
}
