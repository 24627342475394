import React, { Component } from 'react';

import { List } from './../../../dependencies/zomato-ui';

import RestaurantCard from '../../../containers/home/restaurants/RestaurantCardContainer';
import { getPageHeight } from '../../../dependencies/modules/util';

export default class Restaurants extends Component {
  render() {
    const { restaurantList } = this.props;

    if (!restaurantList) {
      return null;
    }

    return (
      <List
        scrollable
        height={getPageHeight() - 70}
        data={restaurantList}
        renderItem={resId => <RestaurantCard resId={resId} type="outlet" />}
      />
    );
  }
}
