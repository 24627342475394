import React, { Component } from 'react';
import { Link } from '../modules/react-router-native';

export default class UiButton extends Component<{}> {
  render() {
    const { href, children } = this.props;
    return (
      <Link style={{ textDecoration: 'none' }} to={href}>
        <div>{children}</div>
      </Link>
    );
  }
}

UiButton.defaultProps = {
  feedback: false
};
