import React, { Component } from 'react';

import {
  UiRow,
  UiColumn,
  UiHeading,
  UiView,
  UiCell,
  List,
  UiDivider
} from './../../dependencies/zomato-ui';

import PersonalDetails from '../../containers/cart/PersonalDetailsContainer';
import CartItems from '../../containers/cart/CartItemsContainer';

import LocationSnippet from '../../containers/common/LocationSnippetContainer';
// import PromoInput from '../../containers/cart/PromoInputContainer'

export default class CartDetail extends Component {
  onEmpty() {
    this.props.history.goBack();
  }

  onItemRemove() {
    if (this.props.cartItemQuantity === 1) {
      this.onEmpty();
    }
  }

  render() {
    const { checkIfAddressIsInvalid, resId, cartResponse } = this.props;
    const backgroundStyle = checkIfAddressIsInvalid
      ? 'rgba(253,251,211,0.7)'
      : '#fff';

    return (
      <UiView>
        <UiRow>
          <UiColumn>
            <PersonalDetails />
          </UiColumn>
        </UiRow>
        <UiView style={{ backgroundColor: backgroundStyle }}>
          <UiRow marginTop>
            <UiColumn>
              <LocationSnippet
                type="address"
                pageType="cart"
                showIcon={resId}
                resId={resId}
              />
            </UiColumn>
          </UiRow>
        </UiView>
        <UiRow>
          <UiColumn>
            {/* <UiRow margin>
              <PromoInput resId={resId} />
            </UiRow> */}
            <UiRow marginTop>
              <UiColumn>
                <UiCell padded marginTop>
                  <UiHeading size="huge">Your Cart</UiHeading>
                  <UiDivider />
                </UiCell>
                {this.props.items && (
                  <UiCell>
                    <List
                      data={Object.keys(this.props.items)}
                      renderItem={itemId => (
                        <CartItems
                          key={itemId}
                          resId={resId}
                          itemId={itemId}
                          onItemRemove={this.onItemRemove.bind(this)}
                        />
                      )}
                    />
                    <UiDivider />
                  </UiCell>
                )}
              </UiColumn>
            </UiRow>
            <UiRow>
              <UiColumn>
                {cartResponse.items &&
                  cartResponse.items.map((cartCalculation, index) => {
                    if (!cartCalculation.total_cost) {
                      return null;
                    }

                    return (
                      <UiCell
                        paddingTop
                        paddingBottom={['total_user'].includes(
                          cartCalculation.type
                        )}
                        marginTop={['total_user'].includes(
                          cartCalculation.type
                        )}
                        key={index}
                        borderTop={
                          cartCalculation.border_top ? 'default' : 'none'
                        }
                        borderBottom={
                          cartCalculation.border_bottom ||
                          index === cartResponse.items.length - 1
                            ? 'default'
                            : 'none'
                        }
                      >
                        {cartCalculation.display_cost.text && (
                          <UiRow alignItems="center">
                            <UiColumn flex={3} nonPadded>
                              <UiHeading
                                color={
                                  cartCalculation.item_name.color
                                    ? cartCalculation.item_name.color
                                    : 'secondary'
                                }
                                size={
                                  cartCalculation.item_name.size
                                    ? cartCalculation.item_name.size
                                    : 'medium'
                                }
                              >
                                {cartCalculation.item_name.text}
                              </UiHeading>
                            </UiColumn>
                            <UiColumn nonPadded>
                              <UiView
                                style={{
                                  justifyContent: 'flex-end',
                                  alignItems: 'flex-end'
                                }}
                              >
                                {cartCalculation.discounted_cost &&
                                  cartCalculation.discounted_cost !==
                                    cartCalculation.total_cost && (
                                    <UiHeading
                                      class="original-price"
                                      decoration="line-through"
                                      color={
                                        cartCalculation.discounted_display_cost
                                          .color
                                          ? cartCalculation
                                              .discounted_display_cost.color
                                          : 'secondary'
                                      }
                                      size={`${
                                        cartCalculation.discounted_display_cost
                                          .size
                                          ? cartCalculation
                                              .discounted_display_cost.size
                                          : 'medium'
                                      }Cart`}
                                      align="right"
                                      style={{ alignSelf: 'flex-end' }}
                                    >
                                      {cartCalculation.display_cost.text}
                                    </UiHeading>
                                  )}
                                <UiHeading
                                  color={
                                    cartCalculation.display_cost.color
                                      ? cartCalculation.display_cost.color
                                      : 'secondary'
                                  }
                                  size={`${
                                    cartCalculation.display_cost.size
                                      ? cartCalculation.display_cost.size
                                      : 'medium'
                                  }Cart`}
                                  align="right"
                                  style={{ alignSelf: 'flex-end' }}
                                >
                                  {cartCalculation.discounted_cost
                                    ? cartCalculation.discounted_display_cost
                                        .text
                                    : cartCalculation.display_cost.text}
                                </UiHeading>
                              </UiView>
                            </UiColumn>
                          </UiRow>
                        )}
                      </UiCell>
                    );
                  })}
              </UiColumn>
            </UiRow>
          </UiColumn>
        </UiRow>
      </UiView>
    );
  }
}
