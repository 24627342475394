import React, { Component } from 'react';

import {
  UiRow,
  UiColumn,
  UiHeading,
  UiCell
} from '../../dependencies/zomato-ui';

import CustomisationsRadio from '../../containers/restaurant/CustomisationsRadioContainer';
import CustomisationsItem from '../../containers/restaurant/CustomisationsItemContainer';

export default class CustomisationsGroup extends Component {
  getOptionSelectionCountString() {
    const { group } = this.props;
    let optionSelectionCountString = '';
    if (group.min < 1 && group.max > 0) {
      if (group.max === 1) {
        optionSelectionCountString = 'You can choose up to 1 option';
      } else {
        optionSelectionCountString = `You can choose up to ${group.max} options`;
      }
    } else if (group.min === 1 && group.max === 1) {
      optionSelectionCountString = 'Please select any one option';
    } else {
      optionSelectionCountString = `Select a minimum of ${group.min} and a maximum of ${group.max}`;
    }
    return optionSelectionCountString;
  }

  render() {
    const { group, isParentItemSelected } = this.props;

    if (
      !group ||
      !group.group_id ||
      (group.parentItemId && !isParentItemSelected)
    ) {
      return null;
    }

    return (
      <UiCell padded>
        <UiRow>
          <UiColumn>
            <UiRow>
              <UiColumn>
                <UiCell margin>
                  <UiHeading size="large">{group.name}</UiHeading>
                </UiCell>
              </UiColumn>
            </UiRow>
            <UiRow marginTop>
              <UiColumn>
                <UiHeading size="small" color="secondary">
                  {this.getOptionSelectionCountString()}
                </UiHeading>
              </UiColumn>
            </UiRow>
          </UiColumn>
        </UiRow>
        {group.min === 1 && group.max === 1 ? (
          <CustomisationsRadio
            groupId={group.group_id}
            groupItems={group.items}
          />
        ) : (
          group.items.map((groupItem, index) => {
            return (
              <CustomisationsItem
                key={groupItem.item_id}
                checked={index < group.min}
                groupId={group.group_id}
                groupItem={groupItem}
                groupMax={group.max}
              />
            );
          })
        )}
      </UiCell>
    );
  }
}
