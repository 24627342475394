import { locations } from '../../api';
import { getToken } from '../user/actions';
import { Storage } from '../../util';
import Partners from '../../dependencies/modules/partners';

export const SET_LOCATIONS_LIST = 'SET_LOCATIONS_LIST';
export const SELECT_LOCATION = 'SELECT_LOCATION';
export const CLEAR_LOCATIONS = 'CLEAR_LOCATIONS';
export const SELECT_LOCATION_FOR_ADDRESS = 'SELECT_LOCATION_FOR_ADDRESS';
export const CLEAR_SELECTED_LOCATION_FOR_ADDRESS =
  'CLEAR_SELECTED_LOCATION_FOR_ADDRESS';
export const SELECT_CURRENT_LAT_LNG = 'SELECT_CURRENT_LAT_LNG';

export const searchLocations = query => (dispatch, getState) => {
  const state = getState().locationsState;
  if (!query || query.length <= 2) {
    dispatch(setLocationsList(null));
    return Promise.resolve();
  }

  const { latitude, longitude } = state.currentLatLong;
  return locations
    .search({
      query,
      latitude,
      longitude
    })
    .then(response => {
      dispatch(setLocationsList(response.locations));
      return response;
    });
};

export const setLocationsList = locations => dispatch => {
  dispatch({
    type: SET_LOCATIONS_LIST,
    locations: locations ? [...locations] : null
  });
};

export const getNearestDSZ = data => (dispatch, getState) => {
  return getLatLng()
    .then(coords => {
      const position = {
        lat: coords.latitude,
        lng: coords.longitude
      };

      dispatch(setCurrentLatLng(position));
    })
    .then(() => {
      const { locationsState } = getState();
      const { currentLatLong } = locationsState;

      return locations
        .getNearestDeliverySubzone(currentLatLong)
        .then(response => {
          if (data.showAddressList) {
            dispatch(selectLocation(response));
          } else {
            dispatch(selectLocationForAddress(response));
          }
        });
    });
};

function getLatLng() {
  return new Promise((resolve, reject) => {
    const partner = Partners.getClient();
    partner
      .requestCurrentLocation()
      .then(position => {
        resolve(position);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export const selectLocationForAddress = selectedLocationForAddress => dispatch => {
  dispatch({
    type: SELECT_LOCATION_FOR_ADDRESS,
    selectedLocationForAddress
  });
};

export const setCurrentLatLng = currentLatLong => dispatch => {
  dispatch({
    type: SELECT_CURRENT_LAT_LNG,
    currentLatLong
  });
};

export const selectLocation = selectedLocation => dispatch => {
  return Storage.getItem('selectedLocation').then(response => {
    const location = selectedLocation ? selectedLocation : response;

    Storage.setItem('selectedLocation', location);

    dispatch(selectLocationForAddress(location));
    dispatch({
      type: SELECT_LOCATION,
      location
    });
    return;
  });
};

export const clearSelectedLocationForAddress = () => dispatch => {
  dispatch({
    type: CLEAR_SELECTED_LOCATION_FOR_ADDRESS
  });
};

export const clearLocations = () => dispatch => {
  dispatch({
    type: CLEAR_LOCATIONS
  });
};
