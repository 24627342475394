import React, { Component } from 'react';

import {
  UiText,
  UiRow,
  UiColumn,
  UiHeading,
  UiView,
  UiCell,
  UiSnackbar
} from './../../dependencies/zomato-ui';

import { colors } from '../../dependencies/zomato-ui/util/Theme';
import { Storage } from '../../util';

export default class CartButton extends Component {
  state = {
    error: null
  };

  componentDidUpdate(prevProps) {
    const cartCalculations = this.props.getCartCalculations();

    if (prevProps.cartCalculations === this.props.cartCalculations) {
      this.props.setCartCalculation(cartCalculations);
    }
  }

  viewCartButton() {
    let viewCartButton = styles.hideViewCartBtn;
    if (this.props.cartCalculations && this.props.cartCalculations.totalItems) {
      viewCartButton = styles.showViewCartBtn;
    }

    return viewCartButton;
  }

  navigateToCart() {
    const { resId } = this.props;

    this.props
      .getUserDetails()
      .then(() => {
        this.setRestaurantInStorage();
        this.props.history.push(`/cart/${resId}`);
      })
      .catch(error => {
        this.setState({
          error: error
        });
      });
  }

  setRestaurantInStorage() {
    const { restaurantInfo, offer, items, generateItemObject } = this.props;
    const dispatchData = {
      restaurantInfo,
      offer,
      items: {},
      groups: {},
      groupItems: {}
    };

    Object.keys(items).forEach(itemId => {
      generateItemObject({ dispatchData, itemId });
    });
    Storage.setItem('restaurant', { ...dispatchData });
  }

  handleClose() {
    this.setState({
      error: null
    });
  }

  render() {
    const { cartResId, resId } = this.props;
    const { error } = this.state;

    if (cartResId !== resId) {
      return null;
    }

    return (
      <UiView
        style={{
          ...this.viewCartButton(),
          ...styles.viewCartBtn,
          backgroundColor: colors['button']['primary']
        }}
      >
        <UiView
          style={styles.cartBtnLink}
          onClick={this.navigateToCart.bind(this)}
        >
          {this.props.minOrderAlert() && (
            <UiView
              style={{
                ...styles.suggestion,
                backgroundColor: colors['background']['grayishOrange']
              }}
            >
              <UiRow>
                <UiColumn>
                  <UiText size="small" align="center">
                    {this.props.minOrderAlert()}
                  </UiText>
                </UiColumn>
              </UiRow>
            </UiView>
          )}
          {this.props.showDiscountSuggestion() && this.props.offerSuggestion() && (
            <UiView
              style={{
                ...styles.suggestion,
                backgroundColor: colors['background']['grayishOrange']
              }}
            >
              <UiRow>
                <UiColumn>
                  <UiText size="small" align="center">
                    {this.props.offerSuggestion()}
                  </UiText>
                </UiColumn>
              </UiRow>
            </UiView>
          )}
          <UiView style={styles.cartBlue}>
            <UiCell margin>
              <UiRow>
                <UiColumn doublePadded>
                  <UiRow>
                    <UiColumn nonPadded>
                      <UiView style={styles.leftColumn}>
                        <UiText size="mini" color="inverted">
                          {this.props.cartCalculations.totalItems} items in cart
                        </UiText>
                        <UiRow>
                          {this.props.isOfferApplicable() &&
                            this.props.originalTotalAmount() && (
                              <UiColumn nonPadded>
                                <UiView style={styles.originalPrice}>
                                  <UiHeading
                                    size="small"
                                    color="inverted"
                                    decoration="line-through"
                                  >
                                    {this.props.originalTotalAmount()}
                                  </UiHeading>
                                </UiView>
                              </UiColumn>
                            )}
                          <UiColumn flex={4} nonPadded>
                            <UiHeading size="small" color="inverted">
                              {this.props.finalTotalAmount()}
                            </UiHeading>
                          </UiColumn>
                        </UiRow>
                        <UiText size="mini" color="inverted">
                          plus taxes
                        </UiText>
                      </UiView>
                    </UiColumn>
                    <UiView
                      style={{
                        justifyContent: 'flex-end',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <UiHeading size="big" color="inverted" align="right">
                        View Cart
                      </UiHeading>
                    </UiView>
                  </UiRow>
                </UiColumn>
              </UiRow>
            </UiCell>
          </UiView>
        </UiView>
        <UiSnackbar
          open={error}
          handleClose={this.handleClose.bind(this)}
          message={'Email is required!'}
        />
      </UiView>
    );
  }
}

const styles = {
  viewCartBtn: {
    overflow: 'hidden',
    position: 'fixed',
    flex: 1,
    left: 0,
    right: 0,
    justifyContent: 'center'
  },
  cartBtnLink: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center'
  },
  cartBlue: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row'
  },
  leftColumn: {
    alignItems: 'flex-start'
  },
  rightColumn: {
    alignSelf: 'center'
  },
  originalPrice: {
    marginRight: 6
  },
  labelColumn: {
    alignItems: 'flex-end'
  },
  suggestion: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2
  },
  hideViewCartBtn: {
    bottom: '-250px'
  },
  showViewCartBtn: {
    bottom: 0
  }
};
