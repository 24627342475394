import { connect } from 'react-redux';
import { withRouter } from '../../../dependencies/modules/react-router-native';
import RestaurantChainOutlets from '../../../components/home/restaurants/RestaurantChainOutlets';

export default withRouter(
  connect((state, ownProps) => {
    const { restaurants } = state.searchState;
    const restaurant = restaurants[ownProps.resId];
    return { restaurant };
  })(RestaurantChainOutlets)
);
