import React, { Component } from 'react';
import { colors } from './util/Theme';
import UiView from './View';
import FeedbackOverlay from './feedbackOverlay';

const styles = {
  container: {
    flex: 1,
    position: 'relative'
  },
  paddedBorder: {
    paddingLeft: 12,
    paddingRight: 12
  },
  UiCell: {
    flex: 1
  }
};

export default class UiCell extends Component {
  onClick() {
    this.props.onClick && this.props.onClick();
  }

  render() {
    const {
      margin,
      marginTop,
      marginBottom,
      padded,
      paddingTop,
      paddingBottom,
      borderTop,
      borderBottom,
      feedback,
      paddedBorder,
      background,
      DOMref
    } = this.props;

    let marginSet = { top: 0, bottom: 0, left: 0, right: 0 };
    marginSet = margin ? { ...marginSet, top: 6, bottom: 6 } : marginSet;
    marginSet = marginTop ? { ...marginSet, top: 6 } : marginSet;
    marginSet = marginBottom ? { ...marginSet, bottom: 6 } : marginSet;

    let paddingSet = { top: 0, bottom: 0, left: 0, right: 0 };
    paddingSet = padded ? { ...paddingSet, top: 6, bottom: 6 } : paddingSet;
    paddingSet = paddingTop ? { ...paddingSet, top: 6 } : paddingSet;
    paddingSet = paddingBottom ? { ...paddingSet, bottom: 6 } : paddingSet;

    let borderTopColor = colors.border[borderTop]
      ? colors.border[borderTop]
      : borderTop;
    if (borderTop === 'default') {
      borderTopColor = colors.border.primary;
    }

    let borderBottomColor = colors.border[borderBottom]
      ? colors.border[borderBottom]
      : borderBottom;
    if (borderBottom === 'default') {
      borderBottomColor = colors.border.primary;
    }

    const feedbackStyle = feedback ? styles.feedback : {};
    const paddedBorderStyle = paddedBorder ? styles.paddedBorder : {};

    return (
      <UiView
        DOMref={DOMref}
        style={{
          ...styles.container,
          ...feedbackStyle,
          ...paddedBorderStyle
        }}
        onClick={this.onClick.bind(this)}
      >
        <UiView
          style={{
            ...styles.UiCell,
            marginTop: marginSet.top,
            marginBottom: marginSet.bottom,
            marginLeft: marginSet.left,
            marginRight: marginSet.right,
            paddingTop: paddingSet.top,
            paddingBottom: paddingSet.bottom,
            paddingLeft: paddingSet.left,
            paddingRight: paddingSet.right,
            backgroundColor: background,
            borderBottomColor: borderBottomColor,
            borderBottomWidth: borderBottom === 'none' ? 0 : 1,
            borderBottomStyle: borderBottom === 'none' ? '' : 'solid',
            borderTopColor: borderTopColor,
            borderTopWidth: borderTop === 'none' ? 0 : 1,
            borderTopStyle: borderTop === 'none' ? '' : 'solid'
          }}
        >
          {this.props.children}
        </UiView>
        {feedback && <FeedbackOverlay onClick={this.onClick.bind(this)} />}
      </UiView>
    );
  }
}

UiCell.defaultProps = {
  background: 'transparent',
  borderTop: 'none',
  borderBottom: 'none'
};
