import React, { Component } from 'react';

import { getPageHeight } from '../../dependencies/modules/util';
import { getErrorTitleMessage } from '../../util';

import {
  UiText,
  UiRow,
  UiColumn,
  UiHeading,
  UiView,
  UiLink,
  UiButton,
  UiOverlay,
  UiDialog
} from '../../dependencies/zomato-ui';

export default class SelectLocationSnippet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetectLocationIndicator: false,
      error: null,
      errorTitle: '',
      errorMessage: ''
    };
  }

  detectLocation() {
    this.setState({
      showDetectLocationIndicator: true,
      error: null
    });

    const data = {
      showAddressList: true
    };

    this.props
      .getNearestDSZ(data)
      .then(() => {
        this.setState({ showDetectLocationIndicator: false });
      })
      .catch(error => {
        const errorObject = getErrorTitleMessage(error);

        this.setState({
          showDetectLocationIndicator: false,
          error,
          errorTitle: errorObject.errorTitle,
          errorMessage: errorObject.errorMessage
        });
      });
  }

  disMissErrorDialog() {
    this.setState({ error: null });
  }

  render() {
    const messageString = ` We only access location while you are using the app to improve your order experience. `.trim();
    const {
      showDetectLocationIndicator,
      error,
      errorTitle,
      errorMessage
    } = this.state;

    return (
      <UiView style={{ ...styles.snippetContainer, height: getPageHeight() }}>
        <UiRow marginBottom>
          <UiColumn>
            <UiRow marginBottom>
              <UiColumn nonPadded>
                <UiHeading align="center" size="huge">
                  Hi, nice to meet you
                </UiHeading>
              </UiColumn>
            </UiRow>
          </UiColumn>
        </UiRow>
        <UiRow margin>
          <UiColumn>
            <UiRow margin>
              <UiColumn>
                <UiButton
                  onPress={this.detectLocation.bind(this)}
                  title="Use current location"
                  type="primary"
                  variant="outlined"
                />
              </UiColumn>
            </UiRow>
          </UiColumn>
        </UiRow>
        <UiRow margin>
          <UiColumn>
            <UiRow margin>
              <UiColumn nonPadded>
                <UiText align="center" color="secondary">
                  {messageString}
                </UiText>
              </UiColumn>
            </UiRow>
          </UiColumn>
        </UiRow>
        <UiRow marginTop>
          <UiColumn nonPadded>
            <UiLink href="/select-location/true" feedback>
              <UiHeading size="big" align="center" type="anchor">
                Select your location manually
              </UiHeading>
            </UiLink>
          </UiColumn>
        </UiRow>
        <UiOverlay show={showDetectLocationIndicator && !error} />
        <UiDialog
          isVisible={!showDetectLocationIndicator && error ? true : false}
          title={errorTitle}
          content={errorMessage}
          confirmText="OK"
          onConfirm={this.disMissErrorDialog.bind(this)}
        />
      </UiView>
    );
  }
}

const styles = {
  snippetContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '20px',
    boxSizing: 'border-box'
  }
};
