import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import CartObject from '../../components/common/CartObject';
import { getUserPermission } from '../../store/user/actions';

import {
  getCartFinalCalculations,
  setCalculatingCart,
  setCalculatingUpdatedCart,
  buildCartObject
} from '../../store/cart/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { selectedLocation } = state.locationsState;
      const { cartItems } = state.cartState;
      const { mobileNumber, uuid, name } = state.userState;
      const resId = ownProps.resId;
      return { resId, cartItems, mobileNumber, uuid, name, selectedLocation };
    },
    {
      getCartFinalCalculations,
      setCalculatingCart,
      setCalculatingUpdatedCart,
      buildCartObject,
      getUserPermission
    }
  )(CartObject)
);
