import { StateManager } from '../../util';
import {
  SET_ADDRESS_DATA,
  SET_NEW_ADDRESS_DATA,
  SET_NEW_ADDRESS
} from './actions';
export const initialState = {
  addressList: [],
  addresses: {},
  newAddressData: {}
};

const setAddressData = (state, action) => {
  const { addressList, addresses } = action;
  return {
    ...state,
    addressList,
    addresses
  };
};

const setNewAddressData = (state, action) => {
  const { address } = action;
  return {
    ...state,
    newAddressData: { ...address }
  };
};

const setNewAddress = (state, action) => {
  const { addressList, addresses } = action;
  return {
    ...state,
    addressList,
    addresses: { ...addresses }
  };
};

const actions = {
  [SET_ADDRESS_DATA]: setAddressData,
  [SET_NEW_ADDRESS_DATA]: setNewAddressData,
  [SET_NEW_ADDRESS]: setNewAddress
};

const manager = new StateManager(initialState, actions);

export default manager.reducer;
