import React, { Component } from 'react';

export default class WebView extends Component {
  render() {
    return (
      <iframe
        style={{
          border: 0,
          display: 'block',
          width: '100%',
          height: '470px'
        }}
        id={this.props.id}
        title={this.props.title}
        src={this.props.src}
      ></iframe>
    );
  }
}
