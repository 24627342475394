import React, { Component } from 'react';

import {
  UiText,
  UiView,
  UiCell,
  UiTextInput
} from './../../dependencies/zomato-ui';

import AddressNickName from './AddressNickName';

import AddressMapButton from '../common/AddressMapButton';

export default class AddAddress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: {
        address: '',
        address_special_instructions: '',
        alias: 'HOME',
        place_id: '',
        place_name: '',
        place_type: '',
        delivery_subzone_id: '',
        place_name_main: '',
        place_name_secondary: '',
        address_id: 0,
        res_id: null
      },
      error: null,
      isloading: false
    };
  }

  componentDidMount() {
    const { selectedLocationForAddress, selectedLocation } = this.props;

    if (!selectedLocation) {
      this.props.selectLocation();
      return;
    }
    if (!selectedLocationForAddress && selectedLocation) {
      this.props.selectLocationForAddress(selectedLocation);
    }
  }

  onChange(event) {
    this.setState({
      address: {
        ...this.state.address,
        [event.target.name]: event.target.value
      }
    });
  }

  isAddButtonEnabled() {
    return this.CheckIfDataIsValid();
  }

  CheckIfDataIsValid() {
    const { selectedLocationForAddress } = this.props;

    if (
      !selectedLocationForAddress ||
      !selectedLocationForAddress.place.place_id ||
      !this.state.address.address ||
      !this.state.address.alias
    ) {
      return false;
    }
    return true;
  }

  navigateToAddressMap() {
    const { selectedLocationForAddress, resId } = this.props;

    if (!this.isAddButtonEnabled() && !this.CheckIfDataIsValid()) {
      return;
    }
    const place = selectedLocationForAddress.place;
    const address = { ...this.state.address };
    address.delivery_subzone_id = place.delivery_subzone_id;
    address.delivery_subzone_name = place.place_name;
    address.place_id = place.place_id;
    address.place_name = place.place_name;
    address.place_name_main = place.place_name_main;
    address.place_name_secondary = place.place_name_secondary;
    address.place_type = place.place_type;
    address.res_id = resId;
    // this.isloading = true;
    // this.isloading = false;

    this.setState(address);
    this.props.setNewAddressData(address);
    this.props.history.push('/address-map');
  }

  onSelect(value) {
    this.setState({
      address: {
        ...this.state.address,
        alias: value
      }
    });
  }

  render() {
    const { address } = this.state;
    return (
      <UiView>
        <UiCell margin padded borderBottom="default">
          <UiText color="secondary">Complete address*</UiText>
          <UiView>
            <UiTextInput
              name="address"
              value={address.address}
              placeholder="Enter complete address"
              onChange={this.onChange.bind(this)}
            />
          </UiView>
        </UiCell>
        <UiCell margin padded borderBottom="default">
          <UiText color="secondary">Delivery instructions</UiText>
          <UiView>
            <UiTextInput
              name="address_special_instructions"
              value={address.address_special_instructions}
              placeholder="Enter delivery instructions"
              onChange={this.onChange.bind(this)}
            />
          </UiView>
        </UiCell>
        <UiView style={{ marginTop: 5 }}>
          <UiText color="secondary">
            E.g. Don't ring the bell; near metro station.
          </UiText>
        </UiView>
        <AddressNickName onSelect={this.onSelect.bind(this)} />
        <AddressMapButton
          isAddButtonEnabled={this.isAddButtonEnabled()}
          navigateToAddressMap={this.navigateToAddressMap.bind(this)}
        />
      </UiView>
    );
  }
}
