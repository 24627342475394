import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import CallRestaurant from '../../components/summary/CallRestaurant';

export default withRouter(
  connect(state => {
    const { summary } = state.orderState;
    const restaurant = summary.restaurant;
    const mobilePhoneDisplay = restaurant.mobile_phone_display;
    const restaurantName = restaurant.name;
    return { mobilePhoneDisplay, restaurantName };
  })(CallRestaurant)
);
