import React, { Component } from 'react';
import {
  UiView,
  UiRow,
  UiColumn,
  UiCell,
  UiHeading,
  UiText,
  UiDivider
} from '../../dependencies/zomato-ui';

export default class OrderDetails extends Component {
  render() {
    const { summary } = this.props;
    const {
      orderId,
      paymentText,
      addedOn,
      userPhone,
      address,
      deliverySubzoneName
    } = summary;
    return (
      <UiView style={{ marginTop: 20 }}>
        <UiRow>
          <UiColumn doublePadded>
            <UiHeading size="medium">Order Details</UiHeading>
          </UiColumn>
        </UiRow>
        <UiRow>
          <UiColumn doublePadded>
            <UiDivider />
          </UiColumn>
        </UiRow>
        <UiRow>
          <UiColumn doublePadded>
            <UiCell padded>
              <UiText size="mini" color="secondary">
                ORDER NUMBER
              </UiText>
              <UiText size="mini">{orderId}</UiText>
            </UiCell>
          </UiColumn>
        </UiRow>
        <UiRow>
          <UiColumn doublePadded>
            <UiCell padded>
              <UiText size="mini" color="secondary">
                PAYMENT
              </UiText>
              <UiText size="mini">{paymentText}</UiText>
            </UiCell>
          </UiColumn>
        </UiRow>
        <UiRow>
          <UiColumn doublePadded>
            <UiCell padded>
              <UiText size="mini" color="secondary">
                DATE
              </UiText>
              <UiText size="mini">{addedOn}</UiText>
            </UiCell>
          </UiColumn>
        </UiRow>
        <UiRow>
          <UiColumn doublePadded>
            <UiCell padded>
              <UiText size="mini" color="secondary">
                PHONE NUMBER
              </UiText>
              <UiText size="mini">{userPhone}</UiText>
            </UiCell>
          </UiColumn>
        </UiRow>
        <UiRow>
          <UiColumn doublePadded>
            <UiCell padded>
              <UiText size="mini" color="secondary">
                DELIVER TO
              </UiText>
              <UiText size="mini">
                {address}, {deliverySubzoneName}
              </UiText>
            </UiCell>
          </UiColumn>
        </UiRow>
        <UiRow>
          <UiColumn doublePadded>
            <UiDivider />
          </UiColumn>
        </UiRow>
      </UiView>
    );
  }
}
