import { StateManager } from '../../util';
import {
  SET_ERROR,
  SET_LOADING,
  SET_LAST_QUERY,
  SET_SEARCH_DATA,
  SET_FILTER_DATA,
  SET_APPLIED_FILTERS,
  SET_APPLIED_CUISINE,
  SET_SELECTED_FILTERS,
  SET_SELECTED_SORTING,
  SET_SELECTED_CUISINE,
  UPDATE_RESTAURANT_LIST,
  SET_RESTAURANT_LIST,
  SET_RENDERED_RESTAURANTS,
  SET_SEARCH_RESULTS_RESTAURANTS
} from './actions';
export const initialState = {
  location: {},
  hasMore: true,
  totalCount: 0,
  responseCount: 10,
  restaurants: null,
  restaurantList: null,
  searchResults: {
    lastQuery: null,
    restaurantList: null,
    loading: false,
    error: null
  },
  renderedRestaurants: [],
  selectedFilters: [],
  appliedFilters: [],
  selectedCuisine: {},
  appliedCuisine: {},
  selectedSorting: {},
  filterObject: {
    categories: []
  }
};

const setlastQuery = (state, action) => {
  const { lastQuery } = action;
  return {
    ...state,
    searchResults: {
      ...state.searchResults,
      lastQuery
    }
  };
};

const setSearchResultRestaurants = (state, action) => {
  const { restaurantList, restaurants } = action;
  return {
    ...state,
    restaurants: { ...state.restaurants, ...restaurants },
    searchResults: {
      ...state.searchResults,
      restaurantList
    }
  };
};

const updateRestaurantList = (state, action) => {
  const { restaurants, restaurantList } = action;
  return {
    ...state,
    restaurantList: [...state.restaurantList, ...restaurantList],
    restaurants: { ...state.restaurants, ...restaurants }
  };
};

const setRestaurantList = (state, action) => {
  const { restaurants, restaurantList } = action;
  return {
    ...state,
    restaurants: { ...restaurants },
    restaurantList: [...restaurantList]
  };
};

const setSearchData = (state, action) => {
  const { location, hasMore, totalCount, responseCount, filterObject } = action;
  return {
    ...state,
    location,
    hasMore,
    totalCount,
    responseCount,
    filterObject
  };
};

const setFilterData = (state, action) => {
  const { filterObject } = action;
  return {
    ...state,
    filterObject
  };
};

const setSelectedFilters = (state, action) => {
  const { filters } = action;
  return {
    ...state,
    selectedFilters: [...filters]
  };
};

const setSelectedCuisine = (state, action) => {
  const { cuisine } = action;
  return {
    ...state,
    selectedCuisine: { ...cuisine }
  };
};

const applySelectedFilters = (state, action) => {
  const { filters } = action;
  return {
    ...state,
    appliedFilters: [...filters]
  };
};

const applySelectedCuisine = (state, action) => {
  const { cuisine } = action;
  return {
    ...state,
    appliedCuisine: { ...cuisine }
  };
};

const setSelectedSorting = (state, action) => {
  const { sorting } = action;
  return {
    ...state,
    selectedSorting: { ...sorting }
  };
};

const setRenderedRestaurants = (state, action) => {
  const { renderedRestaurants } = action;
  return {
    ...state,
    renderedRestaurants
  };
};

const setError = (state, action) => {
  const { error } = action;
  return {
    ...state,
    searchResults: {
      ...state.searchResults,
      error: error
    }
  };
};

const setLoading = (state, action) => {
  const { loading } = action;
  return {
    ...state,
    searchResults: {
      ...state.searchResults,
      loading
    }
  };
};

const actions = {
  [SET_ERROR]: setError,
  [SET_LOADING]: setLoading,
  [SET_LAST_QUERY]: setlastQuery,
  [SET_SEARCH_DATA]: setSearchData,
  [SET_FILTER_DATA]: setFilterData,
  [SET_SELECTED_SORTING]: setSelectedSorting,
  [SET_APPLIED_FILTERS]: applySelectedFilters,
  [SET_APPLIED_CUISINE]: applySelectedCuisine,
  [SET_SELECTED_FILTERS]: setSelectedFilters,
  [SET_SELECTED_CUISINE]: setSelectedCuisine,
  [SET_RESTAURANT_LIST]: setRestaurantList,
  [UPDATE_RESTAURANT_LIST]: updateRestaurantList,
  [SET_RENDERED_RESTAURANTS]: setRenderedRestaurants,
  [SET_SEARCH_RESULTS_RESTAURANTS]: setSearchResultRestaurants
};

const manager = new StateManager(initialState, actions);

export default manager.reducer;
