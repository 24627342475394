import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import Fssai from './../../components/restaurant/Fssai';

export default withRouter(
  connect((state, ownProps) => {
    const { restaurantInfo } = state.restaurantState;
    const fssai = restaurantInfo.fssai;
    return { fssai };
  })(Fssai)
);
