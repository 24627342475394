import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import searchReducer, { initialState as searchState } from './search/reducers';
import restaurantReducer, {
  initialState as restaurantState
} from './restaurant/reducers';
import addressReducer, {
  initialState as addressState
} from './address/reducers';
import cartReducer, { initialState as cartState } from './cart/reducers';
import customisationsReducer, {
  initialState as customisationsState
} from './customisations/reducers';
import locationsReducer, {
  initialState as locationsState
} from './locations/reducers';
import orderReducer, { initialState as orderState } from './order/reducers';
import userReducer, { initialState as userState } from './user/reducers';

export const initializeState = () => {
  return {
    searchState,
    restaurantState,
    addressState,
    cartState,
    customisationsState,
    locationsState,
    orderState,
    userState
  };
};

export default combineReducers({
  searchState: searchReducer,
  restaurantState: restaurantReducer,
  addressState: addressReducer,
  cartState: cartReducer,
  customisationsState: customisationsReducer,
  locationsState: locationsReducer,
  userState: userReducer,
  orderState: orderReducer,
  router: routerReducer
});
