import { cartModel } from '../../api';
import { Storage } from '../../util';

export const SET_CART = 'SET_CART';
export const SET_ITEM = 'SET_ITEM';
export const SET_CART_EMPTY = 'SET_CART_EMPTY';
export const SET_CART_FROM_STORAGE = 'SET_CART_FROM_STORAGE';
export const SET_ORDER_PLACED_RESPONSE = 'SET_ORDER_PLACED_RESPONSE';
export const SET_PROMO_CODE = 'SET_PROMO_CODE';
export const SET_CART_RESPONSE = 'SET_CART_RESPONSE';
export const SET_CART_ITEM = 'SET_CART_ITEM';
export const INCREASE_ITEM_QUANTITY = 'INCREASE_ITEM_QUANTITY';
export const DECREASE_ITEM_QUANTITY = 'DECREASE_ITEM_QUANTITY';
export const SET_CART_CALCULATIONS = 'SET_CART_CALCULATIONS';
export const SET_RESPONSE_STATUS = 'SET_RESPONSE_STATUS';
export const SET_CART_EXTRAS = 'SET_CART_EXTRAS';
export const SET_PROMOCODE_VALIDITY = 'SET_PROMOCODE_VALIDITY';
export const SET_CALCULATING_CART = 'SET_CALCULATING_CART';
export const SET_CALCULATING_UPDATED_CART = 'SET_CALCULATING_UPDATED_CART';
export const REPEAT_LAST_CUSTOMISED_CART_ITEM_QUANTITY =
  'REPEAT_LAST_CUSTOMISED_CART_ITEM_QUANTITY';

export const setCalculatingCart = calculatingCart => dispatch => {
  dispatch({
    type: SET_CALCULATING_CART,
    calculatingCart
  });
};

export const setCalculatingUpdatedCart = calculatingUpdatedCart => dispatch => {
  dispatch({
    type: SET_CALCULATING_UPDATED_CART,
    calculatingUpdatedCart
  });
};

export const getCartCalculations = () => (dispatch, getState) => {
  const state = getState().cartState;
  const { items, cartItems } = state;
  const cartObject = {
    totalItems: 0,
    totalAmount: 0
  };

  Object.keys(items).forEach(itemId => {
    const cartItemIds = items[itemId];
    cartItemIds.forEach(cartItemId => {
      const cartItem = cartItems[cartItemId];

      cartObject.totalItems += parseInt(cartItem.quantity, 10);
      cartObject.totalAmount += cartItem.quantity * cartItem.totalItemPrice;
    });
  });

  return cartObject;
};

export const getCartFinalCalculations = data => (dispatch, getState) => {
  return cartModel.getData(data).then(response => {
    const extras = response.extras ? response.extras : [];

    dispatch(
      setResponseStatus({
        responseCode: response.code,
        message: response.message
      })
    );
    dispatch(setCartResponse(response.order));
    dispatch(setCartExtras(extras));

    dispatch(
      setPromocodeValidity({
        loading: false,
        valid: response.promo ? response.promo.is_valid : false,
        errorMessage:
          response.promo && !response.promo.is_valid
            ? response.promo.message
            : '',
        successMessage:
          response.promo && response.promo.is_valid
            ? response.promo.message
            : ''
      })
    );

    Storage.setItem('cart', { ...getState().cartState });
  });
};

export const setCartResponse = cartResponse => dispatch => {
  dispatch({
    type: SET_CART_RESPONSE,
    cartResponse
  });
};

export const setCart = ({ resId, items, cartItems, timestamp }) => dispatch => {
  dispatch({
    type: SET_CART,
    resId,
    items,
    cartItems,
    timestamp: timestamp || 0
  });
};

export const getCartFromStorage = () => () => Storage.getItem('cart');

export const setCartFromStorage = ({ resId }) => dispatch => {
  return dispatch(getCartFromStorage()).then(cart => {
    if (!cart || !cart.resId) {
      return;
    }

    if (Number(cart.resId) === Number(resId)) {
      dispatch({
        type: SET_CART_FROM_STORAGE,
        cart
      });
    }
  });
};

export const setOrderPlacedResponse = ({ orderId, hashKey }) => dispatch => {
  dispatch({
    type: SET_ORDER_PLACED_RESPONSE,
    orderId,
    hashKey
  });
};

export const checkout = data => dispatch => {
  return cartModel.checkout(data).then(response => {
    if (response.success === 'success') {
      const orderId = response.data.order_id;
      const hashKey = response.response.hash_key;
      dispatch(setOrderPlacedResponse({ orderId, hashKey }));
    }
    return response;
  });
};

export const setResponseStatus = ({ responseCode, message }) => dispatch => {
  dispatch({
    type: SET_RESPONSE_STATUS,
    responseCode,
    message
  });
};

export const setCartExtras = extras => dispatch => {
  dispatch({
    type: SET_CART_EXTRAS,
    extras
  });
};

export const setPromocodeValidity = ({
  valid,
  errorMessage,
  successMessage,
  loading
}) => (dispatch, getState) => {
  const state = getState().cartState;
  const promocode = { ...state.promocode };

  dispatch({
    type: SET_PROMOCODE_VALIDITY,
    loading,
    valid,
    errorMessage,
    successMessage,
    promocode
  });
};

export const setCartCalculation = cartCalculations => (dispatch, getState) => {
  dispatch({
    type: SET_CART_CALCULATIONS,
    cartCalculations
  });

  Storage.setItem('cart', { ...getState().cartState }).then(() => {
    Storage.getItem('cart').then(value => {
      if (!value || value === 'undefined') {
        return;
      }

      const cartExpiryTime = 7200000;
      const currentTimestamp = Date.now();
      const timeDifference = currentTimestamp - value.timestamp;

      if (value.timestamp && timeDifference > cartExpiryTime) {
        dispatch(setCartEmpty());
      }
    });
  });
};

export const setItem = ({ resId, itemId }) => (dispatch, getState) => {
  const state = getState().cartState;
  const timeStamp = new Date().getTime();
  let cartItemIds;

  if (resId !== state.resId) {
    dispatch(
      setCart({
        resId,
        items: {},
        cartItems: {}
      })
    );
  }

  const items = { ...getState().cartState.items };

  if (items[itemId]) {
    cartItemIds = items[itemId];
  } else {
    cartItemIds = [];
  }

  cartItemIds.push(timeStamp);
  items[itemId] = cartItemIds;

  dispatch({
    type: SET_ITEM,
    items
  });

  Storage.setItem('cart', { ...getState().cartState });
};

export const setCartItem = ({
  itemId,
  selectedCustomisedItems,
  totalItemPrice
}) => (dispatch, getState) => {
  const state = getState().cartState;
  const items = { ...state.items };
  const cartItems = { ...state.cartItems };
  const timestamp = Date.now();
  if (items[itemId] && items[itemId].length) {
    const cartItemId = items[itemId][items[itemId].length - 1];
    cartItems[cartItemId] = {
      quantity: 1,
      totalItemPrice,
      customisations: selectedCustomisedItems || {}
    };
  }

  dispatch({
    type: SET_CART_ITEM,
    cartItems,
    timestamp
  });

  Storage.setItem('cart', { ...getState().cartState });
};

export const setCartEmpty = () => dispatch => {
  dispatch({
    type: SET_CART_EMPTY
  });

  Storage.removeItem('cart');
};

export const increaseCartItemQuantity = cartItemId => (dispatch, getState) => {
  const state = getState().cartState;

  const cartItems = { ...state.cartItems };
  cartItems[cartItemId].quantity += 1;

  dispatch({
    type: INCREASE_ITEM_QUANTITY,
    cartItems
  });

  Storage.setItem('cart', { ...getState().cartState });
};

export const increaseItemQuantity = itemId => (dispatch, getState) => {
  const state = getState().cartState;
  if (state.items[itemId]) {
    const cartItems = { ...state.cartItems };
    const items = { ...state.items };
    const cartItemId = items[itemId];
    cartItems[cartItemId].quantity += 1;

    dispatch({
      type: INCREASE_ITEM_QUANTITY,
      cartItems
    });

    Storage.setItem('cart', { ...getState().cartState });
  }
};

export const decreaseCartItemQuantity = (itemId, cartItemId) => (
  dispatch,
  getState
) => {
  const state = getState().cartState;
  const itemIdIndex = state.items[itemId].indexOf(cartItemId);
  const cartItems = { ...state.cartItems };
  const items = { ...state.items };
  cartItems[cartItemId].quantity -= 1;
  if (cartItems[cartItemId].quantity === 0) {
    delete cartItems[cartItemId];
    if (items[itemId].length > 1) {
      items[itemId].splice(itemIdIndex, 1);
    } else {
      delete items[itemId];
    }
  }

  dispatch({
    type: DECREASE_ITEM_QUANTITY,
    items,
    cartItems
  });

  Storage.setItem('cart', { ...getState().cartState });
};

export const showDiscountSuggestion = () => (dispatch, getState) => {
  const { offer } = getState().restaurantState;
  return offer && offer.type === 'Discount' && !dispatch(isOfferApplicable());
};

export const isOfferApplicable = () => (dispatch, getState) => {
  const { offer } = getState().restaurantState;
  const { cartCalculations } = getState().cartState;
  const finalAmount = cartCalculations.totalAmount;

  if (
    offer &&
    offer.type === 'Discount' &&
    (!offer.min_order_amount || finalAmount >= offer.min_order_amount)
  ) {
    return true;
  }
  return false;
};

export const offerSuggestion = () => (dispatch, getState) => {
  const { offer } = getState().restaurantState;

  if (dispatch(diffAmountForDiscount()) && offer && offer.sub_text) {
    return `Add ${dispatch(diffAmountForDiscount())} more to get ${
      offer.sub_text
    }`;
  }
  return '';
};

export const diffAmountForDiscount = () => (dispatch, getState) => {
  const { offer, restaurantInfo } = getState().restaurantState;
  const { cartCalculations } = getState().cartState;

  if (!offer || !offer.min_order_amount || !cartCalculations.totalAmount) {
    return null;
  }
  const diff = offer.min_order_amount - cartCalculations.totalAmount;
  return dispatch(
    getAmountStringWithCurrency(
      restaurantInfo.currency,
      diff,
      restaurantInfo.currency_affix
    )
  );
};

export const originalTotalAmount = () => (dispatch, getState) => {
  const { offer, restaurantInfo } = getState().restaurantState;
  const { cartCalculations } = getState().cartState;
  const originalTotalAmount = cartCalculations.totalAmount;
  const finalAmount = dispatch(getDiscountedAmount(offer, originalTotalAmount));

  if (
    !dispatch(showPrice()) ||
    !dispatch(isOfferApplicable()) ||
    originalTotalAmount === finalAmount
  ) {
    return '';
  }
  return dispatch(
    getAmountStringWithCurrency(
      restaurantInfo.currency,
      originalTotalAmount.toFixed(2),
      restaurantInfo.currency_affix
    )
  );
};

export const getDiscountedAmount = (amount, checkApplicability = true) => (
  dispatch,
  getState
) => {
  const { offer } = getState().restaurantState;
  if (
    !offer ||
    !offer.discount_percentage ||
    (checkApplicability && !dispatch(isOfferApplicable(amount)))
  ) {
    return amount;
  }
  return amount * (1.0 - offer.discount_percentage);
};

export const getAmountStringWithCurrency = (
  currency,
  amount,
  currencyAffix
) => (dispatch, getState) => {
  if (currencyAffix === 'prefix') {
    return currency + amount.toString();
  }
  return amount.toString() + currency;
};

export const showPrice = () => (dispatch, getState) => {
  const { restaurantInfo } = getState().restaurantState;
  const { cartCalculations } = getState().cartState;

  return (
    restaurantInfo.currency_affix &&
    restaurantInfo.currency &&
    cartCalculations.totalAmount
  );
};

export const finalTotalAmount = () => (dispatch, getState) => {
  const { restaurantInfo } = getState().restaurantState;
  const { cartCalculations } = getState().cartState;

  if (!dispatch(showPrice())) {
    return 'calculating...';
  }

  const finalAmount = dispatch(
    getDiscountedAmount(cartCalculations.totalAmount)
  );

  return dispatch(
    getAmountStringWithCurrency(
      restaurantInfo.currency,
      finalAmount.toFixed(2),
      restaurantInfo.currency_affix
    )
  );
};

export const minOrderAlert = () => (dispatch, getState) => {
  const { restaurantInfo } = getState().restaurantState;
  const { cartCalculations } = getState().cartState;

  if (
    restaurantInfo.delivery_info &&
    restaurantInfo.delivery_info.min_order > cartCalculations.totalAmount
  ) {
    const minOrderDisplay = dispatch(
      getAmountStringWithCurrency(
        restaurantInfo.currency,
        restaurantInfo.delivery_info.min_order.toFixed(2),
        restaurantInfo.currency_affix
      )
    );

    return `Minimum order ${minOrderDisplay}`;
  }
  return '';
};

export const setPromocode = code => (dispatch, getState) => {
  const state = getState().cartState;
  const promocode = { ...state.promocode };
  promocode.loading = true;
  promocode.code = code;
  promocode.valid = false;
  promocode.errorMessage = '';
  promocode.successMessage = '';

  dispatch({
    type: SET_PROMO_CODE,
    promocode
  });
};

export const repeatLastCustomisedCartItemQuantity = itemId => (
  disptach,
  getState
) => {
  const state = getState().cartState;
  if (state.items[itemId] && state.items[itemId].length) {
    const cartItems = { ...state.cartItems };
    const items = { ...state.items };
    const cartItemId = items[itemId][items[itemId].length - 1];
    cartItems[cartItemId].quantity += 1;

    disptach({
      type: REPEAT_LAST_CUSTOMISED_CART_ITEM_QUANTITY,
      cartItems
    });

    Storage.setItem('cart', { ...getState().cartState });
  }
};

export const buildCartObject = () => (dispatch, getState) => {
  const { items } = getState().cartState;

  let dishes = [];
  Object.keys(items).forEach(itemId => {
    const items = dispatch(getCartItemInfo(itemId));
    dishes = [...dishes, ...items];
  });

  return dishes;
};

export const getCartItemInfo = itemId => (dispatch, getState) => {
  const { items } = getState().cartState;
  const restaurantMenuItems = { ...getState().restaurantState.items };
  const cart_items = [];
  const restaurantItem = restaurantMenuItems[itemId];
  const cartItems = items[itemId];

  cartItems.forEach(cartItemId => {
    cart_items.push(dispatch(setItemsObject(restaurantItem, cartItemId)));
  });

  return cart_items;
};

export const setItemsObject = (restaurantItem, cartItemId) => (
  dispatch,
  getState
) => {
  const { cartItems } = getState().cartState;
  const cartItem = cartItems[cartItemId];
  const item = {
    type: 'dish',
    comment: '',
    groups: dispatch(getGroupsInfo(cartItem)),
    item_id: restaurantItem.item_id,
    item_name: restaurantItem.name,
    mrp_item: restaurantItem.mrp_item,
    quantity: cartItem.quantity,
    tags: restaurantItem.tags_id,
    tax_inclusive: restaurantItem.tax_inclusive,
    unit_cost: cartItem.totalItemPrice,
    total_cost: calculateItemTotalCost(
      cartItem.totalItemPrice,
      cartItem.quantity
    ),
    is_bogo_active: '',
    bogoItemsCount: '',
    alwaysShowOnCheckout: restaurantItem.always_show_on_checkout
  };
  if (restaurantItem.item_metadata) {
    item.item_metadata = restaurantItem.item_metadata;
  }
  return item;
};

export const getGroupsInfo = cartItem => dispatch => {
  const groups = [];
  const customisedGroupIds = Object.keys(cartItem.customisations);
  if (customisedGroupIds.length) {
    customisedGroupIds.forEach(customisedGroupId => {
      groups.push(dispatch(setGroupObject(cartItem, customisedGroupId)));
    });
  }
  return groups;
};

export const setGroupObject = (cartItem, customisedGroupId) => (
  dispatch,
  getState
) => {
  const { groups } = getState().restaurantState;
  const restaurantGroup = groups[customisedGroupId];
  const customisedGroupItemIds = cartItem.customisations[customisedGroupId];
  const group = {
    id: restaurantGroup.group_id,
    name: restaurantGroup.name,
    label: restaurantGroup.label,
    min: restaurantGroup.min,
    max: restaurantGroup.max,
    parent_menu_id: restaurantGroup.parent_menu_id,
    parent_visiblity: restaurantGroup.parent_visiblity,
    hasFocus: restaurantGroup.hasFocus,
    items: dispatch(getGroupItemInfo(customisedGroupItemIds)),
    show_customisation: restaurantGroup.show_customisation
  };
  return group;
};

export const getGroupItemInfo = customisedGroupItemIds => dispatch => {
  const groupItems = [];
  customisedGroupItemIds.forEach(customisedGroupItemId => {
    groupItems.push(dispatch(setGroupItemObject(customisedGroupItemId)));
  });
  return groupItems;
};

export const setGroupItemObject = customisedGroupItemId => (
  dispatch,
  getState
) => {
  const { groupItems } = getState().restaurantState;
  const restaurantGroupItem = groupItems[customisedGroupItemId];
  const groupItem = {
    type: 'dish',
    comment: '',
    item_id: restaurantGroupItem.item_id,
    item_name: restaurantGroupItem.name,
    mrp_item: restaurantGroupItem.price,
    quantity: 1,
    tags: '',
    tax_inclusive: restaurantGroupItem.tax_inclusive,
    unit_cost: restaurantGroupItem.price,
    total_cost: calculateItemTotalCost(restaurantGroupItem.price, 1)
  };
  if (restaurantGroupItem.item_metadata) {
    groupItem.item_metadata = restaurantGroupItem.item_metadata;
  }
  return groupItem;
};

export const setPaymentStatusInStorage = paymentStatusObject => {
  return Storage.setItem('paymentStatus', paymentStatusObject);
};

export const getPaymentStatusFromStorage = () => {
  return Storage.getItem('paymentStatus');
};

export const calculateItemTotalCost = (price, quantity) => {
  return price * quantity;
};

export const removeOutOfStockItemsFromCart = outOfStockItemIds => (
  dispatch,
  getState
) => {
  const state = { ...getState().cartState };
  outOfStockItemIds.forEach(outOfStockItemId => {
    const cartItemIds = state.items[outOfStockItemId];
    cartItemIds.forEach(cartItemId => {
      delete state.cartItems[cartItemId];
    });
    delete state.items[outOfStockItemId];
  });
  Storage.setItem('cart', { ...getState().cartState });
  dispatch(setCart({ ...getState().cartState }));
};
