import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { Address } from './../../components/address';

import { selectLocation } from '../../store/locations/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { addresses } = state.addressState;
      const address = addresses[ownProps.addressId];
      return { address };
    },
    {
      selectLocation
    }
  )(Address)
);
