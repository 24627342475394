import React, { Component } from 'react';

export default class UiBackdrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  render() {
    const { show } = this.props;

    if (!show) {
      return null;
    }

    return <div style={backdrop} />;
  }
}

const backdrop = {
  backgroundColor: 'rgba(0,0,0,.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 10
};
