import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { SelectAddress } from '../../components/pages';

export default withRouter(
  connect((state, ownProps) => {
    const resId = ownProps.match.params.resId;
    return { resId };
  })(SelectAddress)
);
