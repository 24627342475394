import React, { Component } from 'react';

const styles = {
  UiGrid: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#f9f9f9'
  }
};

export default class UiGrid extends Component {
  copyToClipboard() {}

  render() {
    return <div style={styles.UiGrid}>{this.props.children}</div>;
  }
}
