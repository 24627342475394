import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import CustomisationsRadio from '../../components/restaurant/CustomisationsRadio';

import { selectRadioItem } from '../../store/customisations/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { items } = state.restaurantState;
      const groupId = ownProps.groupId;
      return { items, groupId };
    },
    { selectRadioItem }
  )(CustomisationsRadio)
);
