import React, { Component } from 'react';

import {
  UiView,
  UiRow,
  UiColumn,
  UiScroller,
  UiCell
} from './../../dependencies/zomato-ui';

import CartItems from '../../containers/cart/CartItemsContainer';

export default class ChangeCustomisedItemQuantityPopup extends Component {
  onItemRemove() {
    if (this.props.cartItemQuantity === 1) {
      this.props.history.goBack();
    }
  }

  render() {
    const { resId, itemId } = this.props;
    return (
      <UiView>
        <UiScroller height={200}>
          <UiCell padded>
            <UiRow marginTop>
              <UiColumn doublePadded>
                <CartItems
                  resId={resId}
                  itemId={itemId}
                  onItemRemove={this.onItemRemove.bind(this)}
                />
              </UiColumn>
            </UiRow>
          </UiCell>
        </UiScroller>
      </UiView>
    );
  }
}
