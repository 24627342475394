import React, { Component } from 'react';

import {
  UiView,
  UiChip,
  UiRow,
  UiColumn
} from './../../../dependencies/zomato-ui';

export default class QuickFilter extends Component {
  onChange(selectedList) {
    let filters = [];
    Object.keys(selectedList).map(key => (filters = [...filters, key]));
    this.props.setSelectedFilters(filters);
  }

  render() {
    const { getCategoriesList, selectedFilters } = this.props;
    return (
      <UiView>
        <UiRow>
          <UiColumn>
            <UiChip
              selectedList={selectedFilters}
              list={getCategoriesList('other')}
              valueKey="value"
              onChange={this.onChange.bind(this)}
            />
          </UiColumn>
        </UiRow>
      </UiView>
    );
  }
}
