import React, { Component } from 'react';

import {
  UiView,
  UiRow,
  UiColumn,
  UiCell,
  UiTextInput,
  List,
  UiIcon
} from './../../dependencies/zomato-ui';

import RestaurantMenuItem from '../../containers/restaurant/RestaurantMenuItemContainer';

import { getPageHeight } from '../../dependencies/modules/util';

export default class MenuItemSearch extends Component {
  state = {
    searchedItems: null,
    defaultHeight: 120,
    height: 120,
    timeout: null
  };

  onChange(event) {
    const { items } = this.props;
    const searchedItems = [];
    const allItems = Object.values(items);
    const inputVal = event.target.value.toLowerCase();
    if (!inputVal || inputVal.length <= 1) {
      this.setState({
        searchedItems: null,
        height: this.state.defaultHeight
      });
      return;
    }
    allItems.forEach(item => {
      if (item.name.toLowerCase().indexOf(inputVal) > -1) {
        searchedItems.push(item);
      }
    });
    this.setState({
      searchedItems: searchedItems
    });
  }

  render() {
    const { searchedItems } = this.state;

    return (
      <UiView>
        <UiRow>
          <UiColumn doublePadded>
            <UiCell borderBottom="default" paddingBottom>
              <UiRow>
                <UiColumn nonPadded>
                  <UiIcon
                    color={'#b8c2cb'}
                    fontSize={15}
                    icon={'fa fa-search'}
                  />
                </UiColumn>
                <UiColumn nonPadded flex={14}>
                  <UiTextInput
                    placeholder="Enter dish name"
                    onChange={this.onChange.bind(this)}
                  />
                </UiColumn>
              </UiRow>
            </UiCell>
          </UiColumn>
        </UiRow>
        <UiView>
          {searchedItems && searchedItems.length ? (
            <List
              maxHeight={getPageHeight() - 200}
              scrollable
              data={searchedItems}
              renderItem={item => (
                <UiRow>
                  <UiColumn doublePadded>
                    <RestaurantMenuItem item={item} resId={this.props.resId} />
                  </UiColumn>
                </UiRow>
              )}
            />
          ) : null}
        </UiView>
      </UiView>
    );
  }
}
