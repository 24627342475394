import React, { Component } from 'react';

import {
  UiRow,
  UiColumn,
  UiHeading,
  UiView,
  UiCell,
  UiButton,
  UiDivider
} from './../../../dependencies/zomato-ui';

import {
  QuickFilter,
  SelectCuisineFilter
} from '../../../containers/home/search';

export default class Filters extends Component {
  onApplyButtonClick() {
    this.props.applySelectedCuisine();
    this.props.applySelectedFilters();
    this.props.hidePopup();
  }

  render() {
    const { isButtonEnabled } = this.props;
    return (
      <UiView>
        <UiRow>
          <UiColumn doublePadded>
            <UiCell padded>
              <UiHeading size="medium">Quick Filters</UiHeading>
            </UiCell>
          </UiColumn>
        </UiRow>
        <UiRow>
          <UiColumn>
            <QuickFilter />
          </UiColumn>
        </UiRow>
        <UiRow marginTop>
          <UiColumn>
            <UiDivider />
          </UiColumn>
        </UiRow>
        <UiRow>
          <UiColumn doublePadded>
            <UiCell padded>
              <UiHeading size="medium">Cuisines</UiHeading>
            </UiCell>
          </UiColumn>
        </UiRow>
        <UiRow>
          <UiColumn>
            <SelectCuisineFilter />
          </UiColumn>
        </UiRow>
        <UiView style={styles.addFilterBtnContainer}>
          <UiButton
            style={styles.addFilterBtn}
            size="large"
            title="Apply"
            type="primary"
            fluid="true"
            variant="contained"
            color={isButtonEnabled ? 'blue' : 'paytmGrey'}
            onPress={this.onApplyButtonClick.bind(this)}
          />
        </UiView>
      </UiView>
    );
  }
}

const styles = {
  addFilterBtnContainer: {
    flex: 1,
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center'
  },
  addFilterBtn: {
    flex: 1,
    borderRadius: 10,
    alignItems: 'center'
  }
};
