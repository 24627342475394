import { Fetch } from './helpers';

export default {
  getData(params) {
    if (!params || !params.deliverySubzoneId) {
      return Promise.reject('Required field: deliverySubzoneId');
    }

    const body = {
      rendered_restaurants: params.renderedRestaurants
    };
    const {
      deliverySubzoneId,
      sort,
      latitude,
      longitude,
      cuisine,
      others
    } = params;
    const queryParams = {
      deliverySubzoneId,
      sort: params.sort ? sort : 'popular',
      latitude: params.latitude ? latitude : 0,
      longitude: params.longitude ? longitude : 0,
      ...(cuisine && { cuisine }),
      ...(others && { ...others })
    };
    const path = 'order/home';

    return Fetch.post({ path, queryParams }, { body: JSON.stringify(body) });
  },
  getkeywordsSearchResults(params) {
    if (!params || !params.deliverySubzoneId || !params.query) {
      return Promise.reject('Required field: deliverySubzoneId, query');
    }

    const { deliverySubzoneId, latitude, longitude, query } = params;
    const queryParams = {
      deliverySubzoneId,
      latitude: params.latitude ? latitude : 0,
      longitude: params.longitude ? longitude : 0,
      query
    };
    const path = '/order/search/keyword';

    return Fetch.get({ path, queryParams });
  }
};
