import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { Customisations } from '../../components/pages';
import { clearCustomisations } from '../../store/customisations/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { items } = state.restaurantState;
      const itemId = ownProps.match.params.itemId;
      const resId = ownProps.match.params.resId;
      const menuItem = items[itemId];
      return { menuItem, itemId, resId };
    },
    {
      clearCustomisations
    }
  )(Customisations)
);
