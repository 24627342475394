import Flipkart from './partners/Flipkart';

export const getPageHeight = () => {
  return window.windowHeight ? window.windowHeight : window.innerHeight;
};

export const getUrlParams = () => {
  let urlParams = {};
  const search = window.location.search.substring(1);

  if (search) {
    urlParams = JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
  }

  urlParams.partner_client_id = urlParams.partner_client_id
    ? urlParams.partner_client_id
    : Flipkart.CLIENT_ID;

  return urlParams;
};
