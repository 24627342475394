import React, { Component } from 'react';
import { colors } from '../../dependencies/zomato-ui/util/Theme';

import {
  UiRow,
  UiColumn,
  UiView,
  UiHeading,
  UiIcon,
  UiCell
} from '../../dependencies/zomato-ui';

import { Addresses } from '../../containers/address';
import AppHeader from '../common/AppHeader';
import { getPageHeight } from '../../dependencies/modules/util';

export default class SelectAddress extends Component {
  addAddress() {
    const { history, resId } = this.props;
    history.push(`/add-address/${resId}`);
  }

  render() {
    const { resId } = this.props;
    return (
      <UiView>
        <AppHeader
          iconColor={'#999999'}
          iconSize={18}
          height={20}
          scrollerHeight={getPageHeight() - 50}
          showHeaderTitle
          showBackButton
          onBack={() => this.props.history.goBack()}
          title={'Delivery Address'}
        >
          <UiCell marginTop padded paddedBorder borderBottom="default">
            <UiHeading size="huge">Saved Addresses</UiHeading>
          </UiCell>
          <UiCell
            margin
            padded
            paddedBorder
            feedback
            borderBottom="default"
            onClick={this.addAddress.bind(this)}
          >
            <UiRow marginTop>
              <UiColumn nonPadded>
                <UiIcon
                  color={colors['anchor']['primary']}
                  fontSize={14}
                  icon={'fa fa-plus'}
                />
              </UiColumn>
              <UiColumn flex={15} nonPadded>
                <UiHeading type="anchor">Add Address</UiHeading>
              </UiColumn>
            </UiRow>
          </UiCell>
          <Addresses resId={resId} />
        </AppHeader>
      </UiView>
    );
  }
}
