import * as Sentry from '@sentry/browser';

export default class SentryErrorReporting {
  static initialize(dsnPath) {
    Sentry.init({
      dsn: dsnPath
    });
  }

  static captureMessage(errorDetails) {
    Sentry.captureEvent({
      message: errorDetails.message,
      stacktrace: [errorDetails.stack]
    });
  }

  static setUser(user) {
    if (user && user.guest_id) {
      Sentry.configureScope(scope => {
        scope.setTag('guest_id', user.guest_id);
        scope.setUser({
          id: user.guest_id,
          userAgent: navigator.userAgent
        });
      });
    }
  }
}
