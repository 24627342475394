import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { AddressMap } from '../../components/pages';

import { addUserAddress } from '../../store/address/actions';
import {
  selectLocation,
  clearLocations,
  clearSelectedLocationForAddress
} from '../../store/locations/actions';
import { getUserDetails } from '../../store/user/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { newAddressData } = state.addressState;
      return { newAddressData };
    },
    {
      addUserAddress,
      selectLocation,
      clearLocations,
      getUserDetails,
      clearSelectedLocationForAddress
    }
  )(AddressMap)
);
