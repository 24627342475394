import React, { Component } from 'react';

import {
  UiRow,
  UiColumn,
  UiView,
  UiHeading,
  UiCell
} from '../../dependencies/zomato-ui';

import CustomisationsGroup from '../../containers/restaurant/CustomisationsGroupContainer';
import AddCustomisedItemButton from '../../containers/restaurant/AddCustomisedItemButtonContainer';

export default class Customisations extends Component {
  goBack() {
    this.props.history.goBack();
  }

  componentWillUnmount() {
    this.props.clearCustomisations();
  }

  render() {
    const { menuItem, resId } = this.props;
    const itemId = menuItem.item_id;

    return (
      <UiView style={{ flex: 1, paddingBottom: 70 }}>
        <UiView>
          <UiRow>
            <UiColumn>
              <UiRow>
                <UiColumn>
                  <UiCell margin padded onClick={this.goBack.bind(this)}>
                    <UiHeading type="anchor" color="tertiary" size="big">
                      Close
                    </UiHeading>
                  </UiCell>
                </UiColumn>
              </UiRow>
            </UiColumn>
          </UiRow>
        </UiView>
        {itemId && (
          <UiView>
            <UiRow>
              <UiColumn>
                <UiRow>
                  <UiColumn>
                    <UiHeading size="large">{menuItem.name}</UiHeading>
                  </UiColumn>
                </UiRow>
              </UiColumn>
            </UiRow>
          </UiView>
        )}
        {itemId &&
          menuItem.allGroups.map((groupId, index) => (
            <CustomisationsGroup
              key={`${groupId}_${index}`}
              resId={resId}
              itemId={itemId}
              groupId={groupId}
            />
          ))}
        <AddCustomisedItemButton itemId={itemId} resId={resId} />
      </UiView>
    );
  }
}
