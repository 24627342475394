import React, { Component } from 'react';
import { WebView } from '../../dependencies/zomato-ui';

export default class Payment extends Component {
  render() {
    const { uuid, hashKey } = this.props;
    return (
      <WebView
        title="PaymentGateWay"
        src={`https://zomato.com/o2-third-party-order-payment.php?uuid=${uuid}&order_id_hash=${hashKey}&pwastaging=aa113`}
      />
    );
  }
}
