import React, { Component } from 'react';

import { UiView, WebView } from './../../dependencies/zomato-ui';

import { generateApiUrl } from '../../api/helpers';

export default class MapWebView extends Component {
  webViewUrl() {
    const { selectedLocationForAddress, currentLatLong } = this.props;

    const url = {
      path: 'webview/order_sdk/address_map',
      queryParams: {
        dsz_id: selectedLocationForAddress
          ? selectedLocationForAddress.place.place_id
          : 1,
        latitude:
          selectedLocationForAddress &&
          selectedLocationForAddress.place.latitude
            ? selectedLocationForAddress.place.latitude
            : 0,
        longitude:
          selectedLocationForAddress &&
          selectedLocationForAddress.place.longitude
            ? selectedLocationForAddress.place.longitude
            : 0,
        current_latitude:
          currentLatLong && currentLatLong.latitude
            ? currentLatLong.latitude
            : 0,
        current_longitude:
          currentLatLong && currentLatLong.longitude
            ? currentLatLong.longitude
            : 0
      }
    };
    return `https://www.zomato.com/${generateApiUrl(url)}`;
  }

  componentDidMount() {
    window.addEventListener('message', this.props.onMapCenterChange);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.props.onMapCenterChange);
  }

  render() {
    return (
      <UiView style={styles.mapView}>
        <WebView title="webview" src={this.webViewUrl()} />
      </UiView>
    );
  }
}

const styles = {
  mapView: {
    borderRadius: 12
  }
};
