import { connect } from 'react-redux';
import { withRouter } from '../../../dependencies/modules/react-router-native';
import { KeywordSearchInput } from './../../../components/home/search';

import {
  setLoading,
  setError,
  getkeywordsSearchResults
} from '../../../store/search/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { error, restaurantList } = state.searchState.searchResults;
      const { selectedLocation } = state.locationsState;
      const setSearchResultsVisibility = ownProps.setSearchResultsVisibility;
      return {
        selectedLocation,
        setSearchResultsVisibility,
        error,
        restaurantList
      };
    },
    {
      setLoading,
      setError,
      getkeywordsSearchResults
    }
  )(KeywordSearchInput)
);
