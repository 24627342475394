import React, { Component } from 'react';

import { UiView, List, UiOverlay } from './../../../dependencies/zomato-ui';

import RestaurantCard from '../../../containers/home/restaurants/RestaurantCardContainer';
import SearchResultStatus from '../../../containers/home/restaurants/SearchResultStatusContainer';
import ErrorPage from '../../common/ErrorPage';

import { debounce, deepCompare } from '../../../util';

import { getPageHeight } from '../../../dependencies/modules/util';

export default class RestaurantList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastSearch: null,
      error: null,
      fetchingRestaurants: false,
      loadMoreFailureCount: 0,
      refreshing: false,
      height: getPageHeight() - 104
    };

    this.refreshSearch = debounce(this.searchRestaurants, 200);
  }

  componentDidMount() {
    const { searchedLocation, selectedLocation } = this.props;

    if (
      selectedLocation &&
      selectedLocation.place &&
      selectedLocation.place.place_id &&
      searchedLocation.delivery_subzone_id ===
        Number(selectedLocation.place.place_id)
    ) {
      return;
    }

    this.refreshSearch();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.appliedFilters !== this.props.appliedFilters ||
      prevProps.selectedSorting !== this.props.selectedSorting
    ) {
      this.refreshSearch();
    }
  }

  setAppliedCuisine(dataObj) {
    const { appliedCuisine } = this.props;
    const data = dataObj;
    if (appliedCuisine) {
      data.cuisine = appliedCuisine.value;
    }
  }

  setAppliedFilters(dataObj) {
    const { appliedFilters } = this.props;
    const data = dataObj;
    if (appliedFilters && appliedFilters.length) {
      data.others = {};
      appliedFilters.forEach(filter => {
        data.others[filter] = 1;
      });
    }
  }

  setAppliedSorting(dataObj) {
    const { selectedSorting } = this.props;
    const data = dataObj;
    if (selectedSorting) {
      data.sort = selectedSorting.value;
    }
  }

  setRenderedRestaurants(dataObj, renderedRestaurants) {
    const data = dataObj;
    if (renderedRestaurants && renderedRestaurants.length) {
      data.renderedRestaurants = renderedRestaurants;
    }
  }

  searchRestaurants(isRefreshing, renderedRestaurants) {
    const { selectedLocation } = this.props;
    const { error, lastSearch } = this.state;
    let { loadMoreFailureCount } = this.state;
    if (!selectedLocation) {
      return;
    }

    const { place } = selectedLocation;
    const data = {
      deliverySubzoneId:
        place.place_type === 'DSZ' ? place.place_id : place.delivery_subzone_id,
      latitude: place.latitude ? place.latitude : 0,
      longitude: place.longitude ? place.longitude : 0,
      sort: 'popular'
    };

    this.setAppliedCuisine(data);
    this.setAppliedFilters(data);
    this.setAppliedSorting(data);
    this.setRenderedRestaurants(data, renderedRestaurants);

    if (
      !error &&
      !isRefreshing &&
      lastSearch &&
      deepCompare(data, lastSearch)
    ) {
      return;
    }

    if (isRefreshing) {
      this.setState({
        refreshing: true,
        error: null,
        lastSearch: { ...data }
      });
    } else if (!renderedRestaurants || !renderedRestaurants.length) {
      this.setState({
        error: null,
        fetchingRestaurants: true,
        loadMoreFailureCount: 0,
        lastSearch: { ...data }
      });
    }

    this.props
      .getRestaurants(data)
      .then(() => {
        this.setState({
          fetchingRestaurants: false,
          refreshing: false
        });
      })
      .catch(error => {
        if (!renderedRestaurants || !renderedRestaurants.length) {
          this.setState({
            fetchingRestaurants: false,
            refreshing: false,
            loadMoreFailureCount: 0,
            error: error
          });
        } else {
          this.setState({
            fetchingRestaurants: false,
            loadMoreFailureCount: loadMoreFailureCount++,
            refreshing: false
          });
          if (loadMoreFailureCount <= 3) {
            this.refreshSearch(false, this.props.renderedRestaurants);
          }
        }
      });
  }

  onScrollToBottom() {
    if (this.props.hasMore) {
      this.refreshSearch(false, this.props.renderedRestaurants);
    }
  }

  onRefresh() {
    this.refreshSearch(true);
  }

  renderRestaurantList() {
    const { error, refreshing } = this.state;
    const { show, restaurantList, hasMore, height } = this.props;

    let errorState = error;
    if (restaurantList && !restaurantList.length) {
      errorState = { status: 'empty' };
    }

    if (error || (restaurantList && !restaurantList.length)) {
      return (
        <UiView style={{ height: 'inherit' }}>
          {errorState && errorState.status === 'empty' && (
            <SearchResultStatus />
          )}
          <ErrorPage error={errorState} tryAgain={() => this.refreshSearch()} />
        </UiView>
      );
    }

    if (!show || !restaurantList || !restaurantList.length) {
      return false;
    }

    return (
      <List
        refreshing={refreshing}
        scrollable
        height={height}
        data={restaurantList}
        onRefresh={this.onRefresh.bind(this)}
        onEndReached={this.onScrollToBottom.bind(this)}
        renderItem={(resId, index) => {
          return (
            <UiView>
              {!index && <SearchResultStatus />}
              <RestaurantCard resId={resId} type="outlet" />
              {index === restaurantList.length - 1 && hasMore && (
                <UiOverlay show={true} />
              )}
            </UiView>
          );
        }}
      />
    );
  }

  render() {
    const { show } = this.props;
    const { fetchingRestaurants, height } = this.state;

    if (!show) {
      return false;
    }

    return (
      <UiView style={{ height: height }}>
        {fetchingRestaurants ? (
          <UiOverlay show={true} />
        ) : (
          this.renderRestaurantList()
        )}
      </UiView>
    );
  }
}
