import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import MenuItemSearch from './../../components/restaurant/MenuItemSearch';

export default withRouter(
  connect((state, ownProps) => {
    const { items } = state.restaurantState;
    return { items };
  })(MenuItemSearch)
);
