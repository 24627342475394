import React, { Component } from 'react';

import {
  UiText,
  UiView,
  UiRow,
  UiColumn,
  UiHeading,
  UiCell,
  UiDivider
} from './../../dependencies/zomato-ui';

export default class PersonalDetails extends Component {
  render() {
    const { mobileNumber, name } = this.props;
    return (
      <UiView>
        <UiRow>
          <UiColumn>
            <UiCell marginTop>
              <UiRow>
                <UiColumn nonPadded>
                  <UiText color="secondary">PERSONAL DETAILS</UiText>
                </UiColumn>
              </UiRow>
              <UiRow margin>
                <UiHeading size="big">
                  {name}, {mobileNumber}
                </UiHeading>
              </UiRow>
            </UiCell>
          </UiColumn>
        </UiRow>
        <UiRow>
          <UiColumn>
            <UiDivider />
          </UiColumn>
        </UiRow>
      </UiView>
    );
  }
}
