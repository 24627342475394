import React, { Component } from 'react';

import {
  UiRow,
  UiColumn,
  UiHeading,
  UiView,
  UiCell,
  List
} from './../../dependencies/zomato-ui';

import RestaurantCategory from './../../containers/restaurant/RestaurantCategoryContainer';

export default class RestaurantMenu extends Component {
  render() {
    const { menu, resId } = this.props;

    if (!menu.menu_id) {
      return null;
    }

    return (
      <UiView>
        <UiRow marginTop>
          <UiColumn doublePadded>
            <UiCell marginTop padded borderBottom="default">
              <UiRow margin>
                <UiColumn nonPadded>
                  <UiHeading size="huge">{menu.name}</UiHeading>
                </UiColumn>
              </UiRow>
            </UiCell>
          </UiColumn>
        </UiRow>
        {menu.categories && (
          <List
            data={menu.categories}
            renderItem={categoryId => (
              <RestaurantCategory
                key={categoryId}
                resId={resId}
                categoryId={categoryId}
                showCategoryName={menu.categories.length > 1}
              />
            )}
          />
        )}
      </UiView>
    );
  }
}
