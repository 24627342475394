import React, { Component } from 'react';
import { colors } from '../../dependencies/zomato-ui/util/Theme';

import {
  UiView,
  UiHeading,
  UiRow,
  UiColumn
} from '../../dependencies/zomato-ui';

export default class AddCustomisedItemButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalItemPrice: 0,
      isButtonEnabled: true
    };
  }

  componentDidMount() {
    this.calculateTotalPrice();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedCustomisedItems !== this.props.selectedCustomisedItems
    ) {
      this.calculateTotalPrice();
      this.validateCustomisations();
    }
  }

  calculateTotalPrice() {
    const { selectedCustomisedItems, menuItem, groupItems } = this.props;
    let totalItemPrice = menuItem.price;

    if (!Object.keys(selectedCustomisedItems).length) {
      this.setState({
        totalItemPrice: totalItemPrice
      });
      return;
    }

    Object.keys(selectedCustomisedItems).forEach(groupId => {
      const itemIds = selectedCustomisedItems[groupId];
      itemIds.forEach(groupItemId => {
        const groupItem = groupItems[groupItemId];
        totalItemPrice += groupItem.price;
      });
    });
    this.setState({
      totalItemPrice: totalItemPrice
    });
  }

  validateCustomisations() {
    const {
      selectedCustomisedItems,
      selectedDependencies,
      menuItem,
      groups
    } = this.props;

    let isButtonEnabled = true;
    let totalMinGroupSelections = 0;

    menuItem.groups.forEach(groupId => {
      const group = groups[groupId];
      if (
        group.parentItemId &&
        !selectedDependencies.includes(group.parentItemId)
      ) {
        return;
      }
      const selectedGroupItems = selectedCustomisedItems[groupId];
      totalMinGroupSelections += group.min;
      if (
        group.min &&
        (!selectedGroupItems || selectedGroupItems.length < group.min)
      ) {
        isButtonEnabled = false;
      }
    });
    if (!totalMinGroupSelections) {
      isButtonEnabled = true;
    }

    this.setState({
      isButtonEnabled: isButtonEnabled
    });
  }

  onClick() {
    const { selectedCustomisedItems, itemId, resId, menuItem } = this.props;

    if (!this.state.isButtonEnabled) {
      return;
    }

    this.props.setItem({ resId, itemId });
    this.props.setCartItem({
      itemId,
      selectedCustomisedItems,
      totalItemPrice: this.state.totalItemPrice,
      isBogoActive: menuItem.is_bogo_active
    });

    this.props.history.goBack();
  }

  totalItemOriginalPrice() {
    const { restaurantInfo } = this.props;
    if (!this.state.totalItemPrice) {
      return null;
    }
    return this.props.getAmountStringWithCurrency(
      restaurantInfo.currency,
      this.state.totalItemPrice.toFixed(2),
      restaurantInfo.currency_affix
    );
  }

  totalItemDiscountedPrice() {
    const { restaurantInfo } = this.props;
    if (!this.state.totalItemPrice) {
      return null;
    }
    return this.props.getAmountStringWithCurrency(
      restaurantInfo.currency,
      this.props
        .getDiscountedAmount(this.state.totalItemPrice, false)
        .toFixed(2),
      restaurantInfo.currency_affix
    );
  }

  render() {
    if (!this.state.totalItemPrice) {
      return null;
    }

    return (
      <UiView style={styles.checkoutButton} onClick={this.onClick.bind(this)}>
        <UiRow>
          <UiColumn>
            <UiView>
              <UiHeading size="big" color="inverted" align="right">
                Add
              </UiHeading>
            </UiView>
          </UiColumn>
          {this.props.offer && this.props.offer.discount_percentage && (
            <UiColumn flex={0}>
              <UiHeading
                size="big"
                color="inverted"
                align="center"
                decoration="line-through"
              >
                {this.totalItemOriginalPrice()}
              </UiHeading>
            </UiColumn>
          )}
          <UiColumn>
            <UiHeading size="big" color="inverted" align="left">
              {this.totalItemDiscountedPrice()}
            </UiHeading>
          </UiColumn>
        </UiRow>
      </UiView>
    );
  }
}

var styles = {
  checkoutButton: {
    position: 'fixed',
    flex: 1,
    flexDirection: 'row',
    padding: 15,
    borderRadius: 6,
    left: 12,
    bottom: 12,
    right: 12,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors['button']['primary']
  },
  heading: {
    marginRight: 12
  }
};
