import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { colors } from '../util/Theme';
import UiRow from '../Row';
import UiColumn from '../Column';
import UiText from '../Text';

class UiCheckbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked
    };
  }

  handleChange(event) {
    this.setState({
      checked: !this.state.checked
    });
    this.props.onChange(event);
  }

  render() {
    const { classes, title, label, isDisabled } = this.props;
    return (
      <UiRow>
        <UiColumn flex={9}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.checked}
                disabled={isDisabled ? true : false}
                onChange={this.handleChange.bind(this)}
                value={title}
                classes={{
                  root: classes.root,
                  checked: classes.checked
                }}
              />
            }
            label={title}
            style={{
              width: '100%'
            }}
          />
        </UiColumn>
        <UiColumn justifyContent="center">
          {label ? <UiText align="right">{label}</UiText> : null}
        </UiColumn>
      </UiRow>
    );
  }
}

const styles = {
  root: {
    color: colors['checkbox']['primary'],
    padding: 5,
    '&$checked': {
      color: colors['checkbox']['primary'],
      padding: 5
    }
  },
  checked: {}
};

export default withStyles(styles)(UiCheckbox);
