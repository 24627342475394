import React, { Component } from 'react';

import { UiView, UiLoader } from '.';

import { colors } from './util/Theme';

export default class UiOverlay extends Component {
  overlayStyle() {
    const { backgroundColor, top, bottom, left, right } = this.props;
    return {
      backgroundColor: colors.overlayBackground[backgroundColor]
        ? colors.overlayBackground[backgroundColor]
        : backgroundColor,
      top,
      bottom,
      left,
      right
    };
  }

  render() {
    const { show } = this.props;
    if (!show) {
      return null;
    }
    return (
      <UiView
        style={{
          ...styles.overlay,
          ...this.overlayStyle()
        }}
        onClick={this.props.onClick}
      >
        <UiLoader />
      </UiView>
    );
  }
}

UiOverlay.defaultProps = {
  top: '0px',
  bottom: '0px',
  left: '0px',
  right: '0px',
  backgroundColor: 'inverted'
};

const styles = {
  overlay: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    zIndex: 100
  }
};
