import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import RestaurantSnippet from './../../components/restaurant/RestaurantSnippet';

export default withRouter(
  connect((state, ownProps) => {
    const { restaurantInfo } = state.restaurantState;
    return { restaurantInfo };
  })(RestaurantSnippet)
);
