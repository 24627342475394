import React, { Component } from 'react';

import {
  UiView,
  UiHeading,
  UiCell,
  List,
  UiRow
} from './../../dependencies/zomato-ui';

import { Address } from '../../containers/address';

export default class RestaurantAddresses extends Component {
  render() {
    const { deliveringAddresses, nonDeliveringAddresses } = this.props;
    return (
      <UiView>
        {deliveringAddresses.length ? (
          <UiView>
            <UiCell margin padded paddedBorder borderBottom="default">
              <UiRow margin>
                <UiHeading size="huge">Delivers to</UiHeading>
              </UiRow>
            </UiCell>
            <List
              data={deliveringAddresses}
              renderItem={addressId => (
                <Address key={addressId} addressId={addressId} />
              )}
            />
          </UiView>
        ) : null}
        {nonDeliveringAddresses.length ? (
          <UiView>
            <UiCell margin padded paddedBorder borderBottom="default">
              <UiRow margin>
                <UiHeading size="huge">Does not deliver to</UiHeading>
              </UiRow>
            </UiCell>
            <List
              data={nonDeliveringAddresses}
              renderItem={addressId => (
                <Address key={addressId} addressId={addressId} />
              )}
            />
          </UiView>
        ) : null}
      </UiView>
    );
  }
}
