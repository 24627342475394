import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import CustomisationsGroup from '../../components/restaurant/CustomisationsGroup';

export default withRouter(
  connect((state, ownProps) => {
    const { groups } = state.restaurantState;
    const { selectedDependencies } = state.customisationsState;
    const group = groups[ownProps.groupId];
    const isParentItemSelected =
      selectedDependencies.indexOf(group.parentItemId) >= 0;
    return { group, isParentItemSelected };
  })(CustomisationsGroup)
);
