import React, { Component } from 'react';

import {
  UiView,
  UiHeading,
  UiCell,
  List,
  UiRow,
  UiColumn
} from './../../dependencies/zomato-ui';

import { Address } from '../../containers/address';

import { getPageHeight } from '../../dependencies/modules/util';

export default class SavedAddresses extends Component {
  render() {
    const { addressList } = this.props;

    if (!addressList.length) {
      return null;
    }

    return (
      <UiView>
        <UiView style={styles.savedAddresses}>
          <UiRow>
            <UiColumn>
              <UiRow>
                <UiColumn>
                  <UiCell padded>
                    <UiHeading size="big">Saved Addresses</UiHeading>
                  </UiCell>
                </UiColumn>
              </UiRow>
            </UiColumn>
          </UiRow>
        </UiView>
        <UiView style={styles.address}>
          <List
            scrollable
            height={getPageHeight() - 200}
            data={addressList}
            renderItem={(addressId, index) => (
              <UiView
                style={{
                  marginBottom: index === addressList.length - 1 ? 70 : 0
                }}
              >
                <Address key={addressId} addressId={addressId} />
              </UiView>
            )}
          />
        </UiView>
      </UiView>
    );
  }
}

const styles = {
  savedAddresses: {
    width: '100%',
    backgroundColor: '#fff'
  },
  address: {
    marginTop: 10
  }
};
