import React, { Component } from 'react';

import { UiView, UiCounter } from '../../dependencies/zomato-ui';

export default class MenuItemCounter extends Component {
  hasGroups() {
    const { restaurantMenuItem } = this.props;
    return restaurantMenuItem.groups && restaurantMenuItem.groups.length > 0;
  }

  addCartItem() {
    const {
      itemId,
      resId,
      item,
      restaurantMenuItem,
      isDeliveringNow
    } = this.props;
    if (!isDeliveringNow) {
      return;
    }

    if (!item && !this.hasGroups()) {
      this.props.setItem({ resId, itemId });
      this.props.setCartItem({
        itemId,
        totalItemPrice: restaurantMenuItem.price,
        isBogoActive: restaurantMenuItem.is_bogo_active
      });
    } else if (this.hasGroups() && !item) {
      this.props.history.push(`/customisations/${resId}/${itemId}`);
    } else if (this.hasGroups() && item) {
      this.props.history.push(`/chooose-customisations/${resId}/${itemId}`);
    } else {
      this.props.increaseItemQuantity(itemId);
    }
  }

  removeCartItem() {
    const { itemId, item, resId } = this.props;
    if (item && item.length > 1) {
      this.props.history.push(
        `/change-customised-item-quantity/${resId}/${itemId}`
      );
    } else {
      const cartItemId = item[0];
      this.props.decreaseCartItemQuantity(itemId, cartItemId);
    }
  }

  render() {
    const { isDeliveringNow, quantity } = this.props;

    return (
      <UiView>
        <UiCounter
          value={quantity}
          onAdd={this.addCartItem.bind(this)}
          onMinus={this.removeCartItem.bind(this)}
          isDisabled={!isDeliveringNow}
        />
      </UiView>
    );
  }
}
