import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import ChooseCustomisationsPopup from '../../components/restaurant/ChooseCustomisationsPopup';

import { repeatLastCustomisedCartItemQuantity } from '../../store/cart/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const itemId = ownProps.match.params.itemId;
      const restaurantMenuItems = state.restaurantState.items;
      const item = restaurantMenuItems[itemId];
      const { items } = state.cartState;
      const cartItemId = items[itemId][items[itemId].length - 1];
      return { cartItemId, item, itemId };
    },
    {
      repeatLastCustomisedCartItemQuantity
    }
  )(ChooseCustomisationsPopup)
);
