import React, { Component } from 'react';
import { UiRow, UiColumn, UiIcon, UiLoader } from '../index';
import { colors } from '../util/Theme';

export default class UiTextInput extends Component {
  render() {
    const {
      value,
      icon,
      fontSize,
      name,
      style,
      placeholder,
      onChange,
      onFocus,
      onBlur,
      iconFontSize,
      closeIconFontSize,
      showLoader
    } = this.props;

    return (
      <UiRow alignItems="center">
        {icon ? (
          <UiIcon color={'#b8c2cb'} fontSize={iconFontSize} icon={icon} />
        ) : null}
        <UiColumn flex={12} nonPadded={!icon}>
          <input
            name={name}
            value={value}
            style={{ ...style, ...styles.input, fontSize }}
            type="text"
            placeholder={placeholder}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </UiColumn>
        {value && value.length ? (
          showLoader ? (
            <UiLoader show={showLoader} size={14} />
          ) : (
            <UiIcon
              onClick={this.props.onClear}
              color={colors['icon']['primary']}
              fontSize={closeIconFontSize}
              icon={'fa fa-times-circle'}
            />
          )
        ) : null}
      </UiRow>
    );
  }
}

const styles = {
  input: {
    outline: 0,
    border: 0,
    width: '100%'
  }
};

UiTextInput.defaultProps = {
  name: '',
  fontSize: 14,
  iconFontSize: 14,
  closeIconFontSize: 14,
  placeholder: '',
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {}
};
