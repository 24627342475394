import React, { Component } from 'react';

import {
  UiRow,
  UiColumn,
  UiHeading,
  UiView,
  UiCell,
  List
} from './../../dependencies/zomato-ui';

import RestaurantMenuItem from './../../containers/restaurant/RestaurantMenuItemContainer';

export default class RestaurantCategory extends Component {
  onClick() {
    if (!this.props.showCategoryName) {
      return;
    }
  }

  render() {
    const { category, resId, showCategoryName } = this.props;

    if (!category.category_id || !category.items) {
      return null;
    }

    return (
      <UiView>
        {showCategoryName && category.items.length ? (
          <UiCell padded paddedBorder onClick={this.onClick.bind(this)}>
            <UiRow marginTop style={styles.categoryRow}>
              <UiColumn nonPadded>
                <UiRow>
                  <UiColumn nonPadded>
                    <UiHeading size="medium" color="secondary">
                      {category.name.toUpperCase()}
                    </UiHeading>
                  </UiColumn>
                </UiRow>
              </UiColumn>
            </UiRow>
          </UiCell>
        ) : null}
        <UiCell padded paddedBorder borderBottom="default">
          <List
            data={category.items}
            renderItem={item => (
              <RestaurantMenuItem
                key={item.item_id}
                resId={resId}
                item={item}
              />
            )}
          />
        </UiCell>
      </UiView>
    );
  }
}

const styles = {
  categoryRow: {
    alignItems: 'center'
  }
};
