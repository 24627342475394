import React, { Component } from 'react';
import { units } from './util/Theme';

const styles = {
  UiRow: {
    padding: 0,
    flexDirection: 'row',
    display: 'flex'
  }
};

export default class UiRow extends Component {
  copyToClipboard() {}

  render() {
    const {
      margin,
      marginTop,
      marginBottom,
      justifyContent,
      alignItems
    } = this.props;
    let marginSet = { top: 0, bottom: 0, left: 0, right: 0 };
    marginSet = margin
      ? { ...marginSet, top: units.quarter, bottom: units.quarter }
      : marginSet;
    marginSet = marginTop ? { ...marginSet, top: units.quarter } : marginSet;
    marginSet = marginBottom
      ? { ...marginSet, bottom: units.quarter }
      : marginSet;

    const dependentStyles = {};
    if (justifyContent) {
      dependentStyles.justifyContent = justifyContent;
    }
    if (alignItems) {
      dependentStyles.alignItems = alignItems;
    }

    return (
      <div
        style={{
          ...styles.UiRow,
          ...{
            marginTop: marginSet.top,
            marginBottom: marginSet.bottom,
            marginLeft: marginSet.left,
            marginRight: marginSet.right
          },
          ...dependentStyles
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
