import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { VerifyPaymentStatusPage } from '../../components/pages';
import { verifyPaymentStatus } from '../../store/order/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const tabId = ownProps.match.params.tabId;
      return { tabId };
    },
    {
      verifyPaymentStatus
    }
  )(VerifyPaymentStatusPage)
);
