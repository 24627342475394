export default class StateManager {
  constructor(initialState, actions) {
    this.initialState = initialState;
    this.actions = actions;
  }

  reducer = (state, action) => {
    state = state || this.initialState;
    const { type } = action;
    if (this.actions && type && this.actions[type]) {
      const func = this.actions[type];
      return func(state, action) || state;
    } else {
      return state;
    }
  };
}
