import React, { Component } from 'react';
import { colors } from '../../dependencies/zomato-ui/util/Theme';

import {
  UiRow,
  UiColumn,
  UiText,
  UiView,
  UiHeading,
  UiButton,
  UiCell,
  UiTextInput,
  List,
  UiIcon,
  UiOverlay,
  UiDialog
} from './../../dependencies/zomato-ui';

import { Addresses } from '../../containers/address';

import { debounce, getErrorTitleMessage } from '../../util';

import { getPageHeight } from '../../dependencies/modules/util';

import AppHeader from '../common/AppHeader';
import ErrorPage from '../common/ErrorPage';

export default class LocationPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showIndicator: false,
      showDetectLocationIndicator: false,
      locationSnippetContainerHeight: 200,
      error: null,
      detectLocationError: null,
      searchQuery: '',
      errorMessage: '',
      errorTitle: ''
    };

    this.searchDebounce = debounce(this.search, 300);
  }

  componentDidMount() {
    this.props.clearLocations();
  }

  search() {
    this.setState({
      showIndicator: true,
      error: null
    });

    this.props
      .searchLocations(this.state.searchQuery)
      .then(() => {
        this.setState({
          showIndicator: false
        });
      })
      .catch(error => {
        this.setState({
          showIndicator: false,
          error: error
        });
      });
  }

  onChange(event) {
    this.setState(
      {
        searchQuery: event.target.value
      },
      () => {
        this.searchDebounce();
      }
    );
  }

  onSelect(index) {
    const { locations } = this.props;
    const selectedLocation = locations[index];

    if (!this.props.showAddressList) {
      this.props.selectLocationForAddress(selectedLocation);
    } else {
      this.props.selectLocation(selectedLocation);
    }

    this.props.history.goBack();
  }

  detectLocation() {
    this.setState({
      showDetectLocationIndicator: true,
      detectLocationError: null
    });

    const data = {
      showAddressList: this.props.showAddressList
    };

    this.props
      .getNearestDSZ(data)
      .then(() => {
        this.setState({
          showDetectLocationIndicator: false
        });
        this.props.history.goBack();
      })
      .catch(error => {
        const errorObject = getErrorTitleMessage(error);

        this.setState({
          showDetectLocationIndicator: false,
          detectLocationError: error,
          errorTitle: errorObject.errorTitle,
          errorMessage: errorObject.errorMessage
        });
      });
  }

  disMissErrorDialog() {
    this.setState({ detectLocationError: null });
  }

  addAddress() {
    this.props.history.push(`/add-address`);
  }

  onClear() {
    this.setState({
      searchQuery: '',
      error: null
    });
    this.props.setLocationsList();
  }

  render() {
    const { locations, showAddressList } = this.props;
    const {
      showDetectLocationIndicator,
      detectLocationError,
      error,
      showIndicator,
      searchQuery,
      errorTitle,
      errorMessage
    } = this.state;

    return (
      <UiView
        style={{
          backgroundColor: 'rgb(236, 242, 243)',
          height: getPageHeight()
        }}
      >
        <AppHeader
          iconSize={18}
          iconColor={'#999999'}
          showHeaderTitle
          showBackButton
          onBack={() => this.props.history.goBack()}
          title={'Delivery Address'}
        >
          <UiView style={{ backgroundColor: '#fff', paddingTop: 10 }}>
            <UiCell padded paddedBorder borderBottom="default">
              <UiTextInput
                onClear={this.onClear.bind(this)}
                showLoader={showIndicator}
                value={searchQuery}
                placeholder="Search for your delivery area..."
                onChange={this.onChange.bind(this)}
              />
            </UiCell>
            <UiCell margin paddedBorder>
              <UiText size="mini" color="secondary">
                E.G. Connaught Place, New Delhi
              </UiText>
            </UiCell>
            <UiRow>
              <UiColumn nonPadded>
                <UiCell
                  margin
                  padded
                  borderTop="default"
                  borderBottom="default"
                  onClick={this.detectLocation.bind(this)}
                  paddedBorder
                >
                  <UiRow>
                    <UiColumn nonPadded>
                      <UiIcon
                        color={colors['anchor']['primary']}
                        fontSize={14}
                        icon={'fa fa-dot-circle-o'}
                      />
                    </UiColumn>
                    <UiColumn flex={15} nonPadded>
                      <UiHeading type="anchor">Use Current Location</UiHeading>
                    </UiColumn>
                  </UiRow>
                </UiCell>
              </UiColumn>
            </UiRow>
          </UiView>
          <UiView
            style={{
              ...styles.resultsContainer,
              height: getPageHeight() - 155
            }}
          >
            {!error && showAddressList && (!locations || !locations.length) ? (
              <Addresses />
            ) : null}
            {locations && locations.length ? (
              <List
                data={locations}
                renderItem={(location, index) => (
                  <UiCell
                    padded
                    key={location.place.place_id}
                    onClick={this.onSelect.bind(this, index)}
                  >
                    <UiRow margin>
                      <UiColumn doublePadded>
                        <UiHeading>{location.place.place_name}</UiHeading>
                      </UiColumn>
                    </UiRow>
                  </UiCell>
                )}
              />
            ) : null}
            {!showIndicator && (error || (locations && !locations.length)) && (
              <ErrorPage
                error={
                  error ||
                  (locations && !locations.length ? { status: 'empty' } : null)
                }
                tryAgain={this.searchDebounce.bind(this)}
              />
            )}
            {showAddressList && (!locations || !locations.length) && (
              <UiView style={styles.addAddressBtn}>
                <UiButton
                  gradient
                  class="add"
                  title="Add New Address"
                  color="white"
                  fluid="true"
                  type="primary"
                  variant="contained"
                  onPress={this.addAddress.bind(this)}
                ></UiButton>
              </UiView>
            )}
          </UiView>
        </AppHeader>
        <UiOverlay show={showDetectLocationIndicator} />
        <UiDialog
          isVisible={
            !showDetectLocationIndicator && detectLocationError ? true : false
          }
          title={errorTitle}
          content={errorMessage}
          confirmText="OK"
          onConfirm={this.disMissErrorDialog.bind(this)}
        />
      </UiView>
    );
  }
}

const styles = {
  resultsContainer: {
    position: 'relative',
    backgroundColor: '#ecf2f3',
    flex: 1
  },
  addAddressBtn: {
    position: 'fixed',
    flex: 1,
    flexDirection: 'row',
    left: 12,
    bottom: 12,
    right: 12,
    alignItems: 'center',
    zIndex: 1000
  },
  locations: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#ecf2f3'
  }
};
