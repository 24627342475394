import React, { Component } from 'react';

import { UiView, UiCounter } from '../../dependencies/zomato-ui';

export default class CartItemCounter extends Component {
  addCartItem() {
    this.props.increaseCartItemQuantity(this.props.cartItemId);
  }

  removeCartItem() {
    const { itemId, cartItemId } = this.props;
    this.props.decreaseCartItemQuantity(itemId, cartItemId);
    this.props.onItemRemove();
  }

  render() {
    return (
      <UiView>
        <UiCounter
          onAdd={this.addCartItem.bind(this)}
          onMinus={this.removeCartItem.bind(this)}
          value={this.props.cartItemQuantity}
        />
      </UiView>
    );
  }
}
