import { connect } from 'react-redux';
import { withRouter } from '../../../dependencies/modules/react-router-native';
import { SearchResults } from './../../../components/home/search';

import { getkeywordsSearchResults } from '../../../store/search/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { searchResults } = state.searchState;
      const searchResultRestaurants = searchResults.restaurantList;
      const show = ownProps.show;
      return { show, searchResults, searchResultRestaurants };
    },
    { getkeywordsSearchResults }
  )(SearchResults)
);
