import React, { Component } from 'react';
import {
  UiRow,
  UiColumn,
  UiCell,
  UiHeading,
  UiView,
  UiDivider,
  UiImage,
  UiText,
  UiAnchor
} from '../../dependencies/zomato-ui';

export default class RiderDetails extends Component {
  render() {
    const { riderDetails } = this.props;
    if (!riderDetails) {
      return null;
    }

    const { title, phone, image, subtitle } = riderDetails;
    if (!title || !phone) {
      return null;
    }

    return (
      <UiView>
        <UiCell padded marginTop>
          <UiRow>
            <UiView style={{ marginRight: 3, marginLeft: 3, paddingLeft: 6 }}>
              {image ? <UiImage src={image} radius={15} /> : null}
            </UiView>
            <UiColumn flex={9}>
              <UiHeading size="medium">{title}</UiHeading>
              {subtitle ? (
                <UiRow marginTop>
                  <UiText>{subtitle}</UiText>
                </UiRow>
              ) : null}
            </UiColumn>
            <UiColumn>
              <UiAnchor href={`tel:${phone}`}>
                <UiImage
                  src={
                    'https://b.zmtcdn.com/data/o2_assets/c8832da1f0c932504da014f72fea812e1569324865.png'
                  }
                />
              </UiAnchor>
            </UiColumn>
          </UiRow>
        </UiCell>
        <UiRow>
          <UiColumn doublePadded>
            <UiDivider />
          </UiColumn>
        </UiRow>
      </UiView>
    );
  }
}
