import React, { Component } from 'react';
import { colors } from '../../dependencies/zomato-ui/util/Theme';

import {
  UiText,
  UiRow,
  UiColumn,
  UiHeading,
  UiView
} from '../../dependencies/zomato-ui';

export default class AddressMapButton extends Component {
  render() {
    const { isAddButtonEnabled } = this.props;
    const addressMapOutline = isAddButtonEnabled
      ? styles.addressMapEnable
      : styles.addressMapDisable;
    return (
      <UiView style={{ ...styles.addressMapBtn, ...addressMapOutline }}>
        <UiView onClick={this.props.navigateToAddressMap}>
          <UiRow>
            <UiColumn>
              <UiHeading
                align="center"
                color={isAddButtonEnabled ? 'inverted' : 'primary'}
                size="large"
              >
                Next
              </UiHeading>
              <UiText
                align="center"
                color={isAddButtonEnabled ? 'inverted' : 'primary'}
              >
                MARK YOUR ADDRESS ON THE MAP
              </UiText>
            </UiColumn>
          </UiRow>
        </UiView>
      </UiView>
    );
  }
}

var styles = {
  addressMapBtn: {
    overflow: 'hidden',
    position: 'fixed',
    flex: 1,
    left: 10,
    right: 10,
    bottom: 10,
    borderRadius: 6,
    padding: 7
  },
  addressMapEnable: {
    backgroundImage: `linear-gradient(to right,${colors['button']['primary']},${
      colors['button']['primary']
    })`
  },
  addressMapDisable: {
    backgroundImage: `linear-gradient(to right,${
      colors['button']['disabled']
    },${colors['button']['disabled']})`
  }
};
