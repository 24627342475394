import { connect } from 'react-redux';
import { withRouter } from '../../../dependencies/modules/react-router-native';
import { SortList } from './../../../components/home/search';

import {
  getCategoriesList,
  setSelectedSorting
} from '../../../store/search/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { selectedSorting } = state.searchState;
      const hidePopup = ownProps.hidePopup;
      return { hidePopup, selectedSorting };
    },
    {
      getCategoriesList,
      setSelectedSorting
    }
  )(SortList)
);
