import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { RestaurantAddresses } from './../../components/address';

export default withRouter(
  connect((state, ownProps) => {
    const { addressList, addresses } = state.addressState;
    const deliveringAddresses = addressList.filter(
      addressId =>
        addresses &&
        addresses[addressId] &&
        !!addresses[addressId].is_delivery_location_for_restaurant
    );
    const nonDeliveringAddresses = addressList.filter(
      addressId =>
        addresses &&
        addresses[addressId] &&
        !addresses[addressId].is_delivery_location_for_restaurant
    );
    return { deliveringAddresses, nonDeliveringAddresses };
  })(RestaurantAddresses)
);
