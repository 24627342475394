import { Fetch } from './helpers';

export default {
  login({ thirdPartyToken, scopeNames }) {
    const headers = {
      'X-ZOMATO-THIRDPARTY-TOKEN': thirdPartyToken
    };

    const body = {
      user_data_scopes_param: scopeNames
    };

    const path = 'order/login';
    return Fetch.post({ path }, { headers, body: JSON.stringify(body) });
  }
};
