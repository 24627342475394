import React from 'react';
import {
  withRouter,
  Route,
  Switch
} from './dependencies/modules/react-router-native';

import {
  setPaymentStatusInStorage,
  getPaymentStatusFromStorage
} from './store/cart/actions';

import {
  HomePage,
  LocationPage,
  Restaurant,
  Customisations,
  CartPage,
  SelectAddress,
  AddressPage,
  AddressMap,
  OrderSummary,
  OrderHistory,
  CrystalPage,
  PaymentPage,
  VerifyPaymentStatusPage
} from './containers/pages';

import { PaymentStatusPage } from './components/pages';

class Routes extends React.Component {
  componentDidMount() {
    const params = this.props.location.pathname
      .replace('/payment-status/', '')
      .split('/');
    const tabId = params[0];
    const paymentStatus = params[1];
    if (this.props.location.pathname.match('/payment-status/') && tabId) {
      this.props.history.go(-(this.props.history.length - 1));
      setPaymentStatusInStorage({
        isPaymentStatusPage: true,
        tabId,
        paymentStatus
      });
    }

    this.redirectToCrystal();
  }
  componentDidUpdate() {
    if (this.props.location.pathname === '/') {
      this.redirectToCrystal();
    }
  }

  redirectToCrystal() {
    const { history } = this.props;
    if (this.props.location.pathname === '/') {
      getPaymentStatusFromStorage().then(paymentStatusObject => {
        if (!paymentStatusObject || !paymentStatusObject.isPaymentStatusPage) {
          return;
        }
        setPaymentStatusInStorage(null);
        const { paymentStatus, tabId } = paymentStatusObject;
        if (paymentStatus === 'success') {
          history.push(`/order-details/${tabId}`);
        } else {
          history.push(`/order-summary/${tabId}`);
        }
      });
    }
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/filters" component={HomePage} />
        <Route exact path="/sort" component={HomePage} />
        <Route
          exact
          path="/restaurant-chain-outlets/:resId"
          component={HomePage}
        />
        <Route
          exact
          path="/select-location/:showAddressList"
          component={LocationPage}
        />
        <Route exact path="/restaurant/:resId" component={Restaurant} />
        <Route exact path="/restaurant-search/:resId" component={Restaurant} />
        <Route
          exact
          path="/chooose-customisations/:resId/:itemId"
          component={Restaurant}
        />
        <Route
          exact
          path="/change-customised-item-quantity/:resId/:itemId"
          component={Restaurant}
        />
        <Route
          exact
          path="/customisations/:resId/:itemId"
          component={Customisations}
        />
        <Route
          exact
          path="/customisations/:resId/:itemId/:already_added"
          component={Customisations}
        />
        <Route exact path="/cart/:resId" component={CartPage} />
        <Route exact path="/select-address/:resId" component={SelectAddress} />
        <Route exact path="/add-address" component={AddressPage} />
        <Route exact path="/address-map" component={AddressMap} />
        <Route exact path="/add-address/:resId" component={AddressPage} />
        <Route exact path="/address-map/:resId" component={AddressMap} />
        <Route exact path="/order-summary/:tabId" component={OrderSummary} />
        <Route exact path="/order-history" component={OrderHistory} />
        <Route exact path="/order-details/:tabId" component={CrystalPage} />
        <Route exact path="/checkout" component={PaymentPage} />
        <Route
          exact
          path="/verify-payment-status/:tabId"
          component={VerifyPaymentStatusPage}
        />
        <Route exact path="/terms/service" component={OrderSummary} />
        <Route
          exact
          path="/payment-status/:tabId/:paymentStatus"
          component={PaymentStatusPage}
        />
      </Switch>
    );
  }
}

export default withRouter(Routes);
