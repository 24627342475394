import React, { Component } from 'react';

import {
  UiRow,
  UiColumn,
  UiHeading,
  UiCell,
  UiButton
} from './../../dependencies/zomato-ui';

import CartItemCustomisations from '../../containers/cart/CartItemCustomisationsContainer';

export default class ChooseCustomisationsPopup extends Component {
  addNewCusomisation() {
    const { itemId, resId } = this.props;
    this.props.history.replace(`/customisations/${resId}/${itemId}/1`);
  }

  repeatLastCusomisation() {
    const { itemId } = this.props;

    this.props.repeatLastCustomisedCartItemQuantity(itemId);
    this.props.history.goBack();
  }

  render() {
    const { itemId, item, cartItemId } = this.props;

    if (!itemId || !item.item_id) {
      return null;
    }

    return (
      <UiCell margin padded>
        <UiRow marginTop>
          <UiColumn>
            <UiRow marginTop>
              <UiColumn>
                <UiHeading size="medium">
                  Repeat last used customisation?
                </UiHeading>
              </UiColumn>
            </UiRow>
          </UiColumn>
        </UiRow>
        <UiRow marginTop>
          <UiColumn doublePadded>
            <CartItemCustomisations cartItemId={cartItemId} />
          </UiColumn>
        </UiRow>
        <UiRow marginTop>
          <UiColumn>
            <UiRow>
              <UiColumn>
                <UiCell margin padded>
                  <UiButton
                    title="Add new"
                    fluid="true"
                    type="primary"
                    variant="contained"
                    onPress={this.addNewCusomisation.bind(this)}
                  />
                </UiCell>
              </UiColumn>
              <UiColumn>
                <UiCell margin padded>
                  <UiButton
                    title="Repeat last"
                    fluid="true"
                    type="primary"
                    variant="contained"
                    onPress={this.repeatLastCusomisation.bind(this)}
                  />
                </UiCell>
              </UiColumn>
            </UiRow>
          </UiColumn>
        </UiRow>
      </UiCell>
    );
  }
}
