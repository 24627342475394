import React, { Component } from 'react';

import {
  UiView,
  UiRow,
  UiColumn,
  UiImage,
  UiHeading
} from './../../dependencies/zomato-ui';

export default class Fssai extends Component {
  render() {
    const { fssai } = this.props;
    if (!fssai) {
      return null;
    }
    return (
      <UiView style={{ marginBottom: 100 }}>
        {fssai && fssai.text && (
          <UiRow margin>
            <UiColumn doublePadded>
              <UiRow marginTop>
                <UiColumn nonPadded>
                  <UiImage src={fssai.image} width={35} height={17} />
                </UiColumn>
              </UiRow>
              <UiRow margin>
                <UiColumn nonPadded>
                  <UiHeading color="secondary" size="small" weight="600">
                    {fssai.text}
                  </UiHeading>
                </UiColumn>
              </UiRow>
            </UiColumn>
          </UiRow>
        )}
      </UiView>
    );
  }
}
