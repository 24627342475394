import React, { Component } from 'react';
import {
  UiRow,
  UiColumn,
  UiCell,
  UiHeading,
  UiImage,
  UiView,
  UiAnchor
} from '../../dependencies/zomato-ui';

export default class SupportDetails extends Component {
  render() {
    const { image } = this.props;
    return (
      <UiCell paddedBorder padded borderBottom="default">
        <UiRow>
          <UiView style={{ marginTop: 6 }}>
            <UiImage src={image} width={30} heigth={30} />
          </UiView>
          <UiColumn>
            <UiAnchor href="tel:0124-6419012">
              <UiRow margin>
                <UiHeading size="medium">
                  {/* {title} */}
                  Have an issue? Call Us
                </UiHeading>
              </UiRow>
            </UiAnchor>
            <UiAnchor href="tel:0124-6419012">
              <UiRow margin>
                <UiHeading size="medium" type="button" color="primary">
                  {/* {subtitle} */}
                  0124-6419012
                </UiHeading>
              </UiRow>
            </UiAnchor>
          </UiColumn>
        </UiRow>
      </UiCell>
    );
  }
}
