import React, { Component } from 'react';

export default class UiAnchor extends Component {
  render() {
    const { href, children, textDecoration, color } = this.props;
    return (
      <a href={href} style={{ textDecoration: textDecoration, color: color }}>
        {children}
      </a>
    );
  }
}

UiAnchor.defaultProps = {
  textDecoration: 'none'
};
