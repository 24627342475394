import React, { Component } from 'react';
import {
  UiRow,
  UiCell,
  UiHeading,
  UiText,
  UiColumn,
  UiView,
  UiIcon
} from '../../dependencies/zomato-ui';

import CircleRipple from '../../dependencies/zomato-ui/circleRipple';
import { colors } from '../../dependencies/zomato-ui/util/Theme';

export default class OrderStatusDetails extends Component {
  render() {
    const {
      title,
      orderStatusText,
      subtitle,
      subtitle2,
      orderStatus
    } = this.props;
    return (
      <UiCell marginTop padded paddedBorder borderBottom="default">
        <UiRow>
          <UiView style={{ marginTop: 9, marginRight: 9, marginLeft: 3 }}>
            {orderStatus === 7 || orderStatus === 8 ? (
              <UiIcon
                icon={'fa fa-times-circle'}
                fontSize={22}
                color={colors['anchor']['primary']}
              />
            ) : (
              <CircleRipple />
            )}
          </UiView>
          <UiColumn>
            {orderStatusText && (
              <UiRow marginBottom>
                <UiHeading size="small" type="anchor">
                  {orderStatusText}
                </UiHeading>
              </UiRow>
            )}
            {title && (
              <UiRow margin>
                <UiHeading size="medium">{title}</UiHeading>
              </UiRow>
            )}
            {subtitle && (
              <UiRow margin>
                <UiText size="small">{subtitle}</UiText>
              </UiRow>
            )}
            {subtitle2 && (
              <UiRow margin>
                <UiText size="small" type="anchor">
                  {subtitle2}
                </UiText>
              </UiRow>
            )}
          </UiColumn>
        </UiRow>
      </UiCell>
    );
  }
}
