import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import LocationPage from '../../components/pages/LocationPage';

import {
  searchLocations,
  clearLocations,
  selectLocationForAddress,
  selectLocation,
  setLocationsList
} from '../../store/locations/actions';

import { getNearestDSZ } from '../../store/locations/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { locations } = state.locationsState;
      const showAddressList = JSON.parse(ownProps.match.params.showAddressList);
      return { locations, showAddressList };
    },
    {
      searchLocations,
      clearLocations,
      selectLocationForAddress,
      selectLocation,
      getNearestDSZ,
      setLocationsList
    }
  )(LocationPage)
);
