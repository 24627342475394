export default class CustomError extends Error {
  constructor(
    error = 'Error',
    errorCode = null,
    errorMessage = '',
    meta = null
  ) {
    super(typeof error === 'string' ? error : error.message);
    this.error = error;

    if (typeof error === 'string') {
      errorMessage = error;
    }

    if (error.errorCode || error.errorMessage) {
      return error;
    }

    if (errorCode) {
      this.errorCode = errorCode;
    }

    if (errorMessage) {
      this.errorMessage = errorMessage;
    }

    if (meta) {
      Object.keys(meta).forEach(key => {
        this[key] = meta[key];
      });
    }
  }
}
