import { Component } from 'react';
import { deepCompare, debounce } from '../../util';

export default class CartObject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastSearch: null,
      isFirstCalculation: true,
      error: null
    };

    this.calculateCartDebounce = debounce(this.calculateCart, 300);
  }

  componentDidMount() {
    this.props.getUserPermission().then(() => {
      this.calculateCartDebounce();
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cartItems !== this.props.cartItems) {
      if (!prevProps.cartItems && !Object.keys(this.props.cartItems).length) {
        this.props.history.goBack();
        return;
      }
      this.calculateCartDebounce();
    }
  }

  calculateCart() {
    const {
      resId,
      selectedLocation,
      mobileNumber,
      name,
      promocode
    } = this.props;
    const dishes = this.props.buildCartObject();

    if (
      //!this.props.selectedLocation ||
      !this.props.resId
    ) {
      return;
    }

    const cartObject = {
      resId: resId,
      addressId: selectedLocation.id ? selectedLocation.id : null,
      phone: mobileNumber,
      name: name,
      promocode: (promocode && promocode.code) || '',
      dishes
    };

    if (
      this.state.lastSearch &&
      deepCompare(cartObject, this.state.lastSearch)
    ) {
      return;
    }

    const state = { ...this.state };

    state.lastSearch = cartObject;
    if (!this.state.isFirstCalculation && this.state.error) {
      state.isFirstCalculation = true;
    }
    state.error = null;

    this.showLoader(true);

    this.setState(state);
    this.props
      .getCartFinalCalculations(cartObject)
      .then(() => {
        this.setState({
          error: null
        });
        this.showLoader(false);
      })
      .catch(error => {
        this.setState({
          error: error
        });
        this.showLoader(false, error);
      });
  }

  showLoader(show, error) {
    if (this.state.isFirstCalculation) {
      this.props.setCalculatingCart(show);
      if (!show && !error) {
        this.setState({
          isFirstCalculation: false
        });
      } else if (error) {
        this.setState({
          isFirstCalculation: true
        });
      }
    } else {
      this.props.setCalculatingUpdatedCart(show);
    }
    // this.props.setError(error);
  }

  render() {
    return null;
  }
}
