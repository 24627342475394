import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import Restaurant from '../../components/pages/Restaurant';
import {
  getRestaurant,
  setMenuFilters,
  toggleChooseCustomisationsPopup,
  toggleChangeCustomisedItemQuantityPopup
} from '../../store/restaurant/actions';

import {
  setCart,
  setCartFromStorage,
  setCartEmpty,
  getCartCalculations,
  setCartCalculation,
  removeOutOfStockItemsFromCart
} from '../../store/cart/actions';
import { selectLocation } from '../../store/locations/actions';

import WithPopup from '../../dependencies/modules/WithPopup';

const ConnectedComponent = withRouter(
  connect(
    (state, ownProps) => {
      const { restaurants } = state.searchState;
      const { items } = state.cartState;
      const {
        restaurantInfo,
        menusList,
        customisationsPopup
      } = state.restaurantState;
      const restaurantMenuItems = state.restaurantState.items;
      const {
        isChooseCustomisationsPopupVisible,
        isChangeCustomisedItemQuantityPopupVisible,
        itemId
      } = customisationsPopup;
      const { selectedLocation } = state.locationsState;
      const resId = ownProps.match.params.resId;
      const restaurantImage =
        restaurants &&
        restaurants[resId] &&
        restaurants[resId].o2_featured_image;
      const isRestaurantChainOutlets =
        restaurants && restaurants[resId] && restaurants[resId].chain_outlets
          ? true
          : false;

      return {
        resId,
        restaurantInfo,
        menusList,
        selectedLocation,
        isChooseCustomisationsPopupVisible,
        isChangeCustomisedItemQuantityPopupVisible,
        itemId,
        addedItemsInCart: items,
        isRestaurantChainOutlets,
        restaurantImage,
        restaurantMenuItems
      };
    },
    {
      setCart,
      setCartEmpty,
      setCartFromStorage,
      getCartCalculations,
      setCartCalculation,
      removeOutOfStockItemsFromCart,
      selectLocation,
      getRestaurant,
      setMenuFilters,
      toggleChooseCustomisationsPopup,
      toggleChangeCustomisedItemQuantityPopup
    }
  )(Restaurant)
);

export default connect(
  (state, ownProps) => {
    return {};
  },
  {
    toggleChooseCustomisationsPopup,
    toggleChangeCustomisedItemQuantityPopup
  }
)(WithPopup(ConnectedComponent));
