import { StateManager } from '../../util';
import { SET_USER_DATA } from './actions';

export const initialState = {
  uuid: null,
  email: null,
  name: null,
  mobileNumber: null,
  thirdPartyUserId: null
};

const setUserData = (state, action) => {
  const { user } = action;
  return {
    ...state,
    uuid: user.guest_id,
    mobileNumber: user.phone,
    email: user.email,
    name: user.name,
    thirdPartyUserId: user.third_party_user_id
  };
};

const actions = {
  [SET_USER_DATA]: setUserData
};

const manager = new StateManager(initialState, actions);

export default manager.reducer;
