import { home } from '../../api';

export const SET_ERROR = 'SET_ERROR';
export const SET_LOADING = 'SET_LOADING';
export const SET_LAST_QUERY = 'SET_LAST_QUERY';
export const SET_SEARCH_DATA = 'SET_SEARCH_DATA';
export const SET_FILTER_DATA = 'SET_FILTER_DATA';
export const SET_APPLIED_FILTERS = 'SET_APPLIED_FILTERS';
export const SET_APPLIED_CUISINE = 'SET_APPLIED_CUISINE';
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS';
export const SET_SELECTED_CUISINE = 'SET_SELECTED_CUISINE';
export const SET_SELECTED_SORTING = 'SET_SELECTED_SORTING';
export const UPDATE_RESTAURANT_LIST = 'UPDATE_RESTAURANT_LIST';
export const SET_RESTAURANT_LIST = 'SET_RESTAURANT_LIST';
export const SET_RENDERED_RESTAURANTS = 'SET_RENDERED_RESTAURANTS';
export const SET_SEARCH_RESULTS_RESTAURANTS = 'SET_SEARCH_RESULTS_RESTAURANTS';

export const getRestaurants = params => dispatch => {
  return home.getData(params).then(response => {
    const restaurants = response ? response.restaurants : null;
    const renderedRestaurants = response ? response.rendered_restaurants : null;
    const searchData = {
      location: response ? response.location : {},
      hasMore: response ? response.has_more : false,
      totalCount: response ? response.total_count : 0,
      responseCount: response ? response.response_count : 0
    };

    const dispatchData = {
      restaurants: {},
      restaurantList: []
    };

    if (restaurants) {
      restaurants.forEach(restaurant => {
        dispatchData.restaurantList.push(restaurant.restaurant.R.res_id);
        dispatchData.restaurants[restaurant.restaurant.R.res_id] =
          restaurant.restaurant;
      });
    }

    const filterObject = {
      cuisines: {},
      sort: {},
      other: {}
    };

    if (response.filter_object && response.filter_object.categories) {
      response.filter_object.categories.forEach(category => {
        const { type } = category;
        category.filter_params.forEach(filterParams => {
          filterObject[type][filterParams.filter_param.text] =
            filterParams.filter_param;
        });
      });
    }
    if (params.renderedRestaurants) {
      dispatch(updateRestaurantList(dispatchData));
    } else {
      dispatch(setRestaurantList(dispatchData));
    }

    dispatch(setRenderedRestaurants(renderedRestaurants));
    dispatch(setSearchData(searchData));
    dispatch(setFilterData(filterObject));
  });
};

export const setSearchData = data => dispatch => {
  dispatch({
    type: SET_SEARCH_DATA,
    location: data.location,
    hasMore: data.hasMore,
    totalCount: data.totalCount,
    responseCount: data.responseCount,
    filterObject: { ...data.filterObject }
  });
};

export const setFilterData = filterObject => dispatch => {
  dispatch({
    type: SET_FILTER_DATA,
    filterObject
  });
};

export const updateRestaurantList = data => dispatch => {
  dispatch({
    type: UPDATE_RESTAURANT_LIST,
    restaurants: data.restaurants,
    restaurantList: data.restaurantList
  });
};

export const setRestaurantList = data => dispatch => {
  dispatch({
    type: SET_RESTAURANT_LIST,
    restaurants: data.restaurants,
    restaurantList: data.restaurantList
  });
};

export const getkeywordsSearchResults = params => (dispatch, getState) => {
  // const state = getState().searchState;
  const dispatchData = {
    restaurants: {},
    restaurantList: []
  };

  if (!params.query || params.query.length <= 1) {
    dispatch(setlastQuery(null));
    dispatch(setSearchResultRestaurants(dispatchData));
    return Promise.resolve();
  }

  dispatch(setlastQuery(params.query));
  return home.getkeywordsSearchResults(params).then(response => {
    // if (params.query !== state.searchResults.lastQuery) {
    //   return;
    // }

    if (!response.results.length) {
      dispatch(setSearchResultRestaurants(dispatchData));
      return;
    }

    if (response && response.restaurants) {
      response.restaurants.forEach(restaurant => {
        dispatchData.restaurantList.push(restaurant.restaurant.R.res_id);
        dispatchData.restaurants[restaurant.restaurant.R.res_id] =
          restaurant.restaurant;
      });
    }

    response.results.some(result => {
      if (result.type === 'restaurant') {
        if (!result.data.length) {
          return true;
        }
        result.data.forEach(resultData => {
          const restaurant = resultData.data.restaurant;
          dispatchData.restaurantList.push(restaurant.R.res_id);
          dispatchData.restaurants[restaurant.R.res_id] = restaurant;
        });
      }
      return false;
    });

    dispatch(setSearchResultRestaurants(dispatchData));
  });
};

export const setlastQuery = lastQuery => dispatch => {
  dispatch({
    type: SET_LAST_QUERY,
    lastQuery
  });
};

export const setSearchResultRestaurants = data => dispatch => {
  dispatch({
    type: SET_SEARCH_RESULTS_RESTAURANTS,
    restaurantList: data.restaurantList,
    restaurants: data.restaurants
  });
};

export const setRenderedRestaurants = renderedRestaurants => dispatch => {
  dispatch({
    type: SET_RENDERED_RESTAURANTS,
    renderedRestaurants
  });
};

export const setSelectedFilters = filters => dispatch => {
  dispatch({
    type: SET_SELECTED_FILTERS,
    filters
  });
};

export const setSelectedCuisine = cuisine => dispatch => {
  dispatch({
    type: SET_SELECTED_CUISINE,
    cuisine
  });
};

export const applySelectedFilters = () => (dispatch, getState) => {
  const state = getState().searchState;
  const filters = state.selectedFilters;

  dispatch({
    type: SET_APPLIED_FILTERS,
    filters
  });
};

export const applySelectedCuisine = () => (dispatch, getState) => {
  const state = getState().searchState;
  const cuisine = state.selectedCuisine;

  dispatch({
    type: SET_APPLIED_CUISINE,
    cuisine
  });
};

export const setSelectedSorting = sorting => dispatch => {
  dispatch({
    type: SET_SELECTED_SORTING,
    sorting
  });
};

export const getCategoriesList = type => (dispatch, getState) => {
  const state = getState().searchState;
  const sortTypeArray = [];
  if (!state.filterObject || !state.filterObject[type]) {
    return sortTypeArray;
  }

  const filters = state.filterObject[type];
  Object.keys(filters).forEach(filter => {
    let value = filters[filter].value;
    let key = filters[filter].key;
    let checked = false;
    if (
      type === 'cuisines' &&
      state.selectedCuisine.title === filters[filter].text
    ) {
      checked = true;
    } else if (
      type === 'other' &&
      state.appliedFilters.indexOf(filters[filter].key) >= 0
    ) {
      checked = true;
    } else if (
      type === 'sort' &&
      (state.selectedSorting &&
        state.selectedSorting.title === filters[filter].text)
    ) {
      checked = true;
    }

    if (type === 'other') {
      value = filters[filter].key;
      key = filters[filter].value;
    }
    sortTypeArray.push({
      title: filters[filter].text,
      id: key,
      value,
      icon: filters[filter].icon,
      checked
    });
  });
  if (type === 'sort') {
    sortTypeArray[0].checked = true;
  }
  return sortTypeArray;
};

export const resetFilters = () => dispatch => {
  dispatch(setSelectedFilters([]));
  dispatch(setSelectedCuisine({}));
  dispatch(applySelectedFilters());
  dispatch(applySelectedCuisine());
};

export const resetSorting = () => dispatch => {
  dispatch(setSelectedSorting(null));
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const setError = error => dispatch => {
  dispatch({
    type: SET_ERROR,
    error
  });
};
