import React, { Component } from 'react';

import {
  UiRow,
  UiColumn,
  UiHeading,
  UiView,
  UiIcon,
  UiDivider,
  UiScroller
} from '../../dependencies/zomato-ui';
import { units } from '../../dependencies/zomato-ui/util/Theme';

export default class AppHeader extends Component {
  render() {
    const {
      title,
      showHeaderTitle,
      showRightIcon,
      iconColor,
      titleColor,
      iconSize,
      scrollerHeight,
      stickyHeader,
      backgroundColor,
      titlePosition,
      showRightText,
      rightText,
      rightIcon,
      showBackButton
    } = this.props;
    return (
      <UiView>
        <UiView
          style={{
            position: stickyHeader ? 'fixed' : 'relative',
            left: 0,
            right: 0,
            top: 0,
            padding: units.half,
            zIndex: 10,
            backgroundColor: backgroundColor ? backgroundColor : '#fff'
          }}
        >
          <UiRow alignItems="center">
            <UiColumn nonPadded>
              {showBackButton && (
                <UiView onClick={this.props.onBack}>
                  <UiIcon
                    feedback
                    color={iconColor}
                    fontSize={iconSize}
                    icon={'fa fa-arrow-left'}
                  />
                </UiView>
              )}
            </UiColumn>
            <UiColumn flex={12}>
              {showHeaderTitle && (
                <UiView
                  style={{
                    display: 'flex',
                    justifyContent: titlePosition ? titlePosition : 'center'
                  }}
                >
                  <UiHeading color={titleColor} size="big">
                    {title}
                  </UiHeading>
                </UiView>
              )}
            </UiColumn>
            <UiColumn nonPadded>
              {(showRightText || showRightIcon) && (
                <UiView
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                  onClick={this.props.onClick}
                >
                  {rightIcon && rightIcon()}
                  {rightText && rightText()}
                </UiView>
              )}
            </UiColumn>
          </UiRow>
        </UiView>
        {this.props.divider && <UiDivider noMargin />}
        <UiScroller height={scrollerHeight}>{this.props.children}</UiScroller>
      </UiView>
    );
  }
}

AppHeader.defaultProps = {
  height: 20,
  divider: true,
  iconSize: 15
};
