import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import HomePage from '../../components/pages/HomePage';
import { getRestaurants } from '../../store/search/actions';
import WithPopup from '../../dependencies/modules/WithPopup';

import { resetFilters, resetSorting } from '../../store/search/actions';
import { getNearestDSZ, selectLocation } from '../../store/locations/actions';

const ConnectedComponent = withRouter(
  connect(
    state => {
      const { restaurants } = state.searchState;
      const { selectedLocation } = state.locationsState;
      return { restaurants, selectedLocation };
    },
    { getRestaurants, getNearestDSZ, selectLocation }
  )(HomePage)
);

export default connect(
  (state, ownProps) => {
    return {};
  },
  {
    resetFilters,
    resetSorting
  }
)(WithPopup(ConnectedComponent));
