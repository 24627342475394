import React, { Component } from 'react';

import Drawer from '@material-ui/core/Drawer';

import { UiView, UiHeading, UiScroller } from '.';
import AppHeader from '../../components/common/AppHeader';

export default class UiPopup extends Component {
  render() {
    const {
      anchor,
      open,
      onClose,
      renderItem,
      title,
      showPopupHeader,
      showRightIcon,
      showBackButton,
      showHeaderTitle
    } = this.props;
    return (
      <Drawer anchor={anchor} open={open} onClose={onClose}>
        {showPopupHeader && (
          <AppHeader
            iconSize={18}
            iconColor={'#999999'}
            divider={false}
            showHeaderTitle={showHeaderTitle}
            showBackButton={showBackButton}
            onBack={this.props.onBack}
            title={title}
            showRightIcon={showRightIcon}
            rightIcon={() => (
              <UiView onClick={this.props.reset}>
                <UiHeading type="anchor" color="tertiary" align="right">
                  RESET
                </UiHeading>
              </UiView>
            )}
          />
        )}
        <UiScroller>{renderItem}</UiScroller>
      </Drawer>
    );
  }
}
