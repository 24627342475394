import React, { Component } from 'react';
import {
  UiView,
  UiCell,
  UiColumn,
  UiRow,
  UiHeading
} from '../../dependencies/zomato-ui';

export default class MenuButton extends Component {
  scrollOnSelectedMenu(selectedMenuId) {
    this.props.scrollOnSelectedMenu(selectedMenuId, false);
  }

  render() {
    const { menusList, menus } = this.props;
    return (
      <UiView>
        {menusList && menusList.length ? (
          <UiView style={{ width: 220, maxHeight: 400 }}>
            {menusList.map(menuId => (
              <UiCell
                key={menuId}
                paddingTop
                paddingBottom
                borderBottom="default"
                onClick={this.scrollOnSelectedMenu.bind(this, menuId)}
              >
                <UiRow margin>
                  <UiColumn flex="2" nonPadded>
                    <UiHeading size="big">{menus[menuId].name}</UiHeading>
                  </UiColumn>
                  <UiHeading size="big" align="right">
                    {menus[menuId].ItemCount}
                  </UiHeading>
                </UiRow>
              </UiCell>
            ))}
          </UiView>
        ) : null}
      </UiView>
    );
  }
}
