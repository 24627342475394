import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { OrderStatusDetails } from '../../components/crystal';

export default withRouter(
  connect(state => {
    const { crystal } = state.orderState;
    const { orderStatusDetails, orderStatus } = crystal;
    const { title, subtitle, subtitle2 } = orderStatusDetails;
    const orderStatusText = orderStatusDetails.order_status_text;
    return { title, subtitle, orderStatusText, subtitle2, orderStatus };
  })(OrderStatusDetails)
);
