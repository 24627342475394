import React, { Component } from 'react';
import UiView from './View';
import { getPageHeight } from '../modules/util';

import Refresher from './swipeRefresh/Refresher';
import './swipeRefresh/SwipeRefreshLayout.css';

export default class List extends Component {
  constructor(props) {
    super(props);

    this.lastScrollTop = 0;
    this.endReachedTriggered = false;
    this.getScrollTop = this.getScrollTop.bind(this);
  }

  onScroll(event) {
    const { onEndReached, onEndReachedThreshold, onScroll } = this.props;
    const { offsetHeight, children, scrollTop } = event.target;
    const isScrollingDown = this.lastScrollTop < scrollTop;
    this.lastScrollTop = scrollTop;

    onScroll(event);
    if (!children) {
      return;
    }

    let totalHeight = 0;
    for (let child of children) {
      totalHeight += child.offsetHeight;
    }

    const endReached =
      totalHeight - offsetHeight - scrollTop <= onEndReachedThreshold;
    if (!endReached) {
      this.endReachedTriggered = false;
      return;
    }

    const shouldTriggerEndReached = isScrollingDown && endReached;

    if (!this.endReachedTriggered && shouldTriggerEndReached) {
      this.endReachedTriggered = true;
      onEndReached();
    }
  }

  getScrollTop() {
    return this.lastScrollTop;
  }

  render() {
    const {
      data,
      renderItem,
      height,
      scrollable,
      maxHeight,
      onRefresh,
      refreshing,
      listContainerRef
    } = this.props;
    return (
      <UiView className="ui-list-container" style={{ overflow: 'hidden' }}>
        {onRefresh && (
          <Refresher
            onRefresh={onRefresh}
            refreshing={refreshing}
            getScrollTop={this.getScrollTop}
          />
        )}
        <UiView
          style={{
            overflowY: scrollable ? 'scroll' : 'hidden',
            height: scrollable ? height : 'auto',
            maxHeight: scrollable ? maxHeight : 'auto'
          }}
          onScroll={this.onScroll.bind(this)}
        >
          <UiView DOMref={listContainerRef}>
            {data.map((item, index) => (
              <UiView key={index}>{renderItem(item, index)}</UiView>
            ))}
          </UiView>
        </UiView>
      </UiView>
    );
  }
}

List.defaultProps = {
  renderItem: () => {},
  data: [],
  scrollable: false,
  height: null,
  maxHeight: getPageHeight(),
  onScroll: () => {},
  onEndReached: () => {},
  onEndReachedThreshold: 100
};
