import { StateManager } from '../../util';
import {
  SET_CART,
  SET_ITEM,
  SET_CART_EMPTY,
  SET_CART_FROM_STORAGE,
  SET_ORDER_PLACED_RESPONSE,
  SET_PROMO_CODE,
  SET_CART_ITEM,
  SET_CART_RESPONSE,
  INCREASE_ITEM_QUANTITY,
  DECREASE_ITEM_QUANTITY,
  SET_CART_CALCULATIONS,
  SET_RESPONSE_STATUS,
  SET_CART_EXTRAS,
  SET_PROMOCODE_VALIDITY,
  SET_CALCULATING_CART,
  SET_CALCULATING_UPDATED_CART,
  REPEAT_LAST_CUSTOMISED_CART_ITEM_QUANTITY
} from './actions';

export const initialState = {
  responseCode: null,
  message: '',
  resId: null,
  items: {},
  cartItems: {},
  timestamp: 0,
  cartCalculations: {},
  cartResponse: {},
  extras: [],
  orderId: null,
  hashKey: null,
  calculatingCart: true,
  calculatingUpdatedCart: false,
  promocode: {
    loading: false,
    code: '',
    valid: true,
    errorMessage: '',
    successMessage: ''
  }
};

const setCart = (state, action) => {
  const { resId, items, cartItems, timestamp } = action;
  return {
    ...state,
    resId,
    timestamp,
    items: { ...items },
    cartItems: { ...cartItems }
  };
};

const setItem = (state, action) => {
  const { items } = action;
  return {
    ...state,
    items: { ...items }
  };
};

const setCartItem = (state, action) => {
  const { cartItems, timestamp } = action;
  return {
    ...state,
    cartItems: { ...cartItems },
    timestamp
  };
};

const increaseItemQuantity = (state, action) => {
  const { cartItems } = action;
  return {
    ...state,
    cartItems
  };
};

const decreaseCartItemQuantity = (state, action) => {
  const { items, cartItems } = action;
  return {
    ...state,
    items,
    cartItems
  };
};

const setCartCalculation = (state, action) => {
  const { cartCalculations } = action;
  return {
    ...state,
    cartCalculations
  };
};

const setCartResponse = (state, action) => {
  const { cartResponse } = action;
  return {
    ...state,
    cartResponse
  };
};

const setResponseStatus = (state, action) => {
  const { responseCode, message } = action;
  return {
    ...state,
    responseCode,
    message
  };
};

const setCartExtras = (state, action) => {
  const { extras } = action;
  return {
    ...state,
    extras
  };
};

const setPromocodeValidity = (state, action) => {
  const { valid, errorMessage, successMessage, loading, promocode } = action;
  return {
    ...state,
    promocode: {
      ...state.promocode,
      loading,
      valid,
      errorMessage,
      successMessage,
      promocode
    }
  };
};

const setCalculatingCart = (state, action) => {
  const { calculatingCart } = action;
  return {
    ...state,
    calculatingCart
  };
};

const setCalculatingUpdatedCart = (state, action) => {
  const { calculatingUpdatedCart } = action;
  return {
    ...state,
    calculatingUpdatedCart
  };
};

const setPromocode = (state, action) => {
  const { promocode } = action;
  return {
    ...state,
    promocode: {
      ...state.promocode,
      ...promocode
    }
  };
};

const setOrderPlacedResponse = (state, action) => {
  const { orderId, hashKey } = action;
  return {
    ...state,
    orderId: orderId,
    hashKey: hashKey
  };
};

const repeatLastCustomisedCartItemQuantity = (state, action) => {
  const { cartItems } = action;
  return {
    ...state,
    cartItems: { ...cartItems }
  };
};

const setCartEmpty = (state, action) => {
  return {
    ...state,
    orderId: null,
    resId: null,
    items: {},
    cartItems: {},
    timestamp: 0,
    cartCalculations: {},
    extras: []
  };
};

const setCartFromStorage = (state, action) => {
  const { cart } = action;

  return {
    ...state,
    resId: cart.resId,
    items: { ...cart.items },
    cartItems: { ...cart.cartItems },
    cartCalculations: { ...cart.cartCalculations }
  };
};

const actions = {
  [SET_CART]: setCart,
  [SET_ITEM]: setItem,
  [SET_CART_EMPTY]: setCartEmpty,
  [SET_CART_FROM_STORAGE]: setCartFromStorage,
  [SET_ORDER_PLACED_RESPONSE]: setOrderPlacedResponse,
  [SET_CART_ITEM]: setCartItem,
  [SET_PROMO_CODE]: setPromocode,
  [SET_CART_RESPONSE]: setCartResponse,
  [INCREASE_ITEM_QUANTITY]: increaseItemQuantity,
  [DECREASE_ITEM_QUANTITY]: decreaseCartItemQuantity,
  [SET_CART_CALCULATIONS]: setCartCalculation,
  [SET_RESPONSE_STATUS]: setResponseStatus,
  [SET_CART_EXTRAS]: setCartExtras,
  [SET_PROMOCODE_VALIDITY]: setPromocodeValidity,
  [SET_CALCULATING_CART]: setCalculatingCart,
  [SET_CALCULATING_UPDATED_CART]: setCalculatingUpdatedCart,
  [REPEAT_LAST_CUSTOMISED_CART_ITEM_QUANTITY]: repeatLastCustomisedCartItemQuantity
};

const manager = new StateManager(initialState, actions);

export default manager.reducer;
