import React, { Component } from 'react';

import {
  UiText,
  UiCell,
  UiTextInput,
  UiChip,
  UiView
} from '../../dependencies/zomato-ui';

export default class AddressNickName extends Component {
  state = {
    aliasList: [
      {
        title: 'HOME',
        checked: true,
        value: 1
      },
      {
        title: 'WORK',
        value: 2
      },
      {
        title: 'OTHER',
        value: 3
      }
    ],
    showIfOtherSelected: false,
    value: 'HOME',
    inputValue: '',
    selectedList: ['HOME']
  };

  onChipChange(list) {
    Object.keys(list).forEach(key => {
      const { inputValue } = this.state;
      if (['HOME', 'WORK'].indexOf(key) === -1) {
        const value = inputValue && inputValue.length ? inputValue : key;
        this.setState({
          showIfOtherSelected: true,
          value: value
        });
        if (!inputValue || !inputValue.length) {
          this.props.onSelect(value);
        }
      } else {
        const value = key;

        this.setState({
          showIfOtherSelected: false,
          value: value
        });
        this.props.onSelect(value);
      }
    });
  }

  onChange(event) {
    const inputValue = event.target.value;
    if (!inputValue || !inputValue.length) {
      return;
    }
    this.props.onSelect(inputValue);
  }

  render() {
    const { showIfOtherSelected, selectedList } = this.state;
    return (
      <UiCell margin padded>
        <UiText color="secondary">NICKNAME FOR ADDRESS*</UiText>
        <UiCell padded>
          <UiChip
            selectedList={selectedList}
            type="single"
            valueKey="title"
            list={this.state.aliasList}
            onChange={this.onChipChange.bind(this)}
          />
        </UiCell>
        {showIfOtherSelected && (
          <UiCell margin padded borderBottom="default">
            <UiText color="secondary">
              ENTER A NICKNAME (E.G. BAE'S HOME)
            </UiText>
            <UiView>
              <UiTextInput
                name="alias"
                placeholder="Enter NickName"
                onChange={this.onChange.bind(this)}
              />
            </UiView>
          </UiCell>
        )}
      </UiCell>
    );
  }
}
