import React from 'react';
let reactRouterNative;

reactRouterNative = require('react-router-dom');
// reactRouterNative = require("react-router-native");

const { Route, HashRouter, Switch, withRouter, Link } = reactRouterNative;
const BrowserRouter = HashRouter;
const Router = BrowserRouter ? BrowserRouter : reactRouterNative.Router;
const BackButton = reactRouterNative.BackButton
  ? reactRouterNative.BackButton
  : props => <div>{props.children}</div>;

export { Route, Router, Switch, withRouter, Link, BackButton };
