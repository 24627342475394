import React, { Component } from 'react';

import { UiView, UiOverlay } from './../../dependencies/zomato-ui';

import { SavedAddresses, RestaurantAddresses } from '../../containers/address';

export default class Addresses extends Component {
  state = {
    showIndicator: true
  };

  componentDidMount() {
    const data = {
      latitude: 0,
      longitude: 0,
      resId: this.props.resId
    };

    this.props
      .getUserAddresses(data)
      .then(() => {
        this.setState({
          showIndicator: false
        });
      })
      .catch(() => {
        this.setState({
          showIndicator: false
        });
      });
  }

  render() {
    const { addressList, resId } = this.props;
    const { showIndicator } = this.state;

    if (!addressList) {
      return null;
    }

    if (showIndicator) {
      return <UiOverlay show={true} />;
    }
    return (
      <UiView>{resId ? <RestaurantAddresses /> : <SavedAddresses />}</UiView>
    );
  }
}
