import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import CartItem from '../../components/cart/CartItem';

import {
  getDiscountedAmount,
  isOfferApplicable,
  getAmountStringWithCurrency
} from '../../store/cart/actions';

import { toggleChangeCustomisedItemQuantityPopup } from '../../store/restaurant/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { restaurantInfo } = state.restaurantState;
      const restaurantMenuItems = state.restaurantState.items;
      const { itemId, cartItemId } = ownProps;
      const { resId, cartItems } = state.cartState;
      const cartItem = cartItems[cartItemId];
      const restaurantMenuItem = restaurantMenuItems[itemId];

      return {
        resId,
        restaurantMenuItem,
        itemId,
        cartItemId,
        cartItem,
        restaurantInfo
      };
    },
    {
      getDiscountedAmount,
      isOfferApplicable,
      getAmountStringWithCurrency,
      toggleChangeCustomisedItemQuantityPopup
    }
  )(CartItem)
);
