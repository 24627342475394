import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import RestaurantMenuItem from './../../components/restaurant/RestaurantMenuItem';

export default withRouter(
  connect((state, ownProps) => {
    const { offer, restaurantInfo } = state.restaurantState;
    return { offer, restaurantInfo };
  })(RestaurantMenuItem)
);
