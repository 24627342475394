import React, { Component } from 'react';

import Fab from '@material-ui/core/Fab';

export default class UiFloatingActionButton extends Component {
  render() {
    const { children, style } = this.props;

    return (
      <Fab variant="extended" style={style} onClick={this.props.onClick}>
        {children}
      </Fab>
    );
  }
}
