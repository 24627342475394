import React, { Component } from 'react';

import {
  UiGrid,
  UiRow,
  UiColumn,
  UiView,
  UiCell
} from '../../dependencies/zomato-ui';
import { getPageHeight } from '../../dependencies/modules/util';

import LocationSnippet from '../../containers/common/LocationSnippetContainer';

import {
  KeywordSearchInput,
  SearchResults
} from '../../containers/home/search';

import { RestaurantList } from '../../containers/home/restaurants';

import SelectLocationSnippet from '../common/SelectLocationSnippet';

export default class App extends Component {
  constructor(props) {
    super(props);

    this.locationSnippet = React.createRef();
    this.state = {
      showSearchResults: false,
      authError: null
    };
  }

  componentDidMount() {
    if (!this.props.selectedLocation) {
      this.props.selectLocation();
    }
  }

  setSearchResultsVisibility(showSearchResults) {
    this.setState({
      showSearchResults: showSearchResults
    });
  }

  render() {
    const locationSnippet = this.locationSnippet.current
      ? this.locationSnippet.current
      : {};
    const { selectedLocation, getNearestDSZ } = this.props;
    const { showSearchResults } = this.state;
    const showAddressList = true;
    const locationSnippetHeight = locationSnippet.clientHeight
      ? locationSnippet.clientHeight
      : 0;

    return (
      <UiView style={styles.homePage}>
        {!selectedLocation && (
          <SelectLocationSnippet getNearestDSZ={getNearestDSZ} />
        )}
        {selectedLocation && (
          <UiGrid>
            <UiCell
              DOMref={this.locationSnippet}
              padded
              background="#fff"
              borderBottom="default"
            >
              <UiRow>
                <UiColumn>
                  <LocationSnippet
                    type="location"
                    pageType="home"
                    showAddressList={showAddressList}
                  />
                  <UiRow marginTop>
                    <UiColumn>
                      <KeywordSearchInput
                        setSearchResultsVisibility={this.setSearchResultsVisibility.bind(
                          this
                        )}
                      />
                    </UiColumn>
                  </UiRow>
                </UiColumn>
              </UiRow>
            </UiCell>
            <RestaurantList
              show={!showSearchResults}
              height={getPageHeight() - locationSnippetHeight}
            />
            <SearchResults show={showSearchResults} />
          </UiGrid>
        )}
      </UiView>
    );
  }
}

const styles = {
  homePage: {
    flex: 1,
    overflow: 'hidden'
  }
};
