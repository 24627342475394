import GPay from './GPay';
import Flipkart from './Flipkart';

import { getPartnerClient } from './config';

export default class Partners {
  static client = {};
  static whitelistedPartners = [GPay.CLIENT_NAME, Flipkart.CLIENT_NAME];
  static redirectToOrderSummaryPartners = [Flipkart.CLIENT_NAME];

  static setClient(clientId) {
    const client = getPartnerClient(clientId);
    Partners.client = client;
  }

  static getClient() {
    let partnerClient;
    let clientName = Partners.client.CLIENT_NAME;

    switch (clientName) {
      case GPay.CLIENT_NAME:
        partnerClient = new GPay();
        break;

      case Flipkart.CLIENT_NAME:
        partnerClient = new Flipkart();
        break;

      default:
        break;
    }

    return partnerClient;
  }

  static isWhitelistedPartner(clientName) {
    return Partners.whitelistedPartners.includes(clientName);
  }

  static isRedirectAllowedPartner(clientName) {
    return Partners.redirectToOrderSummaryPartners.includes(clientName);
  }
}
