import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import OrderSummary from '../../components/pages/OrderSummary';

import { getOrderSummary } from '../../store/order/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { summary } = state.orderState;
      const tabId = ownProps.match.params.tabId;
      const orderSummaryString = summary.orderSummaryString;
      return { orderSummaryString, tabId };
    },
    { getOrderSummary }
  )(OrderSummary)
);
