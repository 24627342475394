import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import AddCustomisedItemButton from '../../components/restaurant/AddCustomisedItemButton';

import {
  setItem,
  setCartItem,
  getAmountStringWithCurrency,
  getDiscountedAmount
} from '../../store/cart/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const {
        items,
        groups,
        groupItems,
        offer,
        restaurantInfo
      } = state.restaurantState;
      const { selectedCustomisedItems } = state.customisationsState;
      const itemId = ownProps.itemId;
      const menuItem = items[itemId];
      const resId = ownProps.resId;
      return {
        menuItem,
        resId,
        selectedCustomisedItems,
        itemId,
        groups,
        groupItems,
        offer,
        restaurantInfo
      };
    },
    {
      setItem,
      setCartItem,
      getAmountStringWithCurrency,
      getDiscountedAmount
    }
  )(AddCustomisedItemButton)
);
