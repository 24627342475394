import React, { Component } from 'react';

import { UiView } from '../../dependencies/zomato-ui';

import CartItem from '../../containers/cart/CartItemContainer';

export default class CartItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cartItems: this.props.items[this.props.itemId] || []
    };
  }

  render() {
    const { resId, itemId } = this.props;
    const { cartItems } = this.state;

    if (!cartItems || !cartItems.length) {
      return null;
    }

    return (
      <UiView>
        {cartItems.map(cartItemId => (
          <CartItem
            key={cartItemId}
            resId={resId}
            itemId={itemId}
            cartItemId={cartItemId}
            onItemRemove={this.props.onItemRemove}
          />
        ))}
      </UiView>
    );
  }
}
