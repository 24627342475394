import React, { Component } from 'react';
import './feedback.css';
import { UiView } from '..';

export default class FeedbackOverlay extends Component {
  render() {
    return (
      <UiView
        style={{ position: 'absolute' }}
        className="overlay"
        onClick={this.props.onClick}
      />
    );
  }
}
