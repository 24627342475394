import React from 'react';
import './index.css';
import App from './src/App';
import { SentryErrorReporting, isSentryAllowed } from './src/util';
if (isSentryAllowed()) {
  var DSN = process.env.REACT_APP_SENTRY_DSN;
  SentryErrorReporting.initialize(DSN);
}
if (window.location.href.indexOf("order-history") !== -1) {
  window.location.href = "https://www.zomato.com/partnership-init?partner_client_id=ordering_sdk_gpay_pwa_v1&pwastaging=aa113&flowtype=search";
} else {
  window.location.href = "https://www.zomato.com/partnership-init?partner_client_id=ordering_sdk_gpay_pwa_v1&pwastaging=aa113&flowtype=ordering";
}
var ReactDOM = require("react-dom");
ReactDOM.render(React.createElement(App), document.getElementById('root'));

// const AppRegistry = require("react-native").AppRegistry;
// AppRegistry.registerComponent('zomato.food', () => App);