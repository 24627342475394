import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { OrderDetails } from '../../components/summary';

export default withRouter(
  connect((state, ownProps) => {
    const { summary } = state.orderState;
    return { summary };
  })(OrderDetails)
);
