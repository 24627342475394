import React, { Component } from 'react';
import './circleRipple.css';
import { UiView } from '../../../dependencies/zomato-ui';

class CircleRipple extends Component {
  render() {
    return <UiView className="circle-ripple"></UiView>;
  }
}

export default CircleRipple;
