import React, { Component } from 'react';
import { units } from './util/Theme';

export default class UiColumn extends Component {
  render() {
    const { justifyContent, nonPadded, doublePadded } = this.props;
    let marginSet = { top: 0, bottom: 0, left: units.half, right: units.half };
    marginSet = nonPadded ? { ...marginSet, left: 0, right: 0 } : marginSet;
    marginSet = doublePadded
      ? { ...marginSet, left: units.full, right: units.full }
      : marginSet;

    const styles = {};
    if (justifyContent) {
      styles.justifyContent = justifyContent;
      styles.display = 'flex';
    }

    return (
      <div
        style={{
          flex: this.props.flex,
          flexDirection: 'column',
          paddingTop: marginSet.top,
          paddingBottom: marginSet.bottom,
          paddingLeft: marginSet.left,
          paddingRight: marginSet.right,
          ...styles
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

UiColumn.defaultProps = {
  flex: 1
};
