import React, { Component } from 'react';

import {
  UiView,
  UiRow,
  UiColumn,
  UiImage,
  UiHeading,
  UiText,
  UiScroller,
  UiGrid,
  UiCell
} from '../../../dependencies/zomato-ui';

import RestaurantCard from '../.././../containers/home/restaurants/RestaurantCardContainer';

export default class RestaurantChainOutlets extends Component {
  render() {
    const { restaurant } = this.props;
    return (
      <UiGrid>
        <UiView>
          <UiCell margin paddedBorder padded>
            <UiRow margin>
              <UiColumn flex={2} nonPadded>
                <UiImage
                  width={50}
                  height={50}
                  src={restaurant.o2_featured_image}
                />
              </UiColumn>
              <UiColumn flex={12} nonPadded>
                <UiRow>
                  <UiColumn>
                    <UiHeading ellipsis="1" size="large">
                      {restaurant.name}
                    </UiHeading>
                  </UiColumn>
                </UiRow>
                <UiRow marginTop>
                  <UiColumn>
                    <UiText ellipsis="1" color="secondary">
                      {restaurant.cuisines}
                    </UiText>
                  </UiColumn>
                </UiRow>
              </UiColumn>
            </UiRow>
          </UiCell>
          <UiRow marginBottom>
            <UiColumn doublePadded>
              <UiHeading color="highlighted">
                {restaurant.chain_outlets.length} outlets delivering to you
              </UiHeading>
            </UiColumn>
          </UiRow>
          <UiScroller height={restaurant.chain_outlets.length > 3 && 300}>
            {restaurant.chain_outlets &&
              restaurant.chain_outlets.length &&
              restaurant.chain_outlets.map(restaurantChainOutlet => (
                <UiView key={restaurantChainOutlet.id}>
                  <RestaurantCard
                    type="chainOutlet"
                    resId={restaurantChainOutlet.R.res_id}
                    restaurant={restaurantChainOutlet}
                  />
                </UiView>
              ))}
          </UiScroller>
        </UiView>
      </UiGrid>
    );
  }
}
