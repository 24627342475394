import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { CrystalPage } from '../../components/pages';

import { getOrderDetails } from '../../store/order/actions';
import { setCartEmpty, getCartFromStorage } from '../../store/cart/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { crystal } = state.orderState;
      const cartOrderId = state.cartState.orderId;
      const tabId = ownProps.match.params.tabId;
      const statusImage = crystal.statusImage;
      return { tabId, statusImage, cartOrderId };
    },
    {
      getOrderDetails,
      setCartEmpty,
      getCartFromStorage
    }
  )(CrystalPage)
);
