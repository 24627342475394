import {
  PERMISSION_MOBILE,
  PERMISSION_NAME
} from '../../../store/user/actions';

const permissions = {
  name: { scope: 'user.name' },
  mobile: { scope: 'user.mobile' }
};

export default class GPay {
  static CLIENT_NAME = 'GPAY';
  static CLIENT_ID = 'ordering_sdk_gpay_pwa_v1';
  static PAYMENT_METHOD_TYPE = 'gpay_checkout';
  static ZOMATO_ORDERING_API_KEY = '491edb810aa54a949a91460772e01d74';
  static ZOMATO_ORDERING_SECRET_KEY = '3cd9d2d84529652f6b743a05e6683a32';

  constructor() {
    this.CLIENT_NAME = GPay.CLIENT_NAME;
  }

  isPlatformAvailable() {
    return window.microapps;
  }

  requestIdentity(skipPrompt) {
    const identityRequest = {
      skipPrompt: skipPrompt
    };

    return window.microapps.getIdentity(identityRequest).then(response => {
      const scopes = [PERMISSION_NAME];

      return {
        grantToken: response,
        scopes: scopes.map(scope => permissions[scope].scope)
      };
    });
  }

  requestCurrentLocation() {
    return window.microapps.getCurrentLocation().then(location => {
      return location;
    });
  }

  getPaymentMethodType() {
    return GPay.PAYMENT_METHOD_TYPE;
  }

  requestPayment(checkoutResponse) {
    const transaction = checkoutResponse.response;
    const isTransactionIdPresent = transaction && transaction.transaction_id;
    const request = {
      apiVersion: transaction.api_version,
      apiVersionMinor: transaction.api_version_minor,
      allowedPaymentMethods: [
        {
          type: 'UPI',
          parameters: {
            payeeVpa: transaction.payee_vpa,
            payeeName: transaction.payee_name,
            mcc: transaction.mcc,
            transactionReferenceId: transaction.transaction_reference_id,
            ...(isTransactionIdPresent && {
              transactionId: transaction.transaction_id
            })
          },
          tokenizationSpecification: {
            type: 'DIRECT'
          }
        }
      ],
      transactionInfo: {
        countryCode: transaction.transaction_info.country_code,
        totalPriceStatus: transaction.transaction_info.total_price_status,
        totalPrice: transaction.transaction_info.total_price,
        currencyCode: transaction.transaction_info.currency_code
      }
    };

    return window.microapps.requestPayment(request).then(response => {
      return {
        data: JSON.stringify(response)
      };
    });
  }

  requestUserDetails() {
    return window.microapps.getPhoneNumber().then(response => {
      const scopes = [PERMISSION_MOBILE];

      return {
        grantToken: response,
        scopes: scopes.map(scope => permissions[scope].scope)
      };
    });
  }
}
