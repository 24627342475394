import React, { Component } from 'react';

import {
  UiView,
  UiRow,
  UiColumn,
  UiHeading,
  UiCell,
  UiText,
  UiImage,
  UiOverlay
} from './../../dependencies/zomato-ui';
import AppHeader from '../common/AppHeader';
import { getPageHeight } from '../../dependencies/modules/util';
import { colors } from '../../dependencies/zomato-ui/util/Theme';
import ErrorPage from '../common/ErrorPage';

export default class OrderHistory extends Component {
  state = {
    showLoader: true,
    error: null
  };

  componentDidMount() {
    this.getOrderHistory();
  }

  getOrderHistory() {
    this.setState({
      showLoader: true,
      error: null
    });
    this.props
      .getOrderHistory()
      .then(() => {
        this.setState({
          showLoader: false
        });
      })
      .catch(error => {
        this.setState({
          showLoader: false,
          error
        });
      });
  }

  onClick(tabId, orderStatus, paymentStatus) {
    if (!paymentStatus || [6, 7, 8].includes(orderStatus)) {
      this.props.history.push(`/order-summary/${tabId}`);
    } else {
      this.props.history.push(`/order-details/${tabId}`);
    }
  }

  renderOrders() {
    return this.props.orderItems.map(item => (
      <UiView
        key={item.tab_id}
        style={{
          paddingTop: 10,
          paddingBottom: 10
        }}
        onClick={this.onClick.bind(
          this,
          item.tab_id,
          item.status,
          item.payment_status
        )}
      >
        <UiRow>
          <UiColumn doublePadded>
            <UiView
              style={{
                ...styles.orderHistoryCard,
                boxShadow: colors['boxShadow']['primary']
              }}
            >
              <UiCell margin padded paddedBorder borderBottom="default">
                <UiRow marginBottom>
                  <UiImage
                    src={item.restaurant && item.restaurant.thumb}
                    height={40}
                    width={40}
                    radius={3}
                  />
                  <UiColumn flex={10}>
                    <UiRow marginBottom>
                      <UiHeading size="medium">
                        {item.restaurant && item.restaurant.name}
                      </UiHeading>
                    </UiRow>
                    <UiRow margin>
                      <UiText size="small">
                        {item.restaurant &&
                          item.restaurant.location.locality_verbose}
                      </UiText>
                    </UiRow>
                  </UiColumn>
                </UiRow>
              </UiCell>
              <UiCell margin paddingBottom paddedBorder borderBottom="default">
                <UiRow>
                  <UiColumn nonPadded>
                    <UiCell padded>
                      <UiHeading size="mini" color="secondary">
                        ITEMS
                      </UiHeading>
                      <UiText size="mini">{item.item_string}</UiText>
                    </UiCell>
                  </UiColumn>
                </UiRow>
                <UiRow>
                  <UiColumn nonPadded>
                    <UiCell padded>
                      <UiHeading size="mini" color="secondary">
                        ORDERED ON
                      </UiHeading>
                      <UiText size="mini">{item.created_at_str}</UiText>
                    </UiCell>
                  </UiColumn>
                </UiRow>
                <UiRow>
                  <UiColumn nonPadded>
                    <UiCell padded>
                      <UiHeading size="mini" color="secondary">
                        TOTAL AMOUNT
                      </UiHeading>
                      <UiText size="mini">{item.total_display_cost}</UiText>
                    </UiCell>
                  </UiColumn>
                </UiRow>
              </UiCell>
              <UiCell padded margin paddedBorder>
                <UiText type="anchor">
                  {item.message && item.message.label}
                </UiText>
              </UiCell>
            </UiView>
          </UiColumn>
        </UiRow>
      </UiView>
    ));
  }

  render() {
    const { showLoader, error } = this.state;
    const { orderItems } = this.props;

    return (
      <AppHeader
        iconColor={'#999999'}
        iconSize={18}
        showBackButton
        scrollerHeight={getPageHeight() - 45}
        divider={false}
        onBack={() => this.props.history.replace('/')}
      >
        <UiView>
          <UiRow marginTop>
            <UiColumn doublePadded>
              <UiCell padded>
                <UiHeading size="large">Order History</UiHeading>
              </UiCell>
            </UiColumn>
          </UiRow>
        </UiView>
        {!orderItems || !orderItems.length ? (
          <UiView style={styles.noOrdersContainer}>
            <UiRow marginBottom>
              <UiColumn>
                <UiCell marginBottom paddingBottom>
                  <UiImage
                    src="https://b.zmtcdn.com/data/o2_assets/bcac74e7fcb2cc70f75077e2fae1b99d1563262047.png"
                    width={150}
                    height="auto"
                  />
                </UiCell>
              </UiColumn>
            </UiRow>
            <UiRow margin>
              <UiColumn>
                <UiCell margin padded>
                  <UiHeading weight="500">
                    You haven't placed any orders yet.
                  </UiHeading>
                </UiCell>
              </UiColumn>
            </UiRow>
          </UiView>
        ) : (
          this.renderOrders()
        )}
        <UiOverlay show={showLoader} />
        <ErrorPage error={error} tryAgain={() => this.getOrderHistory()} />
      </AppHeader>
    );
  }
}

const styles = {
  orderHistoryCard: {
    border: '1px solid #ededed',
    borderRadius: 3
  },
  noOrdersContainer: {
    position: 'absolute',
    alignItems: 'center',
    display: 'flex',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: 30
  }
};
