import React, { Component } from 'react';
import { List } from 'react-virtualized';

import UiCell from '../Cell';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../util/Theme';
import UiView from '../View';

class UiRadioList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: ''
    };
  }

  componentDidMount() {
    this.setState({
      selectedValue: this.props.selectedTitle
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedTitle !== this.props.selectedTitle) {
      this.setState({
        selectedValue: this.props.selectedTitle
      });
    }
  }

  handleChange(item, index, event) {
    this.setState({
      selectedValue: event.target.value
    });
    this.props.onChange(item, index);
  }

  render() {
    const { classes, data } = this.props;
    return (
      <RadioGroup name={this.props.name} value={this.state.selectedValue}>
        <List
          rowCount={data.length}
          rowHeight={40}
          overscanRowCount={15}
          height={160}
          width={322}
          rowRenderer={({ index, key, style }) => {
            return (
              <UiView style={style} key={key}>
                <UiCell margin>
                  <FormControlLabel
                    value={data[index].title}
                    control={
                      <Radio
                        onChange={this.handleChange.bind(
                          this,
                          data[index],
                          index
                        )}
                        checked={this.state.selectedValue === data[index].title}
                        classes={{
                          root: classes.root,
                          checked: classes.checked
                        }}
                      />
                    }
                    label={data[index].title}
                  />
                </UiCell>
              </UiView>
            );
          }}
        />
      </RadioGroup>
    );
  }
}

const styles = {
  root: {
    color: colors['radio']['primary'],
    '&$checked': {
      color: colors['radio']['primary']
    }
  },
  checked: {}
};

export default withStyles(styles)(UiRadioList);
