import React, { Component } from 'react';

import { UiOverlay, UiAnchor, UiHeading } from '../../dependencies/zomato-ui';

import ErrorPage from '../common/ErrorPage';
import AppHeader from '../common/AppHeader';
import { getPageHeight } from '../../dependencies/modules/util';

export default class VerifyPaymentStatusPage extends Component {
  state = {
    showLoader: true,
    error: null
  };

  componentDidMount() {
    this.verifyPaymentStatus();
  }

  componentWillUnmount() {
    clearInterval(this.refreshIntervalId);
  }

  redirectOnStatus(response) {
    switch (response.status) {
      case 'success':
        this.props.history.push(`/order-details/${this.props.tabId}`);
        break;

      case 'failed':
        this.props.history.push(`/order-summary/${this.props.tabId}`);
        break;

      case 'pending':
        const pollingTime = response.poll_interval * 1000;
        const retryCount = response.retry_count;

        this.refreshIntervalId = setInterval(
          this.verifyPaymentStatus.bind(this, retryCount),
          pollingTime
        );
        break;

      default:
        break;
    }
  }

  verifyPaymentStatus(retryCount = 1) {
    clearInterval(this.refreshIntervalId);

    const data = {
      order_id: this.props.tabId,
      retry_count: retryCount
    };

    this.setState({
      showLoader: true,
      error: null
    });

    this.props
      .verifyPaymentStatus(data)
      .then(response => {
        this.redirectOnStatus(response);
      })
      .catch(error => {
        this.setState({
          showLoader: false,
          error
        });
      });
  }

  render() {
    const { showLoader, error } = this.state;
    return (
      <AppHeader
        showBackButton
        iconColor={'#999999'}
        iconSize={18}
        title={'Delivery Address'}
        scrollerHeight={getPageHeight() - 45}
        divider={false}
        onBack={() => this.props.history.goBack()}
      >
        <UiOverlay show={showLoader} />
        <ErrorPage error={error} tryAgain={() => this.verifyPaymentStatus()} />
      </AppHeader>
    );
  }
}
