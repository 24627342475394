import React, { Component } from 'react';

export default class UiView extends Component<{}> {
  render() {
    const props = { ...this.props };
    delete props.DOMref;
    return (
      <div
        {...props}
        ref={this.props.DOMref}
        style={{ position: 'relative', ...this.props.style }}
      >
        {this.props.children}
      </div>
    );
  }
}
