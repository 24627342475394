import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import { PaymentPage } from '../../components/pages';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { hashKey, orderId } = state.cartState;
      const { uuid } = state.userState;
      return { hashKey, orderId, uuid };
    },
    {}
  )(PaymentPage)
);
