import { connect } from 'react-redux';
import { withRouter } from '../../dependencies/modules/react-router-native';
import CheckoutButton from '../../components/cart/CheckoutButton';
import { getUserPermission } from '../../store/user/actions';
import { checkout, buildCartObject } from '../../store/cart/actions';
import { completePayment } from '../../store/order/actions';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { mobileNumber, name, email, uuid } = state.userState;
      const {
        cartCalculations,
        resId,
        hashKey,
        calculatingUpdatedCart
      } = state.cartState;
      const { selectedLocation } = state.locationsState;
      const { restaurantInfo } = state.restaurantState;
      return {
        cartCalculations,
        selectedLocation,
        restaurantInfo,
        resId,
        mobileNumber,
        name,
        email,
        uuid,
        hashKey,
        calculatingUpdatedCart
      };
    },
    {
      checkout,
      completePayment,
      buildCartObject,
      getUserPermission
    }
  )(CheckoutButton)
);
