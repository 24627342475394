import React, { Component } from 'react';

import {
  UiView,
  UiRow,
  UiColumn,
  UiHeading,
  UiCell,
  UiText,
  UiOverlay,
  UiAnchor
} from './../../dependencies/zomato-ui';

// import {
//   OrderRating
// } from '../order'

import ErrorPage from '../common/ErrorPage';
import {
  OrderDetails,
  RestaurantSnippet,
  OrderItems,
  CallRestaurant
} from '../../containers/summary';

import AppHeader from '../common/AppHeader';
import { getPageHeight } from '../../dependencies/modules/util';

export default class OrderSummary extends Component {
  state = {
    showLoader: true,
    error: null
  };

  componentDidMount() {
    this.getOrderSummary();
  }

  getOrderSummary() {
    const data = {
      tabId: this.props.tabId
    };

    this.setState({
      showLoader: true,
      error: null
    });

    this.props
      .getOrderSummary(data)
      .then(() => {
        this.setState({
          showLoader: false
        });
      })
      .catch(error => {
        this.setState({
          showLoader: false,
          error
        });
      });
  }

  render() {
    const { orderSummaryString } = this.props;
    const { showLoader, error } = this.state;
    return (
      <AppHeader
        showRightText
        rightText={() => (
          <UiAnchor href="tel:0124-6419012">
            <UiHeading size="medium" type="anchor">
              Support
            </UiHeading>
          </UiAnchor>
        )}
        showBackButton
        iconColor={'#999999'}
        iconSize={18}
        title={'Delivery Address'}
        scrollerHeight={getPageHeight() - 45}
        divider={false}
        onBack={() => this.props.history.goBack()}
      >
        {!showLoader && (
          <UiView style={{ marginBottom: 30 }}>
            <UiRow>
              <UiColumn nonPadded>
                <UiRow>
                  <UiColumn doublePadded>
                    <UiCell padded>
                      <UiHeading size="large">Order Summary</UiHeading>
                    </UiCell>
                  </UiColumn>
                </UiRow>
                <RestaurantSnippet />
                <UiRow>
                  <UiColumn doublePadded>
                    <UiCell padded>
                      <UiText size="small" type="anchor">
                        {orderSummaryString}
                      </UiText>
                    </UiCell>
                  </UiColumn>
                </UiRow>
                {/* <OrderRating /> */}
                <UiCell marginTop padded paddedBorder borderBottom="default">
                  <UiHeading size="medium">Your Order</UiHeading>
                </UiCell>
                <OrderItems />
                <OrderDetails />
                <UiView style={{ marginTop: 20 }}>
                  <UiRow>
                    <UiColumn doublePadded>
                      <CallRestaurant />
                    </UiColumn>
                  </UiRow>
                </UiView>
              </UiColumn>
            </UiRow>
          </UiView>
        )}
        <UiOverlay show={showLoader} />
        <ErrorPage error={error} tryAgain={() => this.getOrderSummary()} />
      </AppHeader>
    );
  }
}
