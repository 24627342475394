import React, { Component } from 'react';

import {
  UiView,
  UiRow,
  UiColumn,
  UiRadio
} from './../../../dependencies/zomato-ui';
export default class SortList extends Component {
  onChange(index, item) {
    const sorting = {
      title: item.title,
      value: item.value
    };

    if (
      !this.props.selectedSorting ||
      this.props.selectedSorting.value !== sorting.value
    ) {
      this.props.setSelectedSorting(sorting);
      this.props.hidePopup();
    }
  }

  render() {
    const { selectedSorting } = this.props;
    return (
      <UiView style={{ height: 200 }}>
        <UiView>
          <UiRow marginTop>
            <UiColumn doublePadded>
              <UiRow>
                <UiColumn>
                  <UiRadio
                    selectedTitle={selectedSorting.title}
                    data={this.props.getCategoriesList('sort')}
                    name="sort"
                    onChange={this.onChange.bind(this)}
                  />
                </UiColumn>
              </UiRow>
            </UiColumn>
          </UiRow>
        </UiView>
      </UiView>
    );
  }
}
