import { serialize } from '../util';
import Partners from '../dependencies/modules/partners';
export const Helpers = {};

export const debounce = (fn, time) => {
  let timeout;

  return function callback(...args) {
    const functionCall = () => fn.apply(this, args);
    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
};

export const generateApiUrl = url => {
  const { queryParams = {}, path } = url;
  if (process.env.REACT_APP_STAGING_SERVER) {
    queryParams.pwastaging = 'aa113';
  }

  const queryString = serialize(queryParams);

  const finalUrlWithParams = `${path}?${queryString}`;
  return finalUrlWithParams;
};

export class Fetch {
  static uuid = '';

  static post = (url, options = {}) =>
    Fetch.send(url, {
      ...options,
      method: 'POST'
    });

  static get = (url, options = {}) =>
    Fetch.send(url, {
      ...options,
      method: 'GET'
    });

  static delete = (url, options = {}) =>
    Fetch.send(url, {
      ...options,
      method: 'DELETE'
    });

  static send = (url, options) => {
    const finalUrlWithParams = generateApiUrl(url);

    const { uuid } = Fetch;
    const partnerClient = Partners.client;

    return fetch(`https://api.zomato.com/${finalUrlWithParams}`, {
      ...options,
      timeout: 20000,
      headers: {
        'Content-Type': 'application/json',
        'X-CLIENT-ID': partnerClient.CLIENT_ID,
        'X-ACCESS-UUID': uuid,
        'X-ZOMATO-ORDERING-API-KEY': partnerClient.ZOMATO_ORDERING_API_KEY,
        'X-ZOMATO-ORDERING-SECRET-KEY':
          partnerClient.ZOMATO_ORDERING_SECRET_KEY,
        'X-Zomato-API-Key': partnerClient.ZOMATO_ORDERING_API_KEY,
        ...options.headers
      },
      body: options.body
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
          let responseError = new Error(response.statusText);
          responseError.error = response.statusText;
          throw responseError;
        }
      })
      .then(response => {
        return response.json();
      })
      .then(responseJSON => {
        if (responseJSON.error) {
          let responseError = new Error(responseJSON.error);
          responseError.error = responseJSON.error;
          throw responseError;
        }
        return responseJSON;
      });
  };
}
